import { Edit, PersonRemove } from '@mui/icons-material';
import { Box, Chip, IconButton, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';

import { ToastContext } from '../../../../../context/toastContext';
import { formatDate } from '../../../../../utils/dateUtils';
import { useRevokeUsersAccessMutation, useUpdateUserMutation } from '../../../../../utils/redux/api';
import { type UpdateUserInfoRequest, UserRoles } from '../../../../../utils/types/User';
import { ConfirmationModal } from '../../Workspaces/components/ConfirmationModal';
import { EditUserDetailsModal } from './EditUserDetailsModal';

export interface UserFiltered {
  id: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  dateAdded: Date | undefined;
  role: string;
}

const extractRole = (role: string | undefined): string => {
  if (role === undefined) {
    return '';
  }

  switch (role) {
    case UserRoles.ADMIN:
      return 'Admin';

    case UserRoles.DATA_LEAD:
      return 'Data Lead';

    case UserRoles.DATA_COLLECTOR:
      return 'Data Collector';

    default:
      break;
  }

  return '';
};

interface UsersTableRowProps {
  orderBy: string;
  user: UserFiltered;
  usersRefetch: () => Promise<void>;
}

export const UsersTableRow: React.FC<UsersTableRowProps> = ({ orderBy, user, usersRefetch }) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);

  const role = extractRole(user.role);

  // Revoke access
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [revokeAccess, { isLoading: isRevokeAccessLoading }] = useRevokeUsersAccessMutation();
  const handleRevokeUserAccess = async () => {
    try {
      await revokeAccess(user.id).unwrap();

      handleOpenToast({
        message: `Access revoked for ${user.firstName} ${user.lastName}!`,
        severity: 'success',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: `Could not revoke access for ${user.firstName} ${user.lastName}!`,
      });
    } finally {
      void usersRefetch();
      setIsConfirmationModalOpen(false);
    }
  };

  // Edit user
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [updateUser, { isLoading: isUpdateUserLoading }] = useUpdateUserMutation();
  const handleEditUser = async (updatedUser: UserFiltered) => {
    try {
      if (updatedUser.id === undefined) throw new Error();

      let body: UpdateUserInfoRequest = {
        userId: updatedUser.id,
      };

      for (const [key, value] of Object.entries(updatedUser)) {
        if (value !== undefined && value !== null) {
          if (key === 'lastName' || key === 'firstName') {
            if (value !== user[key as keyof UserFiltered]) {
              body = {
                ...body,
                name: `${updatedUser.firstName} ${updatedUser.lastName}`,
              };
            }
          } else {
            if (value !== user[key as keyof UserFiltered]) {
              if (key === 'role') {
                body = {
                  ...body,
                  roles: [value],
                };
              } else {
                body = {
                  ...body,
                  [key]: value,
                };
              }
            }
          }
        }
      }

      await updateUser(body).unwrap();

      handleOpenToast({
        message: `${updatedUser.firstName} ${updatedUser.lastName} updated!`,
        severity: 'success',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: `Could not edit details for ${updatedUser.firstName} ${updatedUser.lastName}!`,
      });
    } finally {
      void usersRefetch();
      setIsEditModalOpen(false);
    }
  };

  return (
    <>
      <TableRow
        key={user.id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          td: { maxWidth: '150px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
        }}
      >
        <TableCell
          sx={
            orderBy === 'firstName'
              ? {
                  backgroundColor: palette.green[50],
                  color: palette.green[500],
                }
              : null
          }
        >
          <Tooltip title={user?.firstName}>
            <div>{user?.firstName}</div>
          </Tooltip>
        </TableCell>
        <TableCell
          sx={
            orderBy === 'lastName'
              ? {
                  backgroundColor: palette.green[50],
                  color: palette.green[500],
                }
              : null
          }
        >
          {user.lastName}
        </TableCell>
        <TableCell
          sx={
            orderBy === 'email'
              ? {
                  backgroundColor: palette.green[50],
                  color: palette.green[500],
                }
              : null
          }
        >
          {user.email}
        </TableCell>
        <TableCell
          sx={
            orderBy === 'dateAdded'
              ? {
                  backgroundColor: palette.green[50],
                  color: palette.green[500],
                }
              : null
          }
        >
          {formatDate(user?.dateAdded!.toString() ?? ('' as string))}
        </TableCell>
        <TableCell
          sx={
            orderBy === 'role'
              ? {
                  backgroundColor: palette.green[50],
                  color: palette.green[500],
                }
              : null
          }
        >
          <Chip
            label={role}
            sx={
              user.role !== undefined && user.role === UserRoles.ADMIN
                ? {
                    height: '20px',
                    color: palette.green[700],
                    backgroundColor: palette.green[200],
                    borderRadius: '8px',
                  }
                : {
                    height: '20px',
                    color: palette.green[700],
                    borderRadius: '8px',
                    border: 'solid',
                    borderWidth: '1px',
                    borderColor: palette.green[400],
                    backgroundColor: 'white',
                  }
            }
          />
        </TableCell>

        <TableCell>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Tooltip
                title="Edit user"
                onClick={() => {
                  setIsEditModalOpen(true);
                }}
              >
                <span style={{ marginRight: '18px' }}>
                  <IconButton aria-label="outlier-analysis" size="small" sx={{ padding: 0 }} onClick={() => {}}>
                    <Edit htmlColor={palette.green[500]} sx={{ fontSize: '16px' }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title="Revoke access"
                onClick={() => {
                  setIsConfirmationModalOpen(true);
                }}
              >
                <span>
                  <IconButton aria-label="upload-file" sx={{ padding: 0 }}>
                    <PersonRemove htmlColor={palette.red[500]} sx={{ fontSize: '16px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <ConfirmationModal
        title={`Do you want to remove access for ${user.firstName} ${user.lastName}?`}
        text={'This user will have to be invited again to regain access to REBEX.'}
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        onSave={handleRevokeUserAccess}
        isLoading={isRevokeAccessLoading}
      />
      <EditUserDetailsModal
        title={`Edit User`}
        text={'This action allows editing details for the selected user.'}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onSave={handleEditUser}
        isLoading={isUpdateUserLoading}
        user={user}
      />
    </>
  );
};
