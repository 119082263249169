import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import AppAutoComplete from '../../../../../components/materials/forms/AppAutoComplete';
import SortWorkspace from './SortWorkspace';
interface FilterWorkspaceProps {
  workspaceLength: number | undefined;
  countries: string[];
  banks: string[];
  onCountryChange: (event: any, value: string) => void;
  onBankChange: (event: any, value: string) => void;
  onSortByChange: (value: string) => void;
}

const FilterWorkspace: React.FC<FilterWorkspaceProps> = ({
  workspaceLength = 0,
  countries,
  banks,
  onCountryChange,
  onBankChange,
  onSortByChange,
}) => {
  const { palette } = useTheme();
  return (
    <Box display="block" sx={{ width: '100%' }}>
      <Box display="inline-flex" flexDirection="row" gap={3} alignItems="flex-end">
        <AppAutoComplete
          options={countries.sort((a, b) => (a > b ? 1 : -1))}
          label="Filter by Country"
          onChange={onCountryChange}
          sx={{ width: '240px' }}
        />
        <AppAutoComplete
          options={banks.sort((a, b) => (a > b ? 1 : -1))}
          label="Select a Bank"
          onChange={onBankChange}
          sx={{ width: '280px' }}
        />
        <SortWorkspace onSortByChange={onSortByChange} />
        {workspaceLength > 0 && (
          <Typography variant="bodySmall" sx={{ color: palette.gray.black }}>
            {workspaceLength} Workspaces Found
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FilterWorkspace;
