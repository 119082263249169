import {
  KeyThemeResourceType,
  type CreateKeyThemeRequest,
  type KeyTheme,
  type KeyThemeResource,
  type KeyThemeResourceStored,
  type KeyThemeResponse,
  type KeyThemeStatus,
  type UpdateKeyThemeRequest,
  type UpdateKeyThemeResponse,
  type UploadKeyThemeResourcesRequest,
} from '../../types/KeyTheme';
import { rebexApi } from './baseRebexEndpoints';

const transformKeyThemeResponse = (response: KeyThemeResponse): KeyTheme => ({
  id: response.id,
  title: response.name,
  status: response.status as KeyThemeStatus,
  insights: response.insights_opportunities?.insights ?? [],
  opportunities: response.insights_opportunities?.opportunity_areas ?? [],
  consumerSurveys: (response.theme_resources ?? [])
    .filter((themeResource) => themeResource.resource_type === KeyThemeResourceType.ConsumerSurvey)
    .map((resource) => ({ ...resource, stored: true })),
  kpis: (response.theme_resources ?? [])
    .filter((themeResource) => themeResource.resource_type === KeyThemeResourceType.Kpi)
    .map((resource) => ({ ...resource, stored: true })),
});

export const keyThemesEndpoints = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getThemes: builder.query<KeyTheme[], number>({
      query: (workspaceId: number) => `themes/workspaces/${workspaceId}`,
      transformResponse: (response: KeyThemeResponse[]) =>
        response.map((keyThemeResponse) => transformKeyThemeResponse(keyThemeResponse)),
    }),
    createTheme: builder.mutation<KeyTheme, CreateKeyThemeRequest>({
      query: (body) => ({ url: '/themes', method: 'POST', body }),
      transformResponse: transformKeyThemeResponse,
    }),
    updateTheme: builder.mutation<KeyTheme, UpdateKeyThemeRequest>({
      query: (request) => {
        const { themeId, keyTheme } = request;
        return {
          url: `/themes/${themeId}`,
          method: 'PUT',
          body: {
            name: keyTheme.title,
            insights_opportunities: {
              insights: keyTheme.insights,
              opportunity_areas: keyTheme.opportunities,
            },
            status: keyTheme?.status,
            theme_resources_id: [
              ...keyTheme.kpis
                .filter((kpi): kpi is KeyThemeResourceStored => kpi.stored ?? false)
                .map((kpi) => kpi.id),
              ...keyTheme.consumerSurveys
                .filter((cs): cs is KeyThemeResourceStored => cs.stored ?? false)
                .map((cs) => cs.id),
            ],
          },
        };
      },
      transformResponse: (response: UpdateKeyThemeResponse) => ({
        id: response.id,
        title: response.name,
        status: response.status as KeyThemeStatus,
        insights: response.insights_opportunities?.insights ?? [],
        opportunities: response.insights_opportunities?.opportunity_areas ?? [],
        consumerSurveys: [],
        kpis: [],
      }),
    }),
    deleteTheme: builder.mutation<null, number>({
      query: (id) => ({
        url: `themes/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadThemeResources: builder.mutation<KeyThemeResource, UploadKeyThemeResourcesRequest>({
      query: (request) => ({ url: `/themes/${request.themeId}/resources`, method: 'POST', body: request.formData }),
    }),
    getBlobToken: builder.query<{ sas_token: string }, number>({
      query: (workspaceId: number) => ({ url: `themes/blob-token`, params: { workspace_id: workspaceId } }),
    }),
    publishTheme: builder.mutation<null, number>({
      query: (id) => ({
        url: `themes/${id}/publish`,
        method: 'POST',
      }),
    }),
  }),
});
