import { Box, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../../utils/hooks/useAppSelector';
import {
  BranchAndATMCustomersTables,
  ContactCenterTables,
  CustomersTables,
  OnlineAndMobileCustomersTables,
  PersonalBankingFTETables,
  ProductAndSegmentTables,
  ProductSalesTables,
  TabKeysBySubmodule,
  TypeAndFunctionTables,
  type TabKeys,
} from '../../../../../../../utils/types/DataTablesEnum';

import { FullscreenExit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RedesignAppButton from '../../../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { useTriggerValidationMutation, useUpdateDataTableMutation } from '../../../../../../../utils/redux/api';
import {
  resetUnsavedChanges,
  selectDataTablesByTabKey,
  setActiveTabKey,
} from '../../../../../../../utils/redux/dataTablesSlice';
import CommentPanel from './Comment/CommentPanel';
import DataTableHeader from './DataTableHeader';
import CallCenterCustomerActivity from './DataTables/CallCenter/CallCenterCustomerActivity';
import CallCenterMetrics from './DataTables/CallCenter/CallCenterMetrics';
import TransactionsInCallCenter from './DataTables/CallCenter/TransactionsInCallCenter';
import ChannelCostsAndSupportFunction from './DataTables/Costs/ChannelCostsAndSupportFunction';
import CostType from './DataTables/Costs/CostType';
import MarketingCosts from './DataTables/Costs/MarketingCosts';
import CustomerProductHolding from './DataTables/Customers/CustomerProductHolding';
import DistributionByAge from './DataTables/Customers/DistributionByAge';
import DistributionByProductHolding from './DataTables/Customers/DistributionByProductHolding';
import DistributionOfCheckingCustomerAverageBalance from './DataTables/Customers/DistributionOfCheckingCustomerAverageBalance';
import DistributionOfTenure from './DataTables/Customers/DistributionOfTenure';
import NewAndLostCustomers from './DataTables/Customers/NewAndLostCustomers';
import TotalCustomersOverviewAndSegments from './DataTables/Customers/TotalCustomersOverviewAndSegments';
import ChatbotUsage from './DataTables/DigitalChannels/ChatbotUsage';
import CustomerActivity from './DataTables/DigitalChannels/CustomerActivity';
import Day90CustomerActivityByAge from './DataTables/DigitalChannels/Day90CustomerActivityByAge';
import DigitalEnrollment from './DataTables/DigitalChannels/DigitalEnrollment';
import DigitalSalesFunnel from './DataTables/DigitalChannels/DigitalSalesFunnel';
import QualityOfSalesInDigital from './DataTables/DigitalChannels/QualityOfSalesInDigital';
import TransactionsInDigital from './DataTables/DigitalChannels/TransactionsInDigital';
import UsageOfDigitalFeatures from './DataTables/DigitalChannels/UsageOfDigitalFeatures';
import BranchFTE from './DataTables/Employee(HR)/BranchFTE';
import BranchFTECompensation from './DataTables/Employee(HR)/BranchFTECompensation';
import FrontAndMiddleOfficesAndSupportFTE from './DataTables/Employee(HR)/FrontAndMiddleOfficesAndSupportFTE';
import FrontAndMiddleOfficesFTECompensation from './DataTables/Employee(HR)/FrontAndMiddleOfficesFTECompensation';
import OperationsFTE from './DataTables/Employee(HR)/OperationsFTE';
import OperationsFTECompensation from './DataTables/Employee(HR)/OperationsFTECompensation';
import BranchesAndATMs from './DataTables/PhysicalChannels/BranchesAndATMs';
import CustomerActivityInPhysicalChannels from './DataTables/PhysicalChannels/CustomerActivityInPhysicalChannels';
import QualityOfSalesBranch from './DataTables/PhysicalChannels/QualityOfSalesBranch';
import TransactionsInDigitalPhysical from './DataTables/PhysicalChannels/TransactionsInDigital';
import AccountsClosed from './DataTables/ProductSales/AccountsClosed';
import CallCenterRemoteChannels from './DataTables/ProductSales/CallCenterRemoteChannels';
import DigitalChannels from './DataTables/ProductSales/DigitalChannels';
import NewProductsSoldToNewToBankCustomers from './DataTables/ProductSales/NewProductsSoldToNewToBankCustomers';
import PhysicalChannels from './DataTables/ProductSales/PhysicalChannels';
import SummaryOfSalesByProductType from './DataTables/ProductSales/SummaryOfSalesByProductType';
import AverageBalanceProduct from './DataTables/Revenues/AverageBalanceProduct';
import RevenuesByProduct from './DataTables/Revenues/RevenuesByProduct';
import RevenuesSegment from './DataTables/Revenues/RevenuesSegment';
import { toggleFetchFlag } from '../../../../../../../utils/redux/flagsSlice';

const tabKeyToDataTable: Partial<Record<TabKeys, React.FC>> = {
  [ProductAndSegmentTables.AverageBalanceByProduct]: AverageBalanceProduct,
  [ProductAndSegmentTables.RevenuesByProduct]: RevenuesByProduct,
  [ProductAndSegmentTables.RevenuesBySegment]: RevenuesSegment,
  [TypeAndFunctionTables.ChannelCostsAndSupportFunctionsCosts]: ChannelCostsAndSupportFunction,
  [TypeAndFunctionTables.CostByType]: CostType,
  [TypeAndFunctionTables.MarketingCostsTable1TotalMarketing]: MarketingCosts,
  [PersonalBankingFTETables.BranchFTE]: BranchFTE,
  [PersonalBankingFTETables.BranchFTECompensation]: BranchFTECompensation,
  [PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice]: FrontAndMiddleOfficesAndSupportFTE,
  [PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice]: FrontAndMiddleOfficesFTECompensation,
  [PersonalBankingFTETables.OperationsFTE]: OperationsFTE,
  [PersonalBankingFTETables.OperationsFTECompensation]: OperationsFTECompensation,
  [CustomersTables.CustomerProductHolding]: CustomerProductHolding,
  [CustomersTables.DistributionByAge]: DistributionByAge,
  [CustomersTables.DistributionByProductHoldingTable1]: DistributionByProductHolding,
  [CustomersTables.DistributionOfCheckingCustomerAverageBalance]: DistributionOfCheckingCustomerAverageBalance,
  [CustomersTables.DistributionOfTenure]: DistributionOfTenure,
  [CustomersTables.NewAndLostCustomers]: NewAndLostCustomers,
  [CustomersTables.TotalCustomersOverviewAndSegmentsTable1]: TotalCustomersOverviewAndSegments,
  [ProductSalesTables.AccountsClosed]: AccountsClosed,
  [ProductSalesTables.CallCenterRemoteChannels]: CallCenterRemoteChannels,
  [ProductSalesTables.DigitalChannels]: DigitalChannels,
  [ProductSalesTables.NewProductsSoldToNewToBankCustomers]: NewProductsSoldToNewToBankCustomers,
  [ProductSalesTables.PhysicalChannels]: PhysicalChannels,
  [ProductSalesTables.SummaryOfSalesByProductType]: SummaryOfSalesByProductType,
  [OnlineAndMobileCustomersTables.ChatbotUsageTable1]: ChatbotUsage,
  [OnlineAndMobileCustomersTables.CustomerActivity90DayByAge]: Day90CustomerActivityByAge,
  [OnlineAndMobileCustomersTables.CustomerActivityTable1]: CustomerActivity,
  [OnlineAndMobileCustomersTables.DigitalEnrollmentTable1]: DigitalEnrollment,
  [OnlineAndMobileCustomersTables.DigitalSalesFunnel]: DigitalSalesFunnel,
  [OnlineAndMobileCustomersTables.QualityOfSalesInDigital]: QualityOfSalesInDigital,
  [OnlineAndMobileCustomersTables.TransactionsInDigital]: TransactionsInDigital,
  [OnlineAndMobileCustomersTables.UsageOfDigitalFeatures]: UsageOfDigitalFeatures,
  [BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1]: CustomerActivityInPhysicalChannels,
  [BranchAndATMCustomersTables.NumberOfBranchesAndATMs]: BranchesAndATMs,
  [BranchAndATMCustomersTables.QualityOfSalesInBranch]: QualityOfSalesBranch,
  [`${BranchAndATMCustomersTables.TransactionsInDigital}_physical`]: TransactionsInDigitalPhysical,
  [ContactCenterTables.CallCenterMetricsTable1]: CallCenterMetrics,
  [ContactCenterTables.CustomerActivityInCallCenter]: CallCenterCustomerActivity,
  [ContactCenterTables.TransactionsInCallCenter]: TransactionsInCallCenter,
};

const DataTableRedesign: React.FC = () => {
  const { activeTabKey, isCommentPanelOpen } = useAppSelector((state) => state.dataTables);
  const activeDataTables = useAppSelector((state) => selectDataTablesByTabKey(state));
  const { activeSubmodule } = useWorkspaceDataContext();
  const [isFullscreenActive, setIsFullscreenActive] = useState(false);
  const [updateDataTable, { isLoading: isSaveLoading }] = useUpdateDataTableMutation();
  const [triggerValidation, { isLoading: isLoadingSubmit }] = useTriggerValidationMutation();
  const { workspaceId } = useParams();
  const { handleOpenToast } = useContext(ToastContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeSubmodule === undefined) return;
    dispatch(setActiveTabKey(TabKeysBySubmodule[activeSubmodule.name][0]));
  }, [activeSubmodule, dispatch]);

  const handleSave = async () => {
    try {
      for (const currentDataTable of activeDataTables) {
        await updateDataTable({
          id: currentDataTable.id,
          submodule_id: currentDataTable.submodule_id,
          submodule_name: activeSubmodule?.name ?? '',
          table: currentDataTable.table,
          data: currentDataTable.data,
          columns: currentDataTable.columns,
          rows: currentDataTable.rows,
          workspace_id: Number(workspaceId),
        }).unwrap();
      }
      dispatch(toggleFetchFlag());
      handleOpenToast({ message: 'Data table saved successfully', severity: 'success' });
      dispatch(resetUnsavedChanges());
    } catch (error) {
      handleOpenToast({ message: 'Error uploading data table', severity: 'error' });
    }
  };

  const handleSubmit = async () => {
    if (activeSubmodule?.name === undefined || workspaceId === undefined) return;

    await handleSave();

    try {
      await triggerValidation({
        submodule_name: activeSubmodule?.name,
        workspace_id: Number(workspaceId),
      }).unwrap();
      handleOpenToast({ message: 'Validation finished sucessfullly', severity: 'success' });
    } catch {
      handleOpenToast({ message: 'Error on submodule validation', severity: 'error' });
    }
  };

  if (activeTabKey === undefined || activeTabKey === null || activeSubmodule === undefined) return;
  const DataTableComponent = tabKeyToDataTable[activeTabKey];

  return (
    <>
      <DataTableHeader
        handleFullscreen={() => {
          setIsFullscreenActive(true);
        }}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={3} width="100%">
          <Box
            display="flex"
            justifyContent="center"
            bgcolor={(theme) => theme.palette.gray[200]}
            borderRadius={2}
            flexGrow={1}
            py={2}
            px={3}
          >
            {DataTableComponent != null && <DataTableComponent />}
          </Box>
          <Box flexGrow={0}>{isCommentPanelOpen && <CommentPanel />}</Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={2} width="100%">
          <RedesignAppButton
            appVariant={'outlined'}
            onClick={handleSave}
            disabled={isSaveLoading}
            startIcon={isSaveLoading ? <AppCircularProgress size={20} /> : null}
          >
            Save
          </RedesignAppButton>
          {TabKeysBySubmodule[activeSubmodule.name].at(-1) === activeTabKey && (
            <RedesignAppButton
              disabled={isLoadingSubmit}
              startIcon={isLoadingSubmit ? <AppCircularProgress size={20} /> : null}
              appVariant={'filled'}
              onClick={handleSubmit}
            >
              Submit
            </RedesignAppButton>
          )}
        </Box>
      </Box>
      <Modal
        open={isFullscreenActive}
        onClose={() => {
          setIsFullscreenActive(false);
        }}
      >
        <Box
          position="absolute"
          height="95vh"
          width="95vw"
          bgcolor="white"
          sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          p={2}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="flex-start" width="100%">
            <Typography variant="titleMedium">Fullscreen View</Typography>
          </Box>
          <Box width="100%" display="flex" justifyContent="center" sx={{ overflowX: 'hidden', overflowY: 'scroll' }}>
            {DataTableComponent != null && <DataTableComponent />}
          </Box>
          <Box display="flex" width="100%" justifyContent="flex-end" p={1}>
            <RedesignAppButton
              appVariant={'filled'}
              onClick={() => {
                setIsFullscreenActive(false);
              }}
            >
              <FullscreenExit />
              Exit Fullscreen
            </RedesignAppButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DataTableRedesign;
