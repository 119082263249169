import React, { useContext } from 'react';

import { Typography, useTheme } from '@mui/material';
import AppButton from '../../../../../../../../components/materials/actions/AppButton';
import { ToastContext } from '../../../../../../../../context/toastContext';
import { useLazyDownloadFullReportFileQuery } from '../../../../../../../../utils/redux/api';
interface DownloadFullReportButtonProps {
  workspaceId: number;
  workspaceName: string;
  disabled?: boolean;
  loading?: boolean;
}

const DownloadFullReportButton: React.FC<DownloadFullReportButtonProps> = ({
  workspaceId,
  workspaceName,
  disabled = false,
  loading = false,
}) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);

  const [downloadFullReportQuery, { isLoading: isDownloadLoading }] = useLazyDownloadFullReportFileQuery();

  const handleDownloadFullReport = async () => {
    try {
      const data = await downloadFullReportQuery({ workspaceId }).unwrap();

      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${workspaceName}-full-report.pdf`;
      link.click();

      // Optionally revoke the object URL after the download
      URL.revokeObjectURL(url);
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Error downloading full report',
      });
      console.error('Error in downloading full report:', error);
    }
  };

  return (
    <AppButton
      variant="contained"
      loading={loading || isDownloadLoading}
      sx={{
        backgroundColor: palette.green[500],
        borderRadius: '8px',
        padding: '0 14px',
        '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
      }}
      onClick={handleDownloadFullReport}
      disabled={disabled}
    >
      <Typography variant="buttonMedium">Download Full Report</Typography>
    </AppButton>
  );
};

export default DownloadFullReportButton;
