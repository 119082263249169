import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useContext, useState } from 'react';

import RedesignAppButton from '../../../../components/materials/actions/RedesignAppButton';
import { ToastContext } from '../../../../context/toastContext';
import { useInviteAdminMutation } from '../../../../utils/redux/api';
import { UserRoles } from '../../../../utils/types/User';
import { CreateUserModal, type CreateUserDetails } from './components/CreateUserModal';
import { PageHeader } from './components/PageHeader';
import { UsersTable } from './components/UsersTable';

const Users: React.FC = () => {
  const { handleOpenToast } = useContext(ToastContext);
  // Invite admin
  const [inviteAdmin, { isLoading: isInviteAdminLoading }] = useInviteAdminMutation();
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState<boolean>(false);
  const handleAdminInvitation = async (userInfo: CreateUserDetails) => {
    try {
      if (
        userInfo.role === undefined ||
        userInfo.email === undefined ||
        userInfo.firstName === undefined ||
        userInfo.lastName === undefined
      ) {
        throw new Error('Missing user information');
      }
      await inviteAdmin({
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        email: userInfo.email,
      }).unwrap();

      handleOpenToast({
        message: `Invite sent to ${userInfo.firstName} ${userInfo.lastName}!`,
        severity: 'success',
      });
    } catch (error: any) {
      if (error?.status === 409) {
        handleOpenToast({
          severity: 'error',
          message: `Invite already exists for ${userInfo.firstName} ${userInfo.lastName}.`,
        });
      } else {
        handleOpenToast({
          severity: 'error',
          message: `Could not send an invite for ${userInfo.firstName} ${userInfo.lastName}, contact your system administrator`,
        });
      }
    } finally {
      setIsCreateUserModalOpen(false);
    }
  };

  // Create user
  const handleCreateUser = (user: CreateUserDetails) => {
    switch (user.role) {
      case UserRoles.ADMIN:
        void handleAdminInvitation(user);
        break;

      default:
        setIsCreateUserModalOpen(false);
        throw new Error('No role provided');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <PageHeader name="Users">
          <RedesignAppButton
            sx={{ flexDirection: 'row', gap: '8px', justifyContent: 'center' }}
            widePadding={true}
            appVariant="filled"
            onClick={() => {
              setIsCreateUserModalOpen(true);
            }}
          >
            <AddCircleIcon />
            Add New User
          </RedesignAppButton>
        </PageHeader>
        <UsersTable />
      </div>
      <CreateUserModal
        title="Add New User"
        text="This action will grant access to REBEX for a new user."
        isOpen={isCreateUserModalOpen}
        onClose={() => {
          setIsCreateUserModalOpen(false);
        }}
        onSave={handleCreateUser}
        isLoading={isInviteAdminLoading}
      />
    </>
  );
};

export default Users;
