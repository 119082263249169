import { type CommentThread } from '../../../utils/types/CommentThread';

export const commentThreadsToCellSet = (arr: CommentThread[] | undefined): Set<string> => {
  if (arr === undefined) return new Set();
  return new Set(
    arr.map(({ cell }) => {
      const [char, num] = [cell.charAt(0), parseInt(cell.slice(1), 10)];
      return `${char}${num + 1}`;
    }),
  );
};
