import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_OAUTH2_ISSUER!,
  clientId: process.env.REACT_APP_OKTA_OAUTH2_CLIENT_ID!,
  redirectUri: process.env.REACT_APP_OKTA_OAUTH2_REDIRECT_URI!,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

export default oktaAuth;
