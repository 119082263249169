import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import RebexLogoIcon from '../../../assets/icons/RebexLogo';

interface HeaderProps {
  variant: 'page' | 'email';
}

const AccountHeader: React.FC<HeaderProps> = ({ variant }) => {
  const { palette, ...theme } = useTheme();

  const title = 'REBEX';

  // Styles that adapt based on the variant
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    backgroundColor: palette.green[700],
    color: palette.green[400],
    width: variant === 'page' ? '100%' : 'auto',
    height: variant === 'page' ? '40px' : '120px',
  };

  const typographyStyle = {
    fontSize: variant === 'page' ? '13.89px' : '27.79px',
    fontWeight: 700,
    color: palette.green[400],
  };

  const iconStyle = {
    width: variant === 'page' ? 24 : 48,
    height: variant === 'page' ? 24 : 48,
    marginLeft: variant === 'page' ? theme.spacing(2) : theme.spacing(5),
    marginRight: variant === 'page' ? theme.spacing(1) : theme.spacing(2),
  };

  return (
    <Box style={headerStyle}>
      <RebexLogoIcon color="inherit" sx={iconStyle} />
      <Typography style={typographyStyle}>{title}</Typography>
    </Box>
  );
};

export default AccountHeader;
