import { IconButton } from '@mui/material';
import React, { useRef } from 'react';

interface FileUploadIconButtonProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  acceptedFileTypes: string;
  children: React.ReactNode;
}

const FileUploadIconButton: React.FC<FileUploadIconButtonProps> = ({ onFileChange, acceptedFileTypes, children }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current === null) return;
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept={acceptedFileTypes}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <IconButton onClick={handleButtonClick} color="primary" component="span" sx={{ p: '8px' }}>
        {children}
      </IconButton>
    </>
  );
};

export default FileUploadIconButton;
