import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './redux/authSlice';
import dataTablesReducer from './redux/dataTablesSlice';
import { rebexApi } from './redux/endpoints/baseRebexEndpoints';
import keyThemeReducer from './redux/keyThemeSlice';
import tableauReducer from './redux/tableauViewSlice';
import { flagReducer } from './redux/flagsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  dataTables: dataTablesReducer,
  [rebexApi.reducerPath]: rebexApi.reducer,
  tableau: tableauReducer,
  keyThemes: keyThemeReducer,
  fetchFlags: flagReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(rebexApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
