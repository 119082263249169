/**
 * Retrieves the redeem timestamp for a given workspace ID.
 * @param workspaceId - The ID of the workspace.
 * @returns The redeem timestamp as a Date object, or null if not found.
 */
const getTicketRedeemTimestamp = (workspaceId: number): Date | null => {
  const sessionData = JSON.parse(sessionStorage.getItem('tableauTimestamps') ?? '{}') as Record<string, string>;
  const ses = sessionData[workspaceId] ?? null;
  if (ses === null) {
    return null;
  } else {
    return new Date(parseInt(ses));
  }
};
export default getTicketRedeemTimestamp;
