interface GlossaryContent {
  title: string;
  tables: GlossaryTable[];
}

interface GlossaryTable {
  title: string;
  items: GlossaryItem[];
}

interface GlossaryItem {
  name: string;
  description?: string;
  subitem?: boolean;
}

export const glossaryContent: GlossaryContent[] = [
  {
    title: 'Revenues',
    tables: [
      {
        title: 'Products types',
        items: [
          {
            name: 'Deposit products',
            description:
              'Total retail banking deposit business including current accounts, savings accounts, and term deposits',
          },
          {
            name: 'Checking account',
            description:
              'A product that allows instant access to be available by withdrawals and deposits. Example: standard accounts, premium accounts, student accounts, youth accounts, cash accounts, and international accounts',
          },
          {
            name: 'Savings account',
            description:
              "A product that allows instant access to available funds and usually pays an interest rate per period. Examples: Call money, online savings accounts, children's savings account, saving books and cards, individual savings account, tax free savings account. Please exclude Term deposit accounts",
          },
          {
            name: 'Money markets',
            description:
              'A product that allows access to funds while offering a competitive interest rate, typically with higher minimum balance requirements than standard savings accounts. Examples: money market deposit accounts (MMDAs) and money market mutual funds.',
          },
          {
            name: 'Term deposit',
            description:
              'Savings deposit account with an interest rate where the customer cannot withdraw fund without penalty until the maturity term. This includes all term deposit accounts, regardless of term length (the term need not reach maturity during the study period)',
          },
          {
            name: 'Credit products',
            description: 'Total retail banking credit and loan business',
          },
          {
            name: 'Overdrafts',
            description:
              'An account that allows account holders to withdraw money even if their account balance falls below zero, granting them a line of credit. The bank typically charges interest and/or fees on the overdrawn amount until it is repaid',
          },
          {
            name: 'Credit cards',
            description:
              'A credit product that allows customers to complete purchases with borrowed funds that must be paid back in complete, plus an interest amount, typically by using a plastic or online card. All payment forms (revolving and deferred debit) shall be included',
          },
          {
            name: 'Unsecured consumer loans',
            description:
              'Loans or credit lines without collateral guarantee of any form. Example: personal loan or line of credit',
          },
          {
            name: 'Secured consumer loans',
            description:
              'Loans or credit lines guaranteed by any type of collateral other than real estate. Example: auto loan or boat loan',
          },
          {
            name: 'Mortgages total',
            description:
              'Loan or credit line where real estate (residential or commercial) is used as collateral. This includes both new mortgage drawdowns and mortgage refinances for new and existing customers',
          },
          {
            name: 'New mortgages',
            description:
              'Loans where real estate (residential or commercial) is used as collateral, issued to new or existing customers for the initial purchase of property',
          },
          {
            name: 'Mortgage refinances',
            description:
              'Loans where real estate (residential or commercial) is used as collateral, issued to replace or restructure an existing mortgage. This includes refinancing for new terms, interest rates, or loan amounts, available to both new and existing customers',
          },
          {
            name: 'Home equity',
            description:
              'Loan or credit line without predefined purpose where real estate (residential or commercial) is used as collateral',
          },
          {
            name: 'Investment products',
            description:
              'Retail bank investment products including bank securities accounts for debt and equity securities products (e.g. bonds and debentures, common stocks and derivative contracts) and any products with a securities component, e.g. mutual funds, retirement or pension plans. Also included are savings insurance products which are understood as an insurance policy where the primary customer is covered on savings/investments',
          },
          {
            name: 'Insurance products',
            description:
              "All insurance policies which are sold through the bank's sales channels and generate an income, either as a standalone product, or as linked / embedded to other products (PPI, unit-linked, etc.)",
          },
          {
            name: 'Other',
            description: 'All other products not previously stated',
          },
        ],
      },
      {
        title: 'Revenue by Product',
        items: [
          {
            name: 'Gross interest income/expense',
            description:
              'Total interest paid to (for deposit accounts), or charged on (for loan products) clients, before accounting for the internal cost of funds',
          },
          {
            name: 'Funds transfer pricing (FTP) / Internal cost of funds',
            description:
              'Balance x internal rate assigned to a banking product to reflect its funding cost or benefit',
          },
          {
            name: 'Net interest income',
            description:
              'Net interest income realized on the lending and deposit business after taking into account the internal cost of fund / funds transfer pricing',
          },
          {
            name: 'Net fee and other income',
            description:
              'Net fees collected related to the product during the study period (e.g., NSF, overdraft, maintenance, ATM, Debt card fees), and any other income (e.g. FX)',
          },
          {
            name: 'Total revenue',
            description:
              'Total net reported revenues from operations including net interest, net fee and other income categories',
          },
        ],
      },
      {
        title: 'Average balance by product',
        items: [
          {
            name: 'Average balance',
            description:
              'Balances in the respective product category calculated as the average of balances at the beginning and balances at the end of the data period',
          },
        ],
      },
      {
        title: 'Revenue by segment',
        items: [
          {
            name: 'Mass market - Net interest income',
            description: 'Net interest income realized from mass customer segment',
          },
          {
            name: 'Mass market - Net fee income',
            description: 'Net fee income realized from mass customer segment',
          },
          {
            name: 'Affluent - Net interest income',
            description: 'Net interest income realized from affluent customer segment',
          },
          {
            name: 'Affluent - Net fee income',
            description: 'Net fee income realized from affluent customer segment',
          },
        ],
      },
    ],
  },
  {
    title: 'Costs',
    tables: [
      {
        title: 'Cost P&L View',
        items: [
          {
            name: 'Personnel costs',
            description:
              'Personnel costs include gross salaries, all bonuses, overtime pay and supplements, all (contractual and voluntary) special payments (vacation/holiday pay, year-end bonuses, profit sharing), anniversary awards, employer contributions to statutory social security, expenses for company pension plans, and severance payments',
          },
          {
            name: 'Premises',
            description:
              'Costs incurred from operating and maintaining buildings, facility management and utilities. Examples: rent, leasing, branch equipment (non-IT), and electricity. Please exclude amortization and depreciation',
          },
          {
            name: 'IT',
            description:
              'Costs incurred related to IT equipment, communication, software-applications. Examples: leasing, purchase costs, licensing costs, audio, etc. Please exclude amortization and depreciation',
          },
          {
            name: 'Marketing ',
            description:
              'Costs pertaining to all marketing activities. Examples: television, online ads, newspaper, magazines, radio, direct and interactive marketing, social media marketing, PR events, and sponsorships',
          },
          {
            name: 'Cash handling / vault services',
            description:
              'Costs related to cash transportation, processing and handling for all banking outlets (including branches and ATMs)',
          },
          {
            name: 'Outsourced staff (contractors)',
            description:
              'Costs of business processes (operations, excluding IT) outsourced to third party providers (payment processing, securities processing, and other processes)',
          },
          {
            name: 'Professional services (Consultancy, audit & legal)',
            description: 'Costs related to external advisory, audit and legal services',
          },
          {
            name: 'Postage',
            description: 'Costs related to the usage of postal services',
          },
          {
            name: 'Office equipment',
            description:
              'Costs related to office or workshop furniture and equipment, such as paper, desks, workbenches and tools',
          },
          {
            name: 'Regulatory fees',
            description:
              'Cost related to managing and meeting regulatory requirements, excluding IT cost for regulatory IT projects',
          },
          {
            name: 'Other expenses',
            description: 'Any other costs not listed separately (e.g. travel management, data services)',
          },
          {
            name: 'Operational losses',
            description:
              'Cost of losses resulting from inadequate or failed internal processes, people and systems or from external events',
          },
          {
            name: 'Fraud losses',
            description:
              'Financial losses the bank incurs due to fraudulent activities, including unauthorized transactions, identity theft, card fraud, check fraud, cyber fraud, and internal fraud. Include all direct financial losses from fraud during the reporting period',
          },
          {
            name: 'Amortization/Depreciation',
            description:
              'Accumulated portion of the recorded cost of a fixed asset that has been charged to expense through either depreciation or amortization',
          },
        ],
      },
      {
        title: 'Channel costs and support function costs',
        items: [
          {
            name: 'Front office',
            subitem: true,
          },
          {
            name: 'Branches',
            description: 'Costs pertaining to the branch network',
          },
          {
            name: 'ATMs',
            description:
              'Costs pertaining to ATM-only locations, self-service outlets located in defined regional scope',
          },
          {
            name: 'Non-branch sales force',
            description: 'Costs pertaining to non-branch sales and sales performance measurement',
          },
          {
            name: 'Contact centers',
            description: 'Costs pertaining to the contact center',
          },
          {
            name: 'Online & mobile banking',
            description: 'Costs pertaining to online and mobile banking',
          },
          {
            name: 'Remote advisor',
            description: 'Costs pertaining to the remote advisory',
          },
          {
            name: 'Middle office',
            subitem: true,
          },
          {
            name: 'Sales mgmt., Channel mgmt. & Product mgmt.',
            description:
              'Costs pertaining to channel and product management teams including product design, product marketing/campaign management, customer insights, analytics, overall strategy, customer analytics & CRM, customer experience and segment management, customer marketing strategy, etc.',
          },
          {
            name: 'Collections & recoveries',
            description:
              'Costs pertaining to teams working in teams dedicated to recovering past-due and delinquent amounts owned by customers.',
          },
          {
            name: 'Support',
            subitem: true,
          },
          {
            name: 'Operations',
            description:
              'Costs pertaining to operations (including credit decisioning) inclusive of all cost types defined above',
          },
          {
            name: 'Finance',
            description:
              'Costs pertaining to finance, accounting, and tax functions inclusive of all cost types defined above',
          },
          {
            name: 'Human resources',
            description: 'Costs pertaining to human resources functions inclusive of all cost types defined above',
          },
          {
            name: 'Risk management',
            description: 'Costs pertaining to risk functions inclusive of all cost types defined above',
          },
          {
            name: 'Compliance, internal audit & legal',
            description:
              'Costs pertaining to compliance, legal, and audit (control) functions inclusive of all cost types defined above',
          },
          {
            name: 'IT',
            description:
              'Costs pertaining to central IT functions inclusive of all cost types defined above. It excludes costs allocated to previously defined sales and service channels and other functions',
          },
          {
            name: 'Other',
            description: 'Any other costs within retail banking that were not included in the previous functions',
          },
        ],
      },
      {
        title: 'Marketing costs',
        items: [
          {
            name: 'Marketing spend',
            description:
              'Costs pertaining to all marketing activities. Examples: television, online ads, newspaper, magazines, radio, direct and interactive marketing, social media marketing, PR events, and sponsorships',
          },
          {
            name: 'Digital marketing spend',
            description:
              'Marketing activities conducted through online and electronic channels to promote products and services. This includes efforts such as social media advertising, email campaigns, search engine marketing, display ads, and influencer partnerships aimed at engaging customers and increasing brand visibility in digital spaces.',
          },
          {
            name: 'Marketing spend on sales promotion',
            description:
              'Costs incurred specifically for promotional activities designed to boost immediate sales and attract customers. Examples include discounts, coupons, limited-time offers, and other incentives that encourage quick purchases or increased product usage.',
          },
        ],
      },
    ],
  },
  {
    title: 'Customers',
    tables: [
      {
        title: 'Total customers overview and segments',
        items: [
          {
            name: 'Household',
            description:
              'A unique group of one or more individuals who are linked together by financial relationships, typically residing at the same address, and holding one or more accounts with the bank.',
          },
          {
            name: 'Retail customers',
            description:
              'Personal customers that hold one or more products retail products with the bank. Products include checking accounts, savings accounts, term deposits, credit cards, unsecured loans, secured loans, mortgages (new and refinanced), home equity, investment and insurance. <br/>In case of joint accounts held by two or more customer please only include both primary and secondary account holder. E.g. if two customers have a joint checking account they count as two unique customers. <br/>Please exclude private banking customers and small business customers.',
          },
          {
            name: 'Active customers',
            description:
              'Active customers are defined as customers who have at least one active product as defined below. <br/>- Checking, Savings and Term deposits: All accounts with a minimum balance of $100 OR with at least one customer initiated transaction during the last 6 months of the period. <br/>- Loans, Mortgages, and Credit cards: If there is any outstanding amount on the account OR the customer has initiated at least one transaction in the last 6 months of the period. Transactions include loan and/or bill payment. <br/>- Investment: All accounts with a minimum balance of $100 OR with at least one customer initiated transaction during the last 6 months of the period. <br/>- Insurance: All insurances are considered active as long as the due premiums are being paid or have already been paid (where premium paying term is over) on time, and the insurance cover is valid',
          },
          {
            name: 'Primary customers',
            description:
              'A customer is defined as a primary banking relationship customer if the customer has a checking account ​AND​ has initiated at least 30 transactions in the last 3 months of the study period across all their products. Transactions include all transactions across debit, credit, P2P and check, including recurring transactions such as direct deposit, bill payment and standing payments.',
          },
          {
            name: 'Checking account customers',
            description: 'Total number of unique customers for the bank who hold a checking account with the bank',
          },
          {
            name: 'Deposit only customers',
            description:
              'Total number of unique customers for the bank who hold only deposit product (Checking account, Savings account or Term Deposit) with the bank',
          },
          {
            name: 'Credit card only customers',
            description:
              'Number of unique customers who only hold a credit card product with the bank and no other products',
          },
          {
            name: 'Mono-product customers',
            description: 'Total number of unique customers for the bank who hold only one product',
          },
          {
            name: 'Indirect auto customers',
            description:
              'Total number of unique customers who obtain an auto loan through an intermediary, typically an automobile dealership. In the data, include customers whose auto loans were originated through these indirect channels.',
          },
          {
            name: 'Mass market customers',
            description: 'Customers who are managed in the basic customer coverage model',
          },
          {
            name: 'Affluent customers',
            description:
              'Customers who are managed under a differentiated customer service model, typically based on specific eligibility criteria such as income, assets, or investment levels. This segment receives specialized services and tailored financial solutions due to their higher financial standing.',
          },
        ],
      },
      {
        title: 'New and lost customers',
        items: [
          {
            name: 'Total customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank',
          },
          {
            name: 'Total customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period',
          },
          {
            name: 'Mass market customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank and were managed in the mass market segment by the end of the study period',
          },
          {
            name: 'Mass market customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period and were managed in the mass market segment by the end of the study period',
          },
          {
            name: 'Affluent customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank and were managed in the affluent segment by the end of the study period',
          },
          {
            name: 'Affluent customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period and were managed in the affluent segment by the end of the study period',
          },
        ],
      },
      {
        title: 'Customer product holding',
        items: [
          {
            name: 'Total accounts/products',
            description:
              'Number of products in the respective product category, regardless of whether they are held by one or more customers. E.g. A joint checking account held by two customers would count as one account/product',
          },
          {
            name: 'Retail customers with product',
            description:
              'Number of unique customers with at least one product in the respective product category regardless of the number of accounts the customer holds. Please include non-primary holders of joint accounts. <br/>Example: A customer with two credit cards is only counted once in the credit card category, and so represents one customer with product. <br/>Example: Two customers with a joint checking account will count as two customers with a checking account.',
          },
          {
            name: 'New-to-bank customers with product',
            description:
              'Number of new-to-bank customers with at least one account in the respective product category regardless of the number of accounts the customer holds. Example: A new-to-bank customer with two credit cards is only counted once in the credit card category, and so represents one customer with product',
          },
          {
            name: 'Primary account customer with product',
            description:
              'Number of primary customers (as defined in primary customer definition) with at least one account in the respective product category regardless of the number of accounts the customer holds. Example: A primary customer with two credit cards is only counted once in the credit card category, and so represents one primary customer with product',
          },
          {
            name: 'Checking account customer with product',
            description:
              'Number of unique customers who have a checking account with at least one product in the other respective product category regardless of the number of accounts the customer holds. Example: A customer with two credit cards and a checking account is only counted once in the credit card category, and so represents one checking account customer with the credit card product',
          },
          {
            name: 'Mass market account customer with product',
            description:
              'Number of unique mass market customers with at least one product in the respective product category regardless of the number of accounts the customer holds.',
          },
          {
            name: 'Affluent customer with product',
            description:
              'Number of unique affluent customers with at least one product in the respective product category regardless of the number of accounts the customer holds.',
          },
        ],
      },
      {
        title: 'Distribution by age',
        items: [
          {
            name: 'Retail customers',
            description:
              'Number of unique customers categorized according to their age. Example: 500 customers are under 18 years of age, 1,000 customers are between 18 to 24 years, etc.',
          },
          {
            name: 'Active customers',
            description:
              'Number of unique active customers (as defined in active customer definition)  categorized according to their age. Example: 500 Active customers are under 18 years of age, 1,000 Active customers are between 18 to 24 years, etc.',
          },
          {
            name: 'Primary customer',
            description:
              'Number of unique primary customers (as defined in primary customer definition) categorized according to their age. Example: 500 Primary customers are under 18 years of age, 1,000 Primary customers are between 18 to 24 years, etc.',
          },
          {
            name: 'New-to-bank customers',
            description:
              'Number of unique new to bank customers categorized according to their age. Example: 500 New-to-bank customers are under 18 years of age, 1,000 New-to-bank customers are between 18 to 24 years, etc.',
          },
          {
            name: 'Lost customers',
            description:
              'Number of unique lost from bank customers categorized according to their age. Example: 500 Lost customers are under 18 years of age, 1,000 Lost customers are between 18 to 24 years, etc.',
          },
          {
            name: 'Retail customers with deposit product',
            description:
              'Number of unique retail customers with deposit product categorized according to their age. Example: 500 customers with deposit product are under 18 years of age, 1,000 customers with deposit product are between 18 to 24 years, etc.',
          },
          {
            name: 'Retail customers with mortgage product',
            description:
              'Number of unique retail customers with mortgage product categorized according to their age. Example: 500 customers with mortgage product are under 18 years of age, 1,000 customers with mortgage product are between 18 to 24 years, etc.',
          },
        ],
      },
      {
        title: 'Distribution by product holding',
        items: [
          {
            name: 'Retail customers',
            description: 'Number of unique customers categorized according to their product holdings',
          },
          {
            name: 'Mass market customers',
            description: 'Number of unique mass market customers categorized according to their product holdings',
          },
          {
            name: 'Affluent customers',
            description: 'Number of unique affluent customers categorized according to their product holdings',
          },
          {
            name: 'Active customers',
            description: 'Number of unique active customers categorized according to their product holdings',
          },
          {
            name: 'Primary customers',
            description: 'Number of unique primary customers categorized according to their product holdings',
          },
          {
            name: 'New to bank customers',
            description: 'Number of unique new to bank customers categorized according to their product holdings',
          },
          {
            name: 'Lost customers',
            description: 'Number of unique lost from bank customers categorized according to their product holdings',
          },
        ],
      },
      {
        title: 'Distribution by balance',
        items: [
          {
            name: 'Checking customers',
            description:
              'Number of unique checking account customers categorized as per their end of year balance across all their deposit accounts (checking, savings and term deposits). <br/>Example: If a customer has two checking accounts with balance of $500 and $1000, then the total balance for the customer is $1500 and the customer should be counted in $1k-$2k category. If the customer has a checking account and a savings account with balance of $500 and $1000 then the total balance for the customer is $1500.',
          },
          {
            name: 'Active customers',
            description:
              'Number of unique active customers (as defined in active customer definition) categorized their end of year balance across all their deposit accounts (checking, savings and term deposits). <br/>Example: If a customer has two checking accounts with balance of $500 and $1000, then the total balance for the customer is $1500 and the customer should be counted in $1k-$2k category. If the customer has a checking account and a savings account with balance of $500 and $1000 respectively then the total balance for the customer is $1500. <br/>If the customer does not have any deposit products please include them in the lowest category <$1k.',
          },
          {
            name: 'Primary customers',
            description:
              'Number of unique primary customers (as defined in primary customer definition) categorized their end of year balance across all their deposit accounts (checking, savings and term deposits). <br/>Example: If a customer has two checking accounts with balance of $500 and $1000, then the total balance for the customer is $1500 and the customer should be counted in $1k-$2k category. If the customer has a checking account and a savings account with balance of $500 and $1000 respectively then the total balance for the customer is $1500. <br/>If the customer does not have any deposit products please include them in the lowest category <$1k.',
          },
        ],
      },
      {
        title: 'Distribution of tenure',
        items: [
          {
            name: 'Retail customers',
            description:
              'Number of unique customers categorized according as per their respective tenure with the bank. Tenure is counted as the period between the date the customer joined the bank and the end of study period. Example: 5,000 customers have been customers of the bank for less than a year, 2,000 customers have been customers for between 1 and 2 years, etc.',
          },
          {
            name: 'Active customers',
            description:
              'Number of unique active customers categorized as per their respective tenure with the bank. Tenure is counted as the period between the date the customer joined the bank and the end of study period. Example: 5,000 customers have been customers of the bank for less than a year, 2,000 customers have been customers for between 1 and 2 years, etc.',
          },
          {
            name: 'Primary customers',
            description:
              'Number of unique primary customers categorized as per their respective tenure with the bank. Tenure is counted as the period between the date the customer joined the bank and the end of study period. Example: 5,000 customers have been customers of the bank for less than a year, 2,000 customers have been customers for between 1 and 2 years, etc.',
          },
          {
            name: 'Lost customers',
            description:
              'Number of unique lost customers categorized as per their respective tenure with the bank. Tenure is counted as the period between the date the customer joined the bank and the end of study period. Example: 5,000 customers have been customers of the bank for less than a year, 2,000 customers have been customers for between 1 and 2 years, etc.',
          },
        ],
      },
    ],
  },
  {
    title: 'Products',
    tables: [
      {
        title: 'Sales by product type',
        items: [
          {
            name: 'Branch',
            description:
              'Number of products opened in branches. Example: opened by a branch manager, Sales, Account manager or Universal banker',
          },
          {
            name: 'Third party sales',
            description:
              'Number of products opened by a third party. Example: agencies/broker for mortgages or insurances, online platforms, comparison platforms or retailer',
          },
          {
            name: 'Non branch sales',
            description:
              'Number of products opened by non-branch sales force. Example: central sales, advisory center or non-branch based specialized advisors',
          },
          {
            name: 'ATM',
            description:
              'Number of products opened via ATMs including any customer-facing device that is capable of handling cash. It also includes cash or cheque deposit machines (IDMs)',
          },
          {
            name: 'Online',
            description:
              'Number of products opened via secure site, regardless of the device used to access the website, in the respective product categories',
          },
          {
            name: 'Mobile',
            description:
              'Number of products opened via mobile banking app or mobile homepage version via smartphone or tablet in the respective product category',
          },
          {
            name: 'E2E in digital',
            description:
              'Number of products opened via online or mobile with the entire customer journey being digital and without the need for any human intervention',
          },
          {
            name: 'Remote advisor',
            description:
              'Number of products opened via a remote advisor over virtual platforms such as phone calls, video conferencing, or online messaging in the respective product category',
          },
          {
            name: 'Call center',
            description:
              'Number of products opened by contact centers via telephone banking in the respective product category',
          },
          {
            name: 'Total new products',
            description:
              'Number of products opened during the full period. Submit the information for total customers, accounts sold to new-to-bank customers, and sold to current account active customers. Criteria by product type: <br/>1) New deposit accounts and investment accounts should shall be counted regardless of any transactional activity within this period <br/>2) New mortgages, including instances where new account numbers are generated for extension, refinancing, and renewals <br/>3) New consumer loans (secured and unsecured), including renewals and extensions <br/>4) New credit cards opened and activated <br/>5) New investment accounts (including brokerage, mutual funds, Annuity, and IRA) opened <br/>6) New insurance sales',
          },
        ],
      },
      {
        title: 'Sales to new-to-bank customers',
        items: [
          {
            name: 'New products sold to new-to-bank customers',
            description:
              'Number of products opened during the full period to new-to-bank customers (New-to-bank customers are customers who began their relationship with the bank  during the study period and who formerly had no relationship with the bank)',
          },
        ],
      },
      {
        title: 'Accounts closed',
        items: [
          {
            name: 'Total accounts closed',
            description:
              'Number of products closed during the full data period. Submit this information for each product type',
          },
        ],
      },
    ],
  },
  {
    title: 'Employees',
    tables: [
      {
        title: 'Personal Banking FTEs and compensation',
        items: [
          {
            name: 'Front office',
            subitem: true,
          },
          {
            name: 'Branches',
            description: 'All staff located in the branch network',
          },
          {
            name: 'Contact center',
            description:
              'FTEs in contact centers for both incoming and outgoing services (excluding remote advisory). Roles and activities in contact centers include management, generalist sales, specialist sales, and service',
          },
          {
            name: 'Remote advisor',
            description:
              'FTEs who offer financial advice, guidance, and services to clients through virtual platforms such as phone calls, video conferencing, or online messaging (excluding contact center).',
          },
          {
            name: 'Third party sales force',
            description:
              'FTEs from third parties selling bank products, including agents, hunters, and other third party sales personnel',
          },
          {
            name: 'Non branch sales',
            description:
              'Sales force FTEs not based in branches or in contact centers. Includes FTEs in mobile sales force directly employed by bank, typically located in centralized locations or with a defined regional remit, visiting customers directly or in a defined set of branches.',
          },
          { name: 'Middle office', subitem: true },
          {
            name: 'Sales mgmt., Channel mgmt. & Product mgmt.',
            description:
              'Middle office FTE working on:<br />- Customer-driven tasks: definitions of overall retail banking strategy, customer research and insight, customer experience and segment management, etc.<br />- Product-driven tasks: product development and updates, product lifecycle and performance management, etc.<br />- Channel-driven tasks: channel strategy, technical management of sales channels, definition and monitoring of sales channel goals, coordination of sales channels, etc.',
          },
          {
            name: 'Collections & recoveries',
            description:
              'FTEs dedicated to recovering past-due and delinquent amounts owned by customers. Does not include FTEs writing or managing collections/risk policy',
          },
          { name: 'Support', subitem: true },
          {
            name: 'Operations',
            description:
              'Full time equivalents employed within that retail banking operation to support that retail banking operations and perform back office activities',
          },
          {
            name: 'Mortgage origination',
            description:
              'The number of full-time equivalent employees dedicated to the mortgage origination process. This includes staff involved in tasks such as loan application processing, underwriting, verification, approval, and closing activities related to mortgage lending',
          },
          {
            name: 'Mortgage servicing',
            description:
              'The number of full-time equivalent employees dedicated to the mortgage servicing function. This includes staff involved in activities such as collecting mortgage payments, managing escrow accounts, providing customer service to borrowers, handling delinquencies, processing loan modifications, and managing foreclosures.',
          },
          {
            name: 'Loan origination',
            description:
              'The number of full-time equivalent employees dedicated to the origination process of unsecured and secured loans (excluding mortgages). This includes staff involved in processing, underwriting, approving, and closing consumer loans such as personal loans, auto loans, credit cards, and other consumer lending products.',
          },
          {
            name: 'Retail loan servicing',
            description:
              'The number of full-time equivalent employees dedicated to servicing unsecured and secured loans (excluding mortgages). This includes staff involved in activities such as collecting loan payments, managing customer accounts, providing customer service to borrowers, handling delinquencies and defaults, processing loan modifications or extensions, and performing other administrative tasks related to consumer loans like personal loans, auto loans, credit cards, and other retail lending products.',
          },
          {
            name: 'Deposit servicing',
            description:
              'The number of full-time equivalent employees dedicated to servicing deposit accounts. This includes staff involved in activities such as account maintenance, processing transactions, handling customer inquiries related to deposits, updating account information, ensuring compliance with regulations, and providing support for deposit products',
          },
          {
            name: 'Other operations',
            description: 'Other Operation FTE not covered under above mentioned categories',
          },
          {
            name: 'Finance',
            description:
              'FTEs in business unit specific or central finance functions, including tax, accounting, controlling, reporting, working in or servicing the retail bank segment',
          },
          {
            name: 'Human resources',
            description:
              'FTEs in business unit specific or central human resources functions, including recruiting, talent and development, administration, etc. working in or servicing the retail bank segment',
          },
          {
            name: 'Risk management',
            description:
              'FTEs in business unit specific or central risk functions for all risk types (credit, market, liquidity, operational and others). Example: handling of policies, risk methodology, risk assessment, monitoring, recovery, etc',
          },
          {
            name: 'Compliance, internal audit & legal',
            description:
              'FTEs in compliance, legal, and control functions; includes those in AML and legal teams, internal audit/risk governance functions, those overseeing risk management compliance (second line of defence) and independent assurance (third line of defence)',
          },
          {
            name: 'IT',
            description:
              'FTEs in business unit specific or central technology functions working in or servicing the retail bank segment. Example: server maintenance, front-end development, IT support, etc',
          },
          { name: 'Compensation', subitem: true },
          {
            name: 'Base pay',
            description:
              'The fixed amount of compensation an employee receives for performing their job, usually expressed as an annual salary. Base pay does not include bonuses, incentives, or other forms of additional compensation.',
          },
          {
            name: 'Variable pay',
            description:
              "Additional compensation awarded to employees based on their performance, the bank's performance, or other criteria. This includes annual bonuses, incentives, commissions, and profit-sharing.",
          },
          {
            name: 'Benefits',
            description:
              'Any other benefits provided to employees as part of their total compensation package. This includes retirement and pension plans, paid leave, flexible compensation, and other benefits.',
          },
        ],
      },
    ],
  },
  {
    title: 'Digital channels',
    tables: [
      {
        title: 'Chatbot usage 90 days',
        items: [
          {
            name: 'Number of webchat sessions',
            description:
              'Number of web chat sessions between a customer and agent (human or automated chatbot) in the 90 days prior to end of study period. Please include sessions in which the customer leaves the session because queries are not resolved.',
          },
          {
            name: 'Sessions covered entirely by Chatbots',
            description:
              "Number of chat sessions where the chatbot handled all customer queries from start to finish without any involvement from a human agent. These sessions are fully automated and completed within the chatbot's capabilities. Please include sessions in which the customer leaves the session because queries are not resolved.",
          },
          {
            name: 'Sessions which requires human intervention',
            description:
              'Chat sessions that begin with a chatbot but need a human agent to step in at some point to assist the customer.',
          },
          {
            name: 'Number of FTEs handling webchat',
            description:
              'The total number of Full-Time Equivalent (FTE) employees dedicated to managing and responding to webchat sessions with customers. This includes all employees who work in webchat support, whether full-time or part-time, converted into a full-time basis to measure the total workforce effort allocated to webchat operations.',
          },
          {
            name: 'Number of complaints or issues reported',
            description:
              'The number of webchat sessions in which customers contact support to report a problem, dissatisfaction, or issue with a product or service. These sessions focus on resolving customer complaints and addressing service-related concerns.',
          },
          {
            name: 'Number of request of product information',
            description:
              'The number of webchat sessions where customers inquire about details or features of a bank product or service. These sessions involve answering questions related to product offerings, such as account types, interest rates, loan options, or promotional details.',
          },
          {
            name: 'Number of request to perform an action',
            description:
              'The number of webchat sessions in which customers request assistance to complete specific actions on their accounts or transactions. This may include requests to make a transfer, update account details, block a card, or initiate a loan application.',
          },
          {
            name: 'Other',
            description:
              'The number of webchat sessions that do not fall into the primary categories above. These sessions may include general inquiries, feedback, or any other interaction that does not specifically involve complaints, product information requests, or action requests.',
          },
        ],
      },
      {
        title: 'Customer activity',
        items: [
          {
            name: 'Mobile',
            description:
              'Number of unique customers who logged into the mobile banking app at least once in the 90/30 days prior to the end of the study period.',
          },
          {
            name: 'Online',
            description:
              'Number of unique customers who logged into the bank’s online or web banking platform at least once in the 90/30 days prior to the end of the study period.',
          },
          {
            name: 'Digital',
            description:
              'Number of unique customers who logged into either the mobile app or the online/web banking platform at least once in the 90/30 days prior to the end of the study period. This counts unique users across both channels.',
          },
          { name: 'Number of logins', subitem: true },
          {
            name: 'Mobile',
            description:
              "The total number of times customers logged into the bank's mobile banking app in the 12 months of the study period",
          },
          {
            name: 'Online',
            description:
              "The total number of times customers logged into the bank's online or web banking platform in the 12 months of the study period",
          },
        ],
      },
      {
        title: '90 days customer activity by age',
        items: [
          {
            name: 'Retail customers',
            description:
              'Number of unique retail customers active (at least one login) in the respective channel in the last 90 days by the respective age groups.',
          },
          {
            name: 'Primary customers',
            description:
              'Number of unique primary customers active (at least one login) in the respective channel in the last 90 days by the respective age groups.',
          },
        ],
      },
      {
        title: 'Digital enrollment',
        items: [
          {
            name: 'Number of customers enrolled',
            description:
              'Number of unique customers who are already enrolled through digital channels and are able to use the bank\'s services. A customer is considered "enrolled" when they have been given the ID and password needed to access the channel, even if they haven\'t used these login details yet. Please include joint account holders as separate customers. Example two customers who have a joint account and have two separate login details are considered as two enrolled customers.',
          },
          {
            name: 'Number of new to bank customers enrolled',
            description:
              'Number of unique new-to-bank customers enrolled through digital channels and are able to use the bank\'s services. A customer is considered "enrolled" when they have been given the ID and password needed to access the channel, even if they haven’t used these login details yet. Please include joint account holders as separate customers. Example two customers who have a joint account and have two separate login details are considered as two enrolled customers.',
          },
          {
            name: 'Newly enrolled customers that logged-on within the first 30 days',
            description:
              "Customers who have enrolled into the bank's online banking platform or mobile app in the last 12 months of the study period and have logged in at least once within the first 30 days after enrollment.",
          },
          {
            name: 'of which made a bill payment within the first 30 days',
            description:
              "Among the newly enrolled digital channel customers who logged in within the first 30 days after enrollment, number of unique customers who completed a bill payment using the bank's mobile app/online website within that same 30-day period.",
          },
          {
            name: 'of which made a P2P payment within the first 30 days',
            description:
              "Among the newly enrolled digital channel customers who logged in within the first 30 days after enrollment, number of unique customers who completed a P2P payment using the bank's mobile app/online website within that same 30-day period.",
          },
          {
            name: 'of which made a mobile cheque deposit within the first 30 days',
            description:
              "Among the newly enrolled digital channel customers who logged in within the first 30 days after enrollment, number of unique customers who completed a mobile cheque deposit using the bank's mobile app/online website within that same 30-day period.",
          },
        ],
      },
      {
        title: 'Checking accounts digital sales funnel',
        items: [
          {
            name: 'Online/Web public Site',
            description:
              "The bank's publicly accessible website, where customers can initiate account opening or other services without needing to log in.",
          },
          {
            name: 'Online/Web secure site',
            description:
              "The bank's secure online banking platform that customers access by logging in with their personal credentials.",
          },
          {
            name: 'Mobile app',
            description:
              "The bank's mobile application, designed for smartphones and tablets, where customers can log in with their credentials.",
          },
          {
            name: 'Number of page visits',
            description:
              "The total number of times users have accessed the bank's checking account web pages or mobile app screens within the digital sales funnel. This includes all visits to pages related to checking account offerings, counting every instance regardless of whether the visitor is unique or returning, and regardless of whether it led to an account opening.",
          },
          {
            name: 'Applications started',
            description:
              'Number of applications started in the channel whether these were subsequently submitted or abandoned. A customer has started an application if they have either clicked on "apply now", "start application", "submitted information" or equivalent message.',
          },
          {
            name: 'Applications completed',
            description:
              'Number of started applications in which all requested information has been provided by the customer and submitted to the bank for approval.',
          },
          {
            name: 'Applications which passed fraud and risk checks',
            description:
              "The number of completed checking account applications submitted through digital channels that have successfully completed the bank's fraud detection and risk assessment processes.",
          },
          {
            name: 'Applications approved',
            description:
              'The number of applications that have been approved after the bank receives the completed application, reviews and verifies all required documents and customer information, and decides to proceed with opening the account.',
          },
          {
            name: 'Accounts activated by client',
            description:
              'The number of accounts that have been formally opened and initially accessed or used by the client, indicating that the client has completed any required steps, such as logging in, making an initial deposit, or performing a transaction, to fully activate the account.',
          },
          {
            name: 'Accounts funded by client in the first 30 days',
            description: 'The number of accounts into which clients have deposited funds within the first 30 days.',
          },
          {
            name: 'Accounts closed by bank within the first 3 months due to fraud/funding issues',
            description:
              'The number of activated accounts that got closed down within the first 3 months due to fraud/funding issues.',
          },
          {
            name: 'Accounts closed by clients within the first 3 months',
            description: 'The number of activated accounts closed by the client within the first 3 months.',
          },
        ],
      },
      {
        title: 'Quality of sales in digital',
        items: [
          {
            name: 'Average balance in Checking accounts opened digitally end-to-end',
            description:
              'Average balance at the end of the study period in Checking accounts opened in digital channels (without any human intervention) in the 12 months of the study period.',
          },
          {
            name: 'Average balance in Savings accounts opened digitally end-to-end',
            description:
              'Average balance at the end of the study period in Savings accounts opened in digital channels (without any human intervention) in the 12 months of the study period.',
          },
          {
            name: 'Average balance in Credit cards acquired digitally end-to-end',
            description:
              'Average balance at the end of the study period in Credit cards acquired via digital channels (without any human intervention) in the 12 months of the study period.',
          },
        ],
      },
      {
        title: 'Transactions in digital',
        items: [
          {
            name: 'Customer initiated transaction',
            description:
              'Any financial transaction initiated by the customer that results in a change to the account balance of the associated product. This includes deposits, withdrawals, transfers, and payments made by the customer, excluding inquiries or non-financial activities.',
          },
          {
            name: 'Inter-account transfer',
            description: "Money transfer from customer's account to another account, within the same bank.",
          },
          {
            name: 'P2P Payments',
            description:
              "Transfers to another individual using the recipient's phone number, email address, or social media profile to identify the recipient.",
          },
          {
            name: 'Wire or global transfers',
            description:
              'This includes all customer-initiated transfers that move money from accounts via wire transfer services.',
          },
          {
            name: 'Bill payments',
            description:
              "Transactions where customers use the bank's services to pay bills owed to third-party service providers. This includes payments made to settle obligations such as utilities, credit cards, loans, insurance premiums, taxes, and other recurring or one-time bills.",
          },
          {
            name: 'Mobile cheque deposit',
            description:
              "Customer-initiated transactions where a cheque is deposited into a bank account using the bank's mobile application.",
          },
        ],
      },
      {
        title: 'Usage of digital features',
        items: [
          {
            name: 'Webchat (human)',
            description:
              'Number of unique customers who used the Webchat (Human) feature, which connects them to a human representative in real time for assistance, support, and information on banking products and services.',
          },
          {
            name: 'Webchat (chatbot)',
            description:
              'Number of unique customers who accessed the Webchat (Chatbot) feature, which enables them to interact with an automated chatbot that provides answers to common questions and guides them through basic banking tasks without human intervention.',
          },
          {
            name: 'P2P functionality',
            description:
              'Number of unique customers who used the peer-to-peer (P2P) functionality, allowing them to make payments directly to another individual by using a phone number, email address, or similar identifier instead of bank account details.',
          },
          {
            name: 'Bill payment',
            description:
              'Number of unique customers who accessed the Bill Payment feature, which enables them to pay bills directly from their accounts by scheduling one-time or recurring payments to service providers.',
          },
          {
            name: 'Mobile cheque deposit',
            description:
              'Number of unique customers who utilized the Mobile Check Deposit feature, allowing them to deposit checks remotely by taking a photo of the check through their mobile banking app.',
          },
          {
            name: 'Recurring transaction',
            description:
              'Number of unique customers who established recurring transactions, which allows them to automate regular payments, such as bill payments or transfers, on a consistent schedule.',
          },
        ],
      },
    ],
  },
  {
    title: 'Physical channels',
    tables: [
      {
        title: 'Number of branches and ATMs',
        items: [
          {
            name: 'Number of branches',
            description:
              'A physical channel for advisory and customer services located in a defined regional scope. Any retail outlet owned or rented by the bank and available for use by customers.',
          },
          {
            name: 'Number of ATMs',
            description:
              'Cash machines located in public places that enable access of funds and other services. This number should include: In-branch and remote ATMs, IDMs that allow cash withdrawals and deposits and ATMs.',
          },
        ],
      },
      {
        title: 'Customer activity in physical channels',
        items: [
          {
            name: 'Number of customers active in channel',
            description:
              'Number of unique customers who visited a branch/ATM at least once in the 90/30 days prior to the end of the study period.',
          },
          {
            name: 'of which also active in digital',
            description:
              "Number of unique customers who visited a branch/ATM at least once in the 90/30 days prior to the end of the study period AND also logged in at least once into the bank's website or mobile app during the same period.",
          },
          {
            name: 'Number of branch visits',
            description:
              'Number of customer branch visits. In case branch visits/interactions are not recorded please provide an estimate.',
          },
          {
            name: 'Number of unique authentications in ATM',
            description:
              'Number of unique authentications (customer-initiated operations) including both operations not leading directly to changes in the account balances (such as including account statements, balance inquiry, last movements, PIN change, etc.).',
          },
        ],
      },
      {
        title: 'Transactions in physical channels',
        items: [
          {
            name: 'Customer initiated transaction',
            description:
              'Number of transactions initiated directly by customers across various channels in the 12 months of the study period. Each transaction results in a change in the account balance of the underlying product.',
          },
          {
            name: 'Cash deposits',
            description: 'Number of transactions where customers deposit physical cash into their account.',
          },
          {
            name: 'Check deposits',
            description: 'Number of transactions where customers deposit checks into their account.',
          },
          {
            name: 'Cash withdrawal',
            description: 'Number of transactions where customers withdraw physical cash from their account.',
          },
          {
            name: 'Transfers',
            description:
              'Number of customer-initiated transactions involving the movement of funds between accounts within the bank or to another bank.',
          },
          {
            name: 'P2P payments',
            description:
              'Number of peer-to-peer (P2P) payment transactions where customers transfer funds directly to another individual using identifiers such as phone numbers or email addresses.',
          },
          {
            name: 'Wire or global transfers',
            description:
              'Number of customer-initiated transactions involving the transfer of funds domestically or internationally to accounts at different banks or across borders.',
          },
          {
            name: 'Bill Payments',
            description:
              "Number of transactions where customers use the bank's services to pay bills owed to third-party service providers. This includes payments made to settle obligations such as utilities, credit cards, loans, insurance premiums, taxes, and other recurring or one-time bills.",
          },
          {
            name: 'Other',
            description:
              'Number of transactions initiated by customers that do not fit into the specific categories listed above.',
          },
        ],
      },
      {
        title: 'Quality of sales in branches',
        items: [
          {
            name: 'Average balance in Checking accounts opened in branch',
            description:
              'Average balance at the end of the study period in Checking accounts opened in branches in the 12 months of the study period.',
          },
          {
            name: 'Average balance in Savings accounts opened in branch',
            description:
              'Average balance at the end of the study period in Savings accounts opened in branches in the 12 months of the study period.',
          },
          {
            name: 'Average balance in Credit cards acquired in branch',
            description:
              'Average balance at the end of the study period in Credit cards acquired in a branch in the 12 months of the study period.',
          },
        ],
      },
    ],
  },
  {
    title: 'Call center channels',
    tables: [
      {
        title: 'Contact center metrics',
        items: [
          {
            name: 'Average phone waiting time',
            description:
              'Average waiting time until customers are served by contact center agents with no time spent in the interactive-voice-response (IVR) system, or time spent in the IVR system until customer is routed to a contact center agent. Both for sales & service calls.',
          },
          {
            name: 'Average handling time per call - sales & advice',
            description:
              'Average handling time for servicing inbound customer calls (for both existing and new customers) answered by a contact center agent, when the content of the call is Sales and Advice. These calls might be handled by specialized or non-specialized staff.',
          },
          {
            name: 'Average handling time per call - service',
            description:
              'Average handling time for servicing inbound customer calls (for both existing and new customers) answered by a contact center agent, when the content of the call is servicing existing accounts. Excludes calls handled by IVR only.',
          },
          {
            name: 'Call abandonment rate',
            description:
              'Percentage of the incoming calls (Sales and Advice or Service) that the customer abandoned before the phone has been effectively answered.',
          },
          {
            name: 'Share of incoming calls covered by IVR only',
            description:
              'Share of incoming calls handled only by IVR system with no contact center agent interaction.',
          },
          {
            name: 'First call resolution',
            description:
              'Share of customer calls resolved in one call as % of total service calls answered by a contact center agent (excluding calls handled by IVR only).',
          },
          {
            name: 'Total inbound calls (excluding IVR)',
            description:
              'All inbound calls are phone calls that are initiated by existing or potential bank customers. These calls are typically directed to an inbound contact centre, where they are answered and handled by agents. Please exclude calls which are covered entirely by IVR without the need for a human agent.',
          },
          {
            name: 'Total outbound calls',
            description:
              'All calls initiated by a contact center agent to a customer on behalf of the bank. Outbound calls are typically made to prospective customers and focus on sales and lead generation.',
          },
          {
            name: 'Number of call centers',
            description:
              'Number of customer service centers handling bank inquiries and support that are either owned and operated by the bank itself (captive) or managed by third-party service providers under contract (outsourced).',
          },
          {
            name: 'Outsourced call centers',
            description:
              'Number of customer service centers handling bank inquiries and support that are fully managed by third-party service providers under contract (outsourced).',
          },
          {
            name: 'Offshore call centers',
            description:
              "A customer service center located outside the country of the bank's main operations or customer base.",
          },
        ],
      },
      {
        title: 'Customer activity in call centers',
        items: [
          {
            name: 'Call center',
            description:
              'A customer service channel where customers can contact the bank by phone to receive assistance with their accounts, perform transactions, and get information on products and services. Call centers are staffed by customer service representatives who provide support for a wide range of inquiries and banking needs.',
          },
          {
            name: 'Remote advisors',
            description:
              'A service channel where customers connect with dedicated advisors or relationship managers remotely, typically via phone, video calls, or secure messaging. Remote advisors offer personalized assistance for more complex financial needs, such as investment advice, loan inquiries, and product recommendations, providing a similar experience to in-branch consultations but accessible from any location.',
          },
          {
            name: 'Number of customers who called in the last 90 days (#)',
            description:
              'Number of customers who contacted the bank through the call center or remote advisor services at least once in the 90 days prior to the end of the study period. This includes calls made for assistance, inquiries, or to perform transactions. Include calls covered entirely by IVR.',
          },
          {
            name: 'Number of customers who called in the last 30 days (#)',
            description:
              'Number of customers who contacted the bank through the call center or remote advisor services at least once in the 30 days prior to the end of the study period. This includes calls made for assistance, inquiries, or to perform transactions. Include calls covered entirely by IVR.',
          },
        ],
      },
      {
        title: 'Transactions in call center',
        items: [
          {
            name: 'Customer initiated transaction',
            description:
              'Customer initiated actions or requests on contact center channel that lead to changes in account balances. Submit this information for full period of the study.',
          },
          {
            name: 'Transfers',
            description:
              'Number of customer-initiated transactions involving the movement of funds between accounts within the bank or to another bank.',
          },
          {
            name: 'Wire or global transfers',
            description:
              'Number of customer-initiated transactions involving the transfer of funds domestically or internationally to accounts at different banks or across borders.',
          },
          {
            name: 'Bill Payments',
            description:
              "Number of transactions where customers use the bank's services to pay bills owed to third-party service providers. This includes payments made to settle obligations such as utilities, credit cards, loans, insurance premiums, taxes, and other recurring or one-time bills.",
          },
        ],
      },
    ],
  },
];
