import React, { Fragment, useRef, useEffect } from 'react';
import classes from './ChatBox.module.css';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import chatBot from '../../../../assets/svg/chatBot.svg';
import ChatMessage from './ChatMessage';
import { useSendMessageMutation } from '../../../../utils/redux/endpoints/chatbotEndpoints';
import type { IChat, IChatResponse } from '../../../../utils/types/ChatBot';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

interface IChatBoxProps {
  setIsChatDialogOpen: (value: boolean) => void;
}

const randomQuestions: string[] = [
  '% of customers who use Mobile banking app more than once a week in Germany?',
  'What % of Low income Male customers using ATM globally are under 40?',
  'What are the top 3 most popular channels used by customers in Germany under the age of 35?',
  'Which bank has the highest proportion of customers using mobile app in Spain?',
  'Which highest education qualification segment visit branches the most in Germany?',
];

const ChatBox: React.FC<IChatBoxProps> = ({ setIsChatDialogOpen }) => {
  const [message, setMessage] = React.useState<string>('');
  const [chatMessages, setChatMessages] = React.useState<any[]>([]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);
  const [sendMessage] = useSendMessageMutation(); // Removed unused vars isLoading and error
  let typingInterval: NodeJS.Timeout | null = null;
  const [chatTopic, setChatTopic] = React.useState<string>('');

  useEffect(() => {
    const savedMessages = sessionStorage.getItem('chatMessages');
    if (savedMessages !== null) {
      setChatMessages(JSON.parse(savedMessages) as any[]);
    }
  }, []);

  // Save messages to session storage whenever chatMessages changes
  useEffect(() => {
    if (chatMessages.length > 0) {
      sessionStorage.setItem('chatMessages', JSON.stringify(chatMessages));
    }
    scrollToBottom();
  }, [chatMessages]);

  const sendMessageHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (message.trim() === '') return;

    // Add user's message
    setChatMessages([...chatMessages, { id: 'user', message }]);
    setMessage('');
    setIsChatLoading(true);

    // Add "genie" placeholder message
    setChatMessages((prevMessages) => [...prevMessages, { id: 'genie', message: 'Evaluating your query' }]);

    // Start cycling text messages
    startGenieTyping();

    // Perform the API call
    await postNewMessageRequest(message);
  };

  const postNewMessageRequest = async (message: string) => {
    try {
      const payload = { message };
      const response: IChatResponse = await sendMessage(payload).unwrap();

      // Stop typing effect when response is received
      stopGenieTyping();

      if (response?.chart?.is_chart === true) {
        const { categories, values } = response.chart.data;
        const chartMessage = response.chart?.message !== undefined ? response.chart?.message : 'Here is your chart';

        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            id: 'genie',
            message: response.message ?? '',
            is_chart: true,
            chart: { categories, values, chartMessage },
          },
        ]);
      } else {
        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { id: 'genie', message: response.message ?? '', is_chart: false },
        ]);
      }

      setIsChatLoading(false);
    } catch (err) {
      console.error('Failed to send message:', err);

      // Stop typing effect if there’s an error
      stopGenieTyping();
      setChatMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        { id: 'genie', message: 'Something went wrong. Please try again.', is_chart: false },
      ]);
      setIsChatLoading(false);
    }
  };

  /* Typing Effect Implementation */
  const startGenieTyping = () => {
    const typingMessages = [
      'Evaluating your query',
      'Please hold on...',
      'Gathering insights...',
      'Processing your request...',
      'Almost there...',
      'Looking into the data...',
      'Checking the details...',
      'Crunching some numbers...',
      'Fetching the information...',
      'Double-checking the results...',
      'Preparing your response...',
      'Analyzing your input...',
      'Getting everything ready...',
    ];
    let currentIndex = 0;

    // Start cycling through messages
    typingInterval = setInterval(() => {
      setChatMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...updatedMessages[updatedMessages.length - 1],
          message: typingMessages[currentIndex],
        };
        return updatedMessages;
      });

      // Cycle through the messages
      currentIndex = (currentIndex + 1) % typingMessages.length;
    }, 700); // Change message every 2 seconds
  };

  const stopGenieTyping = () => {
    if (typingInterval !== null) {
      clearInterval(typingInterval);
      typingInterval = null;
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Fragment>
      <DialogTitle
        sx={{
          backgroundColor: '#00796b',
          color: '#fff',
          padding: 'auto 0.3rem !important',
          display: 'flex',
          gap: '.3rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Chat with Genie </Typography>
        <IconButton
          onClick={() => {
            setIsChatDialogOpen(false);
          }}
          sx={{ color: '#fff' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {chatTopic !== '' ? (
        <DialogContent className={classes.content_wrapper}>
          {chatMessages.length > 0 ? (
            <div className={classes.chat_wrapper}>
              {chatMessages.map((chat, index) => (
                <ChatMessage
                  key={index} // Added key prop
                  id={chat.id}
                  message={chat.message}
                  isLastIndex={index === chatMessages.length - 1}
                  isChatLoading={isChatLoading}
                  isChart={chat.is_chart} // Use is_chart, not isChart
                  chart={
                    chat.chart !== undefined
                      ? {
                          categories: chat.chart.categories,
                          values: chat.chart.values,
                          chartMessage: chat.chart.chartMessage,
                        }
                      : undefined
                  }
                />
              ))}
              <div ref={chatEndRef} />
            </div>
          ) : (
            <section className={classes.no_chat_data}>
              <img src={chatBot} alt="chat icon" height="60px" width="60px" />
              <h3>Hi, I am Genie. I can help you with consumer survey information.</h3>
              <ul className={classes.question_card}>
                <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>Ask me Anything like:</Typography>
                {randomQuestions.map((question, index) => (
                  <li
                    key={index} // Added key prop
                    className={classes.list_item}
                    onClick={() => {
                      setMessage(question);
                    }}
                  >
                    {question}
                  </li>
                ))}
              </ul>
            </section>
          )}
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: 0 }}>
          <Box className={classes.topic_nav_wrapper}>
            <Box
              className={classes.topic_nav}
              onClick={() => {
                setChatTopic('1');
              }}
            >
              <section className={classes.nav_icon}>
                <BarChartRoundedIcon sx={{ fontSize: '5em' }} />
              </section>
              <section className={classes.nav_title}>Channel Usage</section>
            </Box>
            <Box className={classes.topic_nav}>
              <section className={classes.nav_icon}>
                <ThumbsUpDownRoundedIcon sx={{ fontSize: '5em' }} />
              </section>
              <section className={classes.nav_title}>Channel Satisfaction</section>
            </Box>
            <Box className={classes.topic_nav}>
              <section className={classes.nav_icon}>
                <EmojiEventsRoundedIcon sx={{ fontSize: '5em' }} />
              </section>
              <section className={classes.nav_title}>Net Promoter Score</section>
            </Box>
            <Box className={classes.topic_nav}>
              <section className={classes.nav_icon}>
                <QuestionAnswerRoundedIcon sx={{ fontSize: '5em' }} />
              </section>
              <section className={classes.nav_title}>Drivers of NPS</section>
            </Box>
            <Box className={classes.topic_nav}>
              <section className={classes.nav_icon}>
                <GroupRoundedIcon sx={{ fontSize: '5em' }} />
              </section>
              <section className={classes.nav_title}>Product ownership</section>
            </Box>
          </Box>
        </DialogContent>
      )}

      {chatTopic !== '' ? (
        <DialogActions className={classes.action_wrapper}>
          <form className={classes.form_wrapper} onSubmit={sendMessageHandler}>
            <div className={classes.input_message_wrapper}>
              <TextField
                value={message}
                disabled={isChatLoading}
                fullWidth
                size="small"
                focused
                placeholder="Ask me anything"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMessage(event.target.value);
                }}
              />
            </div>
            <div className={classes.chat_action_wrapper}>
              <IconButton
                sx={{ color: '#00796b' }}
                disabled={message.trim() === ''}
                type="submit"
                aria-label="Send Message"
              >
                <SendIcon />
              </IconButton>
            </div>
          </form>
        </DialogActions>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default ChatBox;
