import { type Dispatch, type UnknownAction } from '@reduxjs/toolkit';
import {
  type SelectedCell,
  setActiveCell,
  setActiveDataTableId,
  setOpenComments,
} from '../../../../../../../../utils/redux/dataTablesSlice';

export const handleOpenComments = (
  dispatch: Dispatch<UnknownAction>,
  dataTableId: number | undefined,
  selectedCell: SelectedCell,
) => {
  if (dataTableId === undefined) return;
  dispatch(setActiveCell(selectedCell));
  dispatch(setActiveDataTableId(dataTableId));
  dispatch(setOpenComments(true));
};
