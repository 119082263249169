import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import ConsumerSurvey from '../../../../../assets/images/ConsumerSurvey.jpg';
import Data from '../../../../../assets/images/Data.jpg';
import DetailedResults from '../../../../../assets/images/DetailedResults.jpg';
import Insights from '../../../../../assets/images/KeyThemesAndInsights.jpg';

import ResourcesWrapper from '../components/ResourcesWrapper';

const HowToUse: React.FC = () => {
  const howToUseContent = [
    {
      img: Data,
      title: 'Data',
      description: 'In this section you can upload data required for running the benchmarking exercise.',
    },
    {
      img: Insights,
      title: 'Key Themes and Insights',
      description:
        'This summary highlights key findings of the results together with actionable insights from the benchmarking exercise.',
    },
    {
      img: DetailedResults,
      title: 'Detailed Results',
      description: 'This section contains full sets of KPIs available in REBEX benchmarking.',
    },
    {
      img: ConsumerSurvey,
      title: 'Consumer Survey',
      description:
        "In this section you can find the results of the BCG's annual 'Voice of the Customer' Retail Banking survey.",
    },
  ];

  return (
    <ResourcesWrapper
      title="How to Use REBEX"
      subtitle="This page gives a brief overview of the Insights Dashboard and its different sections."
    >
      <Typography variant="titleMedium">There are 4 main sections:</Typography>
      <Grid container spacing={4}>
        {howToUseContent.map((content, idx) => (
          <Grid item key={idx} xs={12} sm={6} lg={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ mb: 3, width: '100%', height: '250px', overflow: 'hidden' }}>
                <img
                  src={content.img}
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
                />
              </Box>
              <Typography variant="titleLarge" sx={{ mb: 4, textAlign: 'center' }}>
                {content.title}
              </Typography>
              <Typography variant="bodyMedium" sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                {content.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </ResourcesWrapper>
  );
};

export default HowToUse;
