import { CloudUpload, Delete, Download, Edit, Search, Upload } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import RedesignAppButton from '../../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../context/toastContext';
import { formatDate } from '../../../../../../utils/dateUtils';
import { useAppSelector } from '../../../../../../utils/hooks/useAppSelector';
import {
  useDeleteWorkspaceDocumentMutation,
  useGetWorkspaceDocumentImagesQuery,
  useGetWorkspaceDocumentsQuery,
  useGetWorkspaceDocumentsSASTokenQuery,
  useUpdateWorkspaceDocumentMutation,
  useUploadWorkspaceDocumentMutation,
} from '../../../../../../utils/redux/api';
import { type WorkspaceDocument } from '../../../../../../utils/types/WorkspaceDocument';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import ReportPublicationCard from '../../components/ReportPublicationCard';

interface IConfirmationModalDetails {
  title: string;
  description: string;
  action?: () => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type Order = 'asc' | 'desc';

interface TableHeading {
  id: keyof WorkspaceDocument;
  label: string;
  width?: string;
}

const tableHeads: readonly TableHeading[] = [
  { id: 'document_name', label: 'File Name', width: '35%' },
  { id: 'document_description', label: 'Description' },
  { id: 'user', label: 'Uploaded By', width: '150px' },
  { id: 'created_at', label: 'Date Uploaded', width: '120px' },
  { id: 'updated_at', label: 'Last Update', width: '120px' },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof WorkspaceDocument) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { palette } = useTheme();

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof WorkspaceDocument) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeads.map((head) => (
          <TableCell
            key={head.id}
            sortDirection={orderBy === head.id ? order : false}
            width={head.width ?? 'auto'}
            sx={
              orderBy === head.id
                ? {
                    backgroundColor: palette.green[50],
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: palette.green[500],
                '&.Mui-active': {
                  color: palette.green[500],
                  backgroundColor: palette.green[50],
                },
                svg: {
                  color: `${palette.green[500]} !important`,
                },
              }}
            >
              <span>{head.label}</span>
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell width="125px"></TableCell>
      </TableRow>
    </TableHead>
  );
}

const ReportsPublications: React.FC = () => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);
  const { workspaceId } = useParams<{ workspaceId: string; moduleName: string }>();
  const userDetails = useAppSelector((state) => state.auth.userDetails);
  const [operationLoading, setOperationLoading] = useState<boolean>(false);

  // sorting, selecting, filtering and pagination
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof WorkspaceDocument>('document_name');
  const [search, setSearch] = useState<string>('');
  // TO-DO: Implement pagination
  // const [page, setPage] = useState(0);
  // const rowsPerPage = 10;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState<IConfirmationModalDetails>();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof WorkspaceDocument) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // TO-DO: Implement pagination
  /*
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  */

  const {
    data: documents,
    refetch: documentsRefetch,
    isFetching: loading,
  } = useGetWorkspaceDocumentsQuery({
    workspaceId: Number(workspaceId),
  });

  const { data: sasToken } = useGetWorkspaceDocumentsSASTokenQuery({
    workspaceId: Number(workspaceId),
  });

  const { data: documentImages } = useGetWorkspaceDocumentImagesQuery(undefined);

  useEffect(() => {
    void documentsRefetch();
  }, [documentsRefetch]);

  // upload
  const fileInput = useRef<HTMLInputElement>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [fileDescription, setFileDescription] = useState<string>('');

  const [uploadFile] = useUploadWorkspaceDocumentMutation();

  const handleUpload = function () {
    setOperationLoading(true);

    uploadFile({
      workspace_id: Number(workspaceId),
      document_description: fileDescription,
      document_name: fileToUpload!.name,
      file: fileToUpload!,
      image: selectedImage!,
    })
      .then((res: any) => {
        setOperationLoading(false);
        if (res.error !== undefined) {
          handleOpenToast({ message: `Error uploading file: ${res.error.data.message}`, severity: 'error' });
        } else {
          handleOpenToast({ message: 'File uploaded successfully', severity: 'success' });
          setIsUploadModalOpen(false);
          setFileToUpload(null);
          setFileDescription('');
          setSelectedImage('');
          fileInput.current!.value = '';
          void documentsRefetch();
        }
      })
      .catch((err) => {
        console.error(err);
        setOperationLoading(false);
        handleOpenToast({ message: 'Error uploading file', severity: 'error' });
      });
  };

  // edit
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<WorkspaceDocument | null>(null);
  const [tableView, setTableView] = useState(false);

  const [updateDocument] = useUpdateWorkspaceDocumentMutation();

  const handleEdit = function () {
    setOperationLoading(true);

    updateDocument(selectedDocument!)
      .then((res: any) => {
        setOperationLoading(false);
        if (res.error !== undefined) {
          handleOpenToast({ message: `Error updating file: ${res.error.data.message}`, severity: 'error' });
        } else {
          handleOpenToast({ message: 'File updated successfully', severity: 'success' });
          setIsEditModalOpen(false);
          void documentsRefetch();
        }
      })
      .catch((err) => {
        console.error(err);
        setOperationLoading(false);
        handleOpenToast({ message: 'Error updating file', severity: 'error' });
      });
  };

  // delete
  const [deleteDocumentTrigger, { isLoading: isDeleteDocumentLoading }] = useDeleteWorkspaceDocumentMutation();

  const deleteDocument = async (documentId: number) => {
    try {
      await deleteDocumentTrigger(documentId).unwrap();

      handleOpenToast({
        message: 'Document deleted successfully',
        severity: 'success',
      });

      await documentsRefetch();
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not delete document',
      });
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const confirmationModalDetailsModes: Record<string, IConfirmationModalDetails> = {
    delete: {
      title: 'Are you sure that you want to delete this document?',
      description: 'This action is irreversible.',
    },
  };

  const handleDeleteDocument = (documentId: number) => {
    setConfirmationModalDetails({
      title: confirmationModalDetailsModes.delete.title,
      description: confirmationModalDetailsModes.delete.description,
      action: () => {
        void deleteDocument(documentId);
      },
    });
    setIsConfirmationModalOpen(true);
  };

  const filteredData: WorkspaceDocument[] = useMemo(
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    () => {
      if (documents !== undefined) {
        return [...documents]
          .filter((row) => row.document_name.toLowerCase().includes(search.toLowerCase()))
          .sort(getComparator(order, orderBy));
      }
      return [];
    },
    [documents, search, order, orderBy],
  );

  const [selectedImage, setSelectedImage] = useState<string>();

  const handleImageClick = (image: string) => {
    setSelectedImage(image); // Highlight selected image
  };

  // TO-DO: Implement pagination

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        borderRadius: '8px',
        border: `1px solid ${palette.gray[300]}`,
        backgroundColor: palette.gray.white,
        padding: '24px 32px',
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" gap={3}>
          <FormControl fullWidth sx={{ width: '280px' }}>
            <InputLabel htmlFor="outlined-adornment-amount">Search File Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              label="Search a Peer Group Name"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <InputLabel id="sort-select-label">Sort</InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              label="Sort"
              value={orderBy}
              onChange={(e) => {
                setOrder('asc');
                setOrderBy(e.target.value as keyof WorkspaceDocument);
              }}
              sx={{ width: '210px' }}
            >
              <MenuItem value="document_name">File Name</MenuItem>
              <MenuItem value="document_description">Description</MenuItem>
              <MenuItem value="user">Uploaded By</MenuItem>
              <MenuItem value="created_at">Date Uploaded</MenuItem>
              <MenuItem value="updated_at">Last Update</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {userDetails?.roles?.[0] === 'admin' && (
          <RedesignAppButton
            appVariant="filled"
            widePadding={true}
            startIcon={<Upload />}
            onClick={() => {
              setIsUploadModalOpen(true);
            }}
          >
            Upload File
          </RedesignAppButton>
        )}
      </Box>
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        <Typography color="textSecondary">Card View</Typography>
        <Switch
          checked={tableView}
          onChange={() => {
            setTableView((prevTableView) => !prevTableView);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          color="success"
        />
        <Typography color="textSecondary">Table View</Typography>
      </Stack>

      {loading ? (
        <Backdrop open sx={{ zIndex: 1000 }}>
          <AppCircularProgress />
        </Backdrop>
      ) : !loading ? (
        <>
          {tableView && (
            <TableContainer
              sx={{
                borderRadius: '8px',
                border: `1px solid ${palette.gray[250]}`,
              }}
            >
              <Table>
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData.map((document, idx) => {
                      return (
                        <TableRow
                          key={document.document_id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            'td>div': { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
                          }}
                        >
                          <TableCell
                            sx={
                              orderBy === 'document_name'
                                ? {
                                    backgroundColor: palette.green[50],
                                    color: palette.green[500],
                                  }
                                : null
                            }
                          >
                            <Tooltip title={document.document_name.slice(0, document.document_name.lastIndexOf('.'))}>
                              <div>{document.document_name.slice(0, document.document_name.lastIndexOf('.'))}</div>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            sx={
                              orderBy === 'document_description'
                                ? {
                                    backgroundColor: palette.green[50],
                                    color: palette.green[500],
                                  }
                                : null
                            }
                          >
                            <Tooltip title={document.document_description}>
                              <div>{document.document_description}</div>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            sx={
                              orderBy === 'user'
                                ? {
                                    backgroundColor: palette.green[50],
                                    color: palette.green[500],
                                  }
                                : null
                            }
                          >
                            <div>{document.user}</div>
                          </TableCell>
                          <TableCell
                            sx={
                              orderBy === 'created_at'
                                ? {
                                    backgroundColor: palette.green[50],
                                    color: palette.green[500],
                                  }
                                : null
                            }
                          >
                            {formatDate(document.created_at ?? '')}
                          </TableCell>
                          <TableCell
                            sx={
                              orderBy === 'updated_at'
                                ? {
                                    backgroundColor: palette.green[50],
                                    color: palette.green[500],
                                  }
                                : null
                            }
                          >
                            {formatDate(document.updated_at ?? '')}
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="space-between">
                              <Box>
                                {userDetails?.roles?.[0] === 'admin' && (
                                  <span>
                                    <IconButton
                                      aria-label="edit"
                                      sx={{ padding: 0 }}
                                      onClick={() => {
                                        setSelectedDocument({
                                          ...document,
                                          document_name: document.document_name.slice(
                                            0,
                                            document.document_name.lastIndexOf('.'),
                                          ),
                                        });
                                        setIsEditModalOpen(true);
                                      }}
                                    >
                                      <Edit htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: '16px' }} />
                                    </IconButton>
                                  </span>
                                )}
                                <a href={`${document.file_path}?${sasToken?.sas_token}`}>
                                  <IconButton aria-label="download" sx={{ padding: 0 }}>
                                    <Download
                                      htmlColor={palette.green[500]}
                                      sx={{ margin: '8px', fontSize: '16px' }}
                                    />
                                  </IconButton>
                                </a>
                              </Box>
                              {userDetails?.roles?.[0] === 'admin' && (
                                <>
                                  <Divider orientation="vertical" flexItem color={palette.gray[300]} />
                                  <IconButton
                                    onClick={() => {
                                      handleDeleteDocument(document.document_id);
                                    }}
                                    aria-label="delete"
                                    sx={{ padding: 0 }}
                                  >
                                    <Delete htmlColor={palette.red[500]} sx={{ margin: '8px', fontSize: '16px' }} />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!tableView && (
            <>
              {filteredData.length > 0 ? (
                <Grid container rowSpacing={3} columnSpacing={2}>
                  {filteredData.map((document, idx) => {
                    return (
                      <Grid item key={idx} xs={12} sm={6} lg={4} xl={3}>
                        <ReportPublicationCard
                          document={document}
                          setSelectedDocument={setSelectedDocument}
                          setIsEditModalOpen={setIsEditModalOpen}
                          handleDeleteDocument={handleDeleteDocument}
                          sasToken={sasToken}
                          userDetails={userDetails}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  No records found
                </Box>
              )}
            </>
          )}
        </>
      ) : null}

      <ConfirmationModal
        title={confirmationModalDetails?.title ?? 'Confirm Operation'}
        text={confirmationModalDetails?.description ?? 'Are you sure?'}
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        onSave={
          confirmationModalDetails?.action ??
          (() => {
            setIsConfirmationModalOpen(false);
          })
        }
        isLoading={isDeleteDocumentLoading}
      />

      <Modal
        open={isUploadModalOpen}
        onClose={() => {
          setIsUploadModalOpen(false);
        }}
      >
        <Box
          position="absolute"
          bgcolor="white"
          sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          p={2}
          display="flex"
          flexDirection="column"
          borderRadius={1}
          gap={3}
          width="580px"
        >
          {operationLoading ? (
            <Backdrop open sx={{ zIndex: 1000 }}>
              <AppCircularProgress />
            </Backdrop>
          ) : null}
          <Typography variant="titleMedium">Upload File</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
                justifyContent="center"
                border={`2px dashed ${palette.gray[300]}`}
                borderRadius={1}
                height="150px"
              >
                {fileToUpload === null ? (
                  <IconButton
                    aria-label="upload-file"
                    size="large"
                    disableRipple
                    onClick={() => {
                      fileInput.current?.click();
                    }}
                    sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: 1 }}
                  >
                    <CloudUpload sx={{ fontSize: '32px' }} />
                    <Typography variant="bodyMedium" color={'textSecondary'}>
                      Select file to upload
                    </Typography>
                  </IconButton>
                ) : (
                  <Chip
                    label={fileToUpload.name}
                    onDelete={() => {
                      setFileToUpload(null);
                      fileInput.current!.value = '';
                    }}
                  />
                )}
              </Box>
              <input
                type="file"
                name="document-upload"
                accept=".xls,.xlsx,.csv,.pdf,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png"
                ref={fileInput}
                onChange={(e) => {
                  const input: HTMLInputElement = e.target as HTMLInputElement;
                  setFileToUpload(input.files !== null ? input.files[0] : null);
                }}
                style={{ display: 'none' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth={true}
                value={fileDescription}
                onChange={(e) => {
                  setFileDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="titleMedium">Select an Image</Typography>

          <Grid container spacing={2} height="500px" sx={{ overflowY: 'scroll' }}>
            {documentImages?.images.map((image: string, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  sx={{
                    border: selectedImage === image ? `3px solid #FFD700` : 'none',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      handleImageClick(image);
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={image}
                      alt={`Image ${index + 1}`}
                      sx={{ height: 100 }}
                      style={{ opacity: 0, transition: 'opacity 0.3s' }}
                      onLoad={(e) => (e.currentTarget.style.opacity = '1')}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <RedesignAppButton
              appVariant="outlined"
              onClick={() => {
                setIsUploadModalOpen(false);
                setFileToUpload(null);
                setFileDescription('');
                setSelectedImage('');
              }}
            >
              Cancel
            </RedesignAppButton>
            <RedesignAppButton
              appVariant="filled"
              disabled={fileToUpload === null || fileDescription.trim() === '' || selectedImage?.trim() === ''}
              onClick={handleUpload}
            >
              Upload
            </RedesignAppButton>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
      >
        <Box
          position="absolute"
          bgcolor="white"
          sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          p={2}
          display="flex"
          flexDirection="column"
          borderRadius={1}
          gap={3}
          width="580px"
        >
          {operationLoading ? (
            <Backdrop open sx={{ zIndex: 1000 }}>
              <AppCircularProgress />
            </Backdrop>
          ) : null}
          <Typography variant="titleMedium">Edit File</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="File Name"
                variant="outlined"
                fullWidth={true}
                value={selectedDocument?.document_name}
                onChange={(e) => {
                  setSelectedDocument({
                    ...selectedDocument!,
                    document_name: e.target.value,
                  });
                }}
              />
            </Grid>
            {/* TO-DO: Implement description in file update */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth={true}
                value={selectedDocument?.document_description}
                onChange={(e) => {
                  setSelectedDocument({
                    ...selectedDocument!,
                    document_description: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="titleMedium">Select an Image</Typography>

          <Grid container spacing={2} height="500px" sx={{ overflowY: 'scroll' }}>
            {documentImages?.images.map((image: string, index) => (
              <Grid item xs={4} key={index}>
                <Card
                  sx={{
                    border:
                      selectedDocument?.image_url?.split('?')[0] === image.split('?')[0]
                        ? `3px solid #FFD700`
                        : 'none',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      setSelectedDocument({
                        ...selectedDocument!,
                        image_url: image,
                      });
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={image}
                      alt={`Image ${index + 1}`}
                      sx={{ height: 100 }}
                      style={{ opacity: 0, transition: 'opacity 0.3s' }}
                      onLoad={(e) => (e.currentTarget.style.opacity = '1')}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <RedesignAppButton
              appVariant="outlined"
              onClick={() => {
                setIsEditModalOpen(false);
              }}
            >
              Cancel
            </RedesignAppButton>
            <RedesignAppButton
              appVariant="filled"
              onClick={handleEdit}
              disabled={selectedDocument?.document_name.trim() === ''}
            >
              Save Changes
            </RedesignAppButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ReportsPublications;
