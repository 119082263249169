import { Box, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import ChartBox from './components/ChartBox';
import TableauAuthenticator from '../../../../../../../DataManagementHub/components/TableauAuthenticator';
import { useParams } from 'react-router-dom';
import getTicketRedeemTimestamp from '../../../../../../../DataManagementHub/helpers/getTicketRedeemTimestamp';
import { TableauAuthContext } from '../../../../../../../DataManagementHub/contexts/tableauAuthProvider';

const isTableauAuthenticated = (workspaceId: string | undefined): boolean => {
  const ticketRedeemTs = getTicketRedeemTimestamp(Number(workspaceId));
  return ticketRedeemTs !== null && ticketRedeemTs !== undefined;
};

const SubModules: React.FC = () => {
  const { workspaceId, submoduleNames } = useParams<{
    submoduleNames: string;
    workspaceId: string;
    moduleName: string;
    workspaceName: string;
  }>();
  const [tableauAuthenticated, setTableauAuthenticated] = useState(isTableauAuthenticated(workspaceId));
  const tableauAuthContext = useContext(TableauAuthContext);
  if (tableauAuthContext === undefined) {
    throw new Error('TableauAuthContext is undefined');
  }
  const { isSuccess: isTicketSuccess, trustedTicket } = tableauAuthContext ?? {};
  const onLoadTableau = () => {
    setTimeout(() => {
      setTableauAuthenticated(true);
    }, 2000); // this is to ensure that the auth token will be stored. at most it takes about 900 ms to be stored, but this is to be safe
  };

  const selectedSubmoduleNames = useMemo(() => {
    if (submoduleNames === undefined) return [];
    return submoduleNames.split(',');
  }, [submoduleNames]);

  return (
    <>
      {!tableauAuthenticated && (
        <>
          Loading Tableau authentication...
          <TableauAuthenticator
            onLoadTableau={onLoadTableau}
            isTicketSuccess={isTicketSuccess}
            trustedTicket={trustedTicket}
          />
        </>
      )}
      {tableauAuthenticated && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}
        >
          {selectedSubmoduleNames.length > 0 ? (
            selectedSubmoduleNames.map((submoduleName, index) => (
              <Box key={submoduleName} sx={{ flex: '1 1 45%', minWidth: '300px', height: '400px' }}>
                <ChartBox submoduleName={submoduleName} />
              </Box>
            ))
          ) : (
            <Typography variant="body1">No KPIs selected.</Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default SubModules;
