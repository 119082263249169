import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';

import AppAutoComplete from '../../../../../../../../components/materials/forms/AppAutoComplete';
import { categories } from '../../../../../../../../utils/kpiCategories';

const SearchBox: React.FC = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const baseUrl = useResolvedPath('');
  const { moduleName } = useParams();
  const [selectedKpis, setSelectedKpis] = useState<any[]>([]);

  const kpis = useMemo(
    () =>
      categories
        .flatMap((category) =>
          category.options.map((option) => ({
            ...option,
            category_label: category.category_label,
            module_name: category.module_name,
          })),
        )
        .filter((category) => category.module_name === moduleName),
    [moduleName],
  );

  useEffect(() => {
    setSelectedKpis([]);
  }, [moduleName]);

  const onChangeSearch = (event: any, value: any[]) => {
    setSelectedKpis(value);
    const nextUrlBase = baseUrl.pathname.split('/').slice(0, -1).join('/');
    if (value.length === 0) {
      navigate(nextUrlBase);
      return;
    }

    const submoduleNames = value.map((kpi) => kpi.option_param).join(',');
    const moduleName = value[0].module_name;
    const nextUrl = `${nextUrlBase}/${moduleName}/${submoduleNames}`;
    navigate(nextUrl);
  };

  return (
    <AppAutoComplete
      multiple={true}
      options={kpis}
      value={selectedKpis}
      getOptionLabel={(option) => option.option_label}
      groupBy={(option) => option.category_label}
      onChange={onChangeSearch}
      label="KPI Categories"
      placeholder="All Categories"
      renderGroup={(params) => {
        return (
          <li key={params.key}>
            <Box sx={{ position: 'sticky', top: '-8px', background: palette.green[200], p: 1 }}>
              <Typography variant="titleMedium">{params.group}</Typography>
            </Box>
            <Box component="ul">{params.children}</Box>
          </li>
        );
      }}
      sx={{ minWidth: '400px', maxWidth: '600px' }}
    />
  );
};

export default SearchBox;
