import React, { Fragment, useEffect } from 'react';
import classes from './ChatBox.module.css';
import Person2Icon from '@mui/icons-material/Person2';
import chatBot from '../../../../assets/svg/chatBot.svg';
import { CircularProgress } from '@mui/material';
import ChatBarChart from './ChatBarChart'; // Import the ChatBarChart component

interface ChatMessageProps {
  id: string;
  message: string | null; // Explicitly typed message to avoid 'any'
  isChatLoading?: boolean;
  isLastIndex: boolean;
  isChart?: boolean; // Renamed to camelCase
  chart?: { categories: string[] | undefined; values: number[] | undefined; chartMessage: string }; // Chart data if isChart is true
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  id,
  message,
  isChatLoading = false, // Default value for isChatLoading
  isLastIndex,
  isChart, // camelCase
  chart,
}) => {
  useEffect(() => {}, []);

  return (
    <div className={id === 'user' ? classes.user_chat : classes.genie_chat}>
      {id === 'genie' ? (
        <>
          <div className={classes.chat_icon_wrapper}>
            <img src={chatBot} alt="Genie Icon" className={classes.bot_icon} />
          </div>
          {isChatLoading ? (
            <>
              <span className={classes.message_text}>
                {message}
                {isLastIndex && <CircularProgress sx={{ color: '#fff' }} size="1rem" />}
              </span>
            </>
          ) : (
            // If the response contains a chart, render the chart; otherwise, render the text
            <>
              {isChart === true && chart != null ? ( // Explicitly checking chart for nullish values
                <div className={classes.chart_chat_wrapper}>
                  <span className={classes.message_text}>{chart.chartMessage}</span>
                  <ChatBarChart categories={chart.categories} data={chart.values} />
                </div>
              ) : (
                <span className={classes.message_text}>{message}</span>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <span className={classes.message_text}>{message}</span>
          <div className={classes.chat_icon_wrapper}>
            <Person2Icon
              fontSize="medium"
              sx={{ color: '#00796b' }}
              className={classes.user_icon}
              aria-label="User Icon"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChatMessage;
