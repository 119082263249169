import { type Admin } from '../../types/Admin';
import {
  type ChangePasswordTokenResponse,
  type RequestChangePassword,
  type RequestChangePasswordToken,
} from '../../types/ChangePassword';
import { type DataCollector } from '../../types/DataCollector';
import { type DataLead } from '../../types/DataLead';
import { type ExchangeOktaTokenRequest, type Token } from '../../types/ExchangeToken';
import {
  type InvitationLinkValidationRequest,
  type InvitationLinkValidationResponse,
} from '../../types/InvitationLink';
import { type RequestResetPasswordResponse } from '../../types/ResetPassword';
import {
  type ChangeUserDetailsRequest,
  type InviteAdminRequest,
  type UpdateUserInfoRequest,
  type User,
} from '../../types/User';
import { rebexApi } from './baseRebexEndpoints';

export const usersExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<User, FormData>({
      query: (user: FormData) => ({ url: '/users/login', method: 'POST', body: user }),
    }),
    getUser: builder.query<User, string>({
      query: (email: string) => `/users/${email}`,
    }),
    getAllUsers: builder.query<User[], null>({
      query: () => '/users',
    }),
    getDefaultWorkspaceByUser: builder.query<User, null>({
      query: () => '/users/token/user-info',
    }),
    addUsers: builder.mutation<User, Partial<User>>({
      query: (user: User) => ({ url: '/users/admin', method: 'POST', body: user }),
    }),
    addAdmin: builder.mutation<DataLead, Partial<Admin & { tempToken: string }>>({
      query: ({ tempToken, ...dataLead }) => ({
        url: '/users/admin',
        method: 'POST',
        body: dataLead,
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }),
    }),
    addDataLead: builder.mutation<DataLead, Partial<DataLead & { tempToken: string }>>({
      query: ({ tempToken, ...dataLead }) => ({
        url: '/users/data-lead',
        method: 'POST',
        body: dataLead,
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }),
    }),
    addDataCollector: builder.mutation<DataCollector, Partial<DataCollector & { tempToken: string }>>({
      query: ({ tempToken, ...dataCollector }) => ({
        url: '/users/data-collector',
        method: 'POST',
        body: dataCollector,
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }),
    }),
    validateInvitationLink: builder.mutation<InvitationLinkValidationResponse, { identifier: string }>({
      query: (request: InvitationLinkValidationRequest) => ({
        url: '/users/validate-invitation-link',
        method: 'POST',
        body: request,
      }),
    }),
    exchangeOktaToken: builder.mutation<Token, ExchangeOktaTokenRequest>({
      query: (request: ExchangeOktaTokenRequest) => ({
        url: '/users/exchange-okta-token',
        method: 'POST',
        body: request,
      }),
    }),
    requestResetPassword: builder.mutation<RequestResetPasswordResponse, FormData>({
      query: (request: FormData) => ({
        url: '/users/request-recovery-email',
        method: 'POST',
        body: request,
      }),
    }),
    verifyChangePasswordToken: builder.mutation<ChangePasswordTokenResponse, RequestChangePasswordToken>({
      query: (request: RequestChangePasswordToken) => ({
        url: 'users/validate-reset-password-token',
        method: 'POST',
        body: request,
      }),
    }),
    changePassword: builder.mutation<{ message: string }, Partial<RequestChangePassword & { tempToken: string }>>({
      query: ({ tempToken, ...body }) => ({
        url: '/users/password',
        method: 'PUT',
        body,
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }),
    }),
    changeUserDetails: builder.mutation<User, ChangeUserDetailsRequest>({
      query: ({ userId, ...user }) => ({
        url: `/users/change-details/${userId}`,
        method: 'PUT',
        body: user,
      }),
    }),
    revokeUsersAccess: builder.mutation<null, number | undefined>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
    }),
    updateUser: builder.mutation<User, UpdateUserInfoRequest>({
      query: ({ userId, ...user }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body: user,
      }),
    }),
    inviteAdmin: builder.mutation<User, InviteAdminRequest>({
      query: (user) => ({
        url: `/users/invites/admin`,
        method: 'POST',
        body: user,
      }),
    }),
  }),
});
