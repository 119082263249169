import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Chip, Divider, IconButton, Typography, useTheme } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppButton from '../../../components/materials/actions/AppButton';
import ConfirmationModal from '../../../components/materials/containers/ConfirmationModal';
import { ToastContext } from '../../../context/toastContext';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import {
  useLazyGetThemesQuery,
  useUpdateThemeMutation,
  useUploadThemeResourcesMutation,
} from '../../../utils/redux/api';
import {
  clearEditingKeyTheme,
  clearIsEditingKeyTheme,
  setEditingKeyTheme,
  setKeyThemes,
  updateKeyThemeById,
} from '../../../utils/redux/keyThemeSlice';
import { type KeyTheme, KeyThemeItemType, KeyThemeResourceType } from '../../../utils/types/KeyTheme';
import EditableTitle from './EditableTitle';
import FileUploadContainer from './FileUploadContainer';
import KeyThemeItemCardContainer from './KeyThemeItemCardContainer';

interface Props {
  handleDeleteTheme: (themeId: number) => Promise<void>;
}

const KeyThemeEditView: React.FC<Props> = ({ handleDeleteTheme }) => {
  const { editingKeyTheme } = useAppSelector((state) => state.keyThemes);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateTheme, { isLoading: isLoadingThemeUpdate }] = useUpdateThemeMutation();
  const [uploadResource, { isLoading: isLoadingResourceUpload }] = useUploadThemeResourcesMutation();
  const [getThemes] = useLazyGetThemesQuery();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { handleOpenToast } = useContext(ToastContext);

  const { workspaceId } = useParams();

  const newResources = useRef<Array<{ id: string; resource: FormData }>>([]);

  const handleNewResource = (id: string, newResource: FormData) => {
    newResources.current = [...newResources.current, { id, resource: newResource }];
  };

  const handleKeyThemeUpload = async () => {
    if (editingKeyTheme === null) return;
    try {
      const response = await updateTheme({
        keyTheme: editingKeyTheme,
        themeId: editingKeyTheme.id,
      });

      if (response.error == null) {
        dispatch(updateKeyThemeById(response.data));
      }
    } catch {
      handleOpenToast({
        message: 'Error updating the key theme, try again later or contact support',
        severity: 'error',
      });
    }
  };

  const handleResourcesUpload = async () => {
    if (editingKeyTheme === null) return;

    const uploadPromises = [];
    for (const kpi of editingKeyTheme.kpis.filter((kpi) => !(kpi.stored ?? false))) {
      const newResource = newResources.current.find((resource) => kpi.id === resource.id);
      if (newResource == null) continue;
      uploadPromises.push(uploadResource({ themeId: editingKeyTheme.id, formData: newResource.resource }));
    }

    for (const consumerSurvey of editingKeyTheme.consumerSurveys.filter(
      (consumerSurvey) => !(consumerSurvey.stored ?? false),
    )) {
      const newResource = newResources.current.find((resource) => consumerSurvey.id === resource.id);
      if (newResource == null) continue;
      uploadPromises.push(uploadResource({ themeId: editingKeyTheme.id, formData: newResource.resource }));
    }

    const results = await Promise.allSettled(uploadPromises);
    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        handleOpenToast({
          message: `Resource ${result.value.data?.file_name} uploaded successfully:`,
          severity: 'success',
        });
      } else {
        handleOpenToast({ message: `Error uploading resource`, severity: 'error' });
      }
    });
  };

  const handleSave = async () => {
    await handleKeyThemeUpload();
    await handleResourcesUpload();

    dispatch(clearEditingKeyTheme());
    const { data: newKeyThemes, error } = await getThemes(Number(workspaceId));
    if (error == null && newKeyThemes !== undefined) dispatch(setKeyThemes(newKeyThemes));
    else {
      handleOpenToast({ message: 'Error fetching updated KeyThemes, try reloading the page', severity: 'error' });
    }
  };

  const updateKeyTheme = (updatedFields: Partial<KeyTheme>) => {
    if (editingKeyTheme != null) {
      const updatedKeyTheme = { ...editingKeyTheme, ...updatedFields };

      dispatch(setEditingKeyTheme(updatedKeyTheme));
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" m={3}>
        <Box display="flex" alignItems="center" columnGap={2}>
          <EditableTitle
            title={editingKeyTheme?.title ?? ''}
            onSave={(newTitle) => {
              updateKeyTheme({ title: newTitle });
            }}
          />
          <Chip size="small" label={editingKeyTheme?.status} variant="outlined" />
        </Box>

        <IconButton
          onClick={() => {
            setModalOpen(true);
          }}
          size="small"
          sx={{ mt: '4px', color: palette.green[500] }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box m={3} display="flex" flexDirection="column" gap={3}>
        <KeyThemeItemCardContainer items={editingKeyTheme?.insights} itemType={KeyThemeItemType.Insight} />
        <Box>
          <Typography variant="headlineMedium">Supporting Data</Typography>
        </Box>
        <FileUploadContainer onNewResource={handleNewResource} resourceType={KeyThemeResourceType.Kpi} />
        <FileUploadContainer onNewResource={handleNewResource} resourceType={KeyThemeResourceType.ConsumerSurvey} />
        <KeyThemeItemCardContainer items={editingKeyTheme?.opportunities} itemType={KeyThemeItemType.Opportunity} />
        <Box display="flex" mt={2} columnGap={2} justifyContent="flex-end">
          <AppButton
            sx={{
              color: palette.green[500],
              borderRadius: '100px',
              '&:hover, &:active, &:focus': { backgroundColor: palette.green.accent50, color: palette.gray.white },
            }}
            variant="outlined"
            onClick={() => {
              dispatch(clearIsEditingKeyTheme());
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            onClick={handleSave}
            disabled={isLoadingThemeUpdate || isLoadingResourceUpload}
            sx={{
              color: palette.green[500],
              borderRadius: '100px',
              '&:hover, &:active, &:focus': { backgroundColor: palette.green.accent50, color: palette.gray.white },
            }}
            variant="outlined"
          >
            Save Draft
          </AppButton>
          <ConfirmationModal
            isOpen={modalOpen}
            title="Do you really want to delete this theme?"
            text="This action cannot be undone."
            confirmText="Delete"
            onClose={() => {
              setModalOpen(false);
            }}
            onSave={() => {
              setModalOpen(false);
              void handleDeleteTheme(editingKeyTheme!.id);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default KeyThemeEditView;
