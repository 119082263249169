import { Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import oktaAuth from '../../components/okta/OktaAuth';
import { useExchangeOktaTokenMutation, useLazyGetUserQuery } from '../../utils/redux/api';
import { clearAuth, setAuth, setUserDetails } from '../../utils/redux/authSlice';
import { type CustomToken } from '../../utils/types/Login';
import { type User } from '../../utils/types/User';

const OktaCallbackPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const executeCheck = useRef(false);
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState('Authentication is being loaded...');

  const [getUserTrigger] = useLazyGetUserQuery();
  const [exchangeOktaTokenTrigger] = useExchangeOktaTokenMutation();

  const oktaAuthFunctions = useCallback(async () => {
    const verifier = localStorage.getItem('oktaVerifier');
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const stateFromSession = localStorage.getItem('state');

    if (verifier == null || stateFromSession == null) {
      setLoadingMessage('Authentication failed. Redirecting to login page...');
      localStorage.clear();
      navigate('/', { replace: true });
      return;
    }

    if (code !== null && state === stateFromSession) {
      try {
        const tokens = await oktaAuth.token.exchangeCodeForTokens({
          authorizationCode: code,
          codeVerifier: verifier,
          state: stateFromSession,
        });
        if (tokens.tokens.accessToken?.accessToken != null) {
          const customToken = await exchangeOktaTokenTrigger({
            okta_token: tokens.tokens.accessToken.accessToken,
          }).unwrap();

          const decode = jwtDecode<CustomToken>(customToken.access_token);
          dispatch(setAuth({ accessToken: customToken.access_token, email: decode.email }));

          getUserTrigger(decode.email.toLowerCase())
            .then((userResponse: any) => {
              if (userResponse?.status === 'fulfilled' && userResponse?.data !== null) {
                dispatch(setUserDetails(userResponse.data as User));
                navigate('/authorization-code/role-check-and-redirect');
              }
            })
            .catch(() => {
              throw new Error('Error getting user details');
            });
        } else {
          throw new Error('Access token is undefined.');
        }
      } catch (error) {
        console.error('Token exchange failed:', error);
        setLoadingMessage('Authentication failed. Redirecting to login page...');
        localStorage.clear();
        dispatch(clearAuth());
        navigate('/', { replace: true });
      }
    } else {
      setLoadingMessage('Authentication mismatch or error. Redirecting to login page...');
      localStorage.clear();
      dispatch(clearAuth());
      navigate('/', { replace: true });
    }
  }, [dispatch, getUserTrigger, location.search, navigate, exchangeOktaTokenTrigger]);

  useEffect(() => {
    if (!executeCheck.current) {
      executeCheck.current = true;
      oktaAuthFunctions().catch((error) => {
        console.error('Failed to handle auth:', error);
        setLoadingMessage('Authentication mismatch or error. Redirecting to login page...');
        dispatch(clearAuth());
        navigate('/', { replace: true });
      });
    }
  }, [dispatch, navigate, oktaAuthFunctions]);

  return (
    <Typography variant="bodyLarge" sx={{ textAlign: 'center', marginTop: '20px' }}>
      {loadingMessage}
    </Typography>
  );
};

export default OktaCallbackPage;
