import { Box, keyframes, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { clearIsEditingKeyTheme, setSelectedKeyThemeId } from '../../../utils/redux/keyThemeSlice';

const NavDots = styled(Box)({
  bottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const Dot = styled(Box)(({ theme }) => ({
  height: '10px',
  width: '10px',
  margin: '0 5px',
  backgroundColor: theme.palette.gray[300],
  borderRadius: '50%',
  display: 'inline-block',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-in-out',
  '&.active': {
    backgroundColor: theme.palette.green[500],
  },
  '&:hover': {
    backgroundColor: theme.palette.gray[500],
  },
}));

interface Props {
  children?: React.ReactNode;
  keyThemeIds: number[];
}

const KeyThemeCarousel: React.FC<Props> = ({ children, keyThemeIds }) => {
  const currentKeyThemeId = useAppSelector((state) => state.keyThemes.selectedKeyThemeId);
  const [isAnimating, setIsAnimating] = useState(false);

  // Transform reset is needed because of a bug on the Draggable component where it creates an offset
  // base of any transform props on the parent component
  const [transformReset, setTransformReset] = useState(false);
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const fadeIn = keyframes`
  from {
    transform: translateX(80vw);
  }
  to {
    transform: translateX(0);
  }
`;

  const fadeOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-80vw);
  }
`;

  useEffect(() => {
    setIsAnimating(true);
    setTransformReset(false);
    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentKeyThemeId]);

  const handleAnimationEnd = useCallback(() => {
    if (!isAnimating) {
      setTransformReset(true);
    }
  }, [isAnimating]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        sx={{
          animation: transformReset ? 'none' : `${isAnimating ? fadeOut : fadeIn} 0.3s forwards`,
          transform: transformReset ? 'none' : undefined,
        }}
        bgcolor={palette.gray[200]}
        onAnimationEnd={handleAnimationEnd}
        borderRadius="8px"
      >
        <Box width={'100%'}>{children}</Box>
      </Box>
      <NavDots>
        {keyThemeIds.map((id) => (
          <Dot
            key={id}
            className={Number(id) === currentKeyThemeId ? 'active' : ''}
            onClick={() => {
              dispatch(setSelectedKeyThemeId(Number(id)));
              dispatch(clearIsEditingKeyTheme());
            }}
          />
        ))}
      </NavDots>
    </Box>
  );
};

export default KeyThemeCarousel;
