import { Backdrop, useTheme, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import mitt from 'mitt';

import AppCircularProgress from '../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../context/toastContext';
import ModalWrapper from '../../components/ModalWrapper';
import styled from '@emotion/styled';

export const methodEmitter1 = mitt();

interface Props {
  open: boolean;
  handleClose: () => void;
  peers: Array<{ id: number; name: string; isPrimary: boolean }>;
}

const PeersSelectionModal: React.FC<Props> = ({ open, handleClose, peers }) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);

  const peersName = useMemo(() => peers.filter((peer) => !peer.isPrimary).map((peer) => peer.name), [peers]);

  const peersNameToIdMap = useMemo(() => {
    const map = new Map<string, number>();
    peers.forEach((peer) => {
      map.set(peer.name, peer.id);
    });
    return map;
  }, [peers]);

  const [selectedPeers, setSelectedPeers] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeers((prevSelected) => {
      if (event.target.checked) {
        return [...prevSelected, event.target.name];
      }
      return prevSelected.filter((name) => name !== event.target.name);
    });
  };

  const handleSubmit = () => {
    if (selectedPeers.length === 0) {
      handleOpenToast({ message: 'Please select at least one peer to discard.', severity: 'error' });
      return;
    }
    setLoading(true);

    const includePeerIds = selectedPeers.map((peer) => peersNameToIdMap.get(peer)!);

    methodEmitter1.emit('recalculateKPIsWithPeers', { includeWorkspaces: includePeerIds, dpChanges: [] });

    handleOpenToast({ message: 'KPIs recalculation initiated with selected peers.', severity: 'info' });

    setLoading(false);

    handleClose();
  };

  const handleCancel = () => {
    handleReset();
    handleClose();
  };

  const handleReset = useCallback(() => {
    setSelectedPeers([]);
  }, []);

  useEffect(() => {
    if (open) {
      setSelectedPeers(peersName);
    } else {
      handleReset();
    }
  }, [open, peersName, handleReset]);

  const GreenSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: palette.success.main,
      '&:hover': {
        backgroundColor: alpha(palette.success.main, 0.25),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: palette.success.main,
    },
  });

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title={'Select Peers'}
      summary={'Select or Unselect Peers that you wish to disregard in your outlier analysis.'}
      buttons={[
        { label: 'Discard', action: handleCancel, variant: 'outlined' },
        {
          label: 'Save and Close',
          action: handleSubmit,
          variant: 'filled',
          disabled: loading || peersName.length === 1,
        },
      ]}
    >
      <Box sx={{ maxHeight: 400, overflowY: 'auto', width: '100%' }}>
        <FormControl component="fieldset" variant="standard" sx={{ width: '100%' }}>
          <FormGroup>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: '1fr', // Single column on extra-small screens
                sm: peersName.length > 1 ? '1fr 1fr' : '1fr', // Two columns if more than one peer
              }}
              gap={2}
              alignItems="start"
              sx={{ px: 2, my: 2 }}
            >
              {peersName.map((peer, index) => (
                <FormControlLabel
                  key={peer}
                  control={
                    <GreenSwitch
                      checked={selectedPeers.includes(peer)}
                      onChange={handleChange}
                      name={peer}
                      inputProps={{ 'aria-label': `Select ${peer}` }}
                      disabled={selectedPeers.length === 1 && selectedPeers.includes(peer)}
                    />
                  }
                  label={peer}
                  sx={{
                    width: '100%', // Ensure the label takes full width
                  }}
                />
              ))}
            </Box>
          </FormGroup>
        </FormControl>
      </Box>
      {loading ? (
        <Backdrop open sx={{ zIndex: 1000 }}>
          <AppCircularProgress />
        </Backdrop>
      ) : null}
    </ModalWrapper>
  );
};

export default PeersSelectionModal;
