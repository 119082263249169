import {
  Backdrop,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { useGetAllUsersQuery } from '../../../../../utils/redux/api';
import { TableFilters } from './TableFilters';
import { UsersTableRow, type UserFiltered } from './UsersTableRow';

type Order = 'asc' | 'desc';

interface TableHeading {
  id: string;
  label: string;
}

const tableHeads: readonly TableHeading[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'E-mail Address' },
  { id: 'dateAdded', label: 'Date Added' },
  { id: 'role', label: 'User Role' },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({ order, orderBy, onRequestSort }) => {
  const { palette } = useTheme();

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeads.map((head) => (
          <TableCell
            key={head.id}
            sortDirection={orderBy === head.id ? order : false}
            sx={
              orderBy === head.id
                ? {
                    backgroundColor: palette.green[50],
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: palette.green[500],
                '&.Mui-active': {
                  color: palette.green[500],
                  backgroundColor: palette.green[50],
                },
                svg: {
                  color: `${palette.green[500]} !important`,
                },
              }}
            >
              <span>{head.label}</span>
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell width="50px" sx={{ color: palette.green[500] }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const sliptName = (name: string): { firstName?: string; lastName?: string } => {
  const splittedNames = name.trim().split(/\s+/);

  if (splittedNames.length === 0) {
    return { firstName: name };
  }

  const first = splittedNames[0];
  const last = splittedNames.length > 1 ? splittedNames[splittedNames.length - 1] : undefined;

  return { firstName: first, lastName: last };
};

export const UsersTable: React.FC = () => {
  const { palette } = useTheme();
  const { data: rawUsers, refetch, isFetching } = useGetAllUsersQuery(null);

  const users: UserFiltered[] = useMemo(() => {
    if (rawUsers !== undefined) {
      return rawUsers.map((user) => {
        const { firstName, lastName } = sliptName(user?.name !== undefined ? user.name : '');
        const role = user.roles !== undefined && user.roles.length > 0 ? user.roles[0] : '';
        return {
          id: user.id,
          firstName: (firstName ?? '').charAt(0).toUpperCase() + (firstName ?? '').slice(1).toLowerCase(),
          lastName: (lastName ?? '').charAt(0).toUpperCase() + (lastName ?? '').slice(1).toLowerCase(),
          dateAdded: user.created_date,
          email: user.email,
          role,
        };
      });
    } else {
      return [];
    }
  }, [rawUsers]);

  // Sorting
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('firstName');
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [displayUsers, setDisplayUsers] = useState<UserFiltered[] | undefined>(users);

  if (isFetching)
    return (
      <Backdrop open>
        <AppCircularProgress />
      </Backdrop>
    );

  return (
    <Box
      sx={{
        border: '1px',
        borderRadius: '8px',
        padding: '24px 32px',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: palette.gray.white,
        borderColor: palette.gray[300],
      }}
      border={1}
    >
      <TableFilters users={users} setDisplayUsers={setDisplayUsers} order={order} orderBy={orderBy} />
      <TableContainer>
        <Table>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          {displayUsers !== undefined ? (
            displayUsers.map((user, i) => (
              <UsersTableRow key={i} orderBy={orderBy} user={user} usersRefetch={refetch as any} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align="center">
                No records found
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
