import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { formatNameForPath } from '../../../../../utils/stringUtils';
import ResourcesWrapper from '../components/ResourcesWrapper';
import { glossaryContent } from './glossaryContent';

interface TabPanelProps {
  children?: React.ReactNode;
  selected: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, selected, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== selected}
      id={`glossary-tabpanel-${selected}`}
      aria-labelledby={`glossary-tab-${selected}`}
      {...other}
    >
      {value === selected && (
        <Box display="flex" flexDirection="column" gap="24px" sx={{ padding: '0 32px 40px 24px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `glossary-tab-${index}`,
    'aria-controls': `glossary-tabpanel-${index}`,
  };
}

const RedesignedGlossary: React.FC = () => {
  const glossaryData = glossaryContent;
  const { submodule } = useParams();
  const { palette } = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(submodule ?? formatNameForPath(glossaryData[0].title));
  }, [glossaryData, submodule]);

  // styling striped table rows
  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  // tab control
  const [selectedTab, setSelectedTab] = React.useState(submodule ?? formatNameForPath(glossaryData[0].title));

  return (
    <ResourcesWrapper
      title="Glossary"
      subtitle="The glossary contains definitions for the KPIs contained within REBEX."
      defaultPadding={false}
    >
      <Tabs
        value={selectedTab}
        onChange={(_, value) => {
          navigate(`/app/resources/glossary/${value}`);
        }}
        variant="standard"
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: palette.green[500] },
        }}
      >
        {glossaryData.map((glossarySection, idx) => (
          <Tab
            key={idx}
            label={
              <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                {glossarySection.title}
              </Typography>
            }
            value={formatNameForPath(glossarySection.title)}
            disableRipple
            sx={{
              textTransform: 'none',
              padding: '24px 32px',
              cursor: 'default',
              color: palette.gray[500],
              '&:first-of-type': { borderTopLeftRadius: '8px' },
              '&:last-of-type': { borderTopRightRadius: '8px' },
              '&:hover': {
                backgroundColor: palette.green[100],
              },
              '&.Mui-selected': {
                color: palette.green[500],
                backgroundColor: palette.green[100],
              },
            }}
            {...a11yProps(idx)}
          />
        ))}
      </Tabs>

      {glossaryData.map((glossarySection) => (
        <CustomTabPanel
          key={glossarySection.title}
          value={formatNameForPath(glossarySection.title)}
          selected={selectedTab}
        >
          {glossarySection.tables?.map((glossaryTable, idx) => (
            <Accordion
              key={idx}
              disableGutters={true}
              defaultExpanded={glossarySection.tables.length === 1}
              sx={{
                boxShadow: 'none',
                '&:not(:first-of-type)': { paddingTop: '24px' },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="titleLarge">{glossaryTable.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {glossaryTable.items.map((item, idx) =>
                        item.subitem === true ? (
                          <TableRow
                            key={idx}
                            sx={{
                              verticalAlign:
                                item.description !== null && item.description !== undefined ? 'bottom' : '',
                            }}
                          >
                            <TableCell
                              colSpan={item.description !== null && item.description !== undefined ? 1 : 2}
                              sx={{ paddingTop: idx > 0 ? '40px' : '' }}
                            >
                              <Typography variant="bodyMediumBold">{item.name}</Typography>
                            </TableCell>
                            {item.description !== null && item.description !== undefined ? (
                              <TableCell>{item.description}</TableCell>
                            ) : null}
                          </TableRow>
                        ) : (
                          <StyledTableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ fontWeight: '500' }}>{item.name}</TableCell>
                            <TableCell>
                              <span dangerouslySetInnerHTML={{ __html: item.description! }}></span>
                            </TableCell>
                          </StyledTableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </CustomTabPanel>
      ))}
    </ResourcesWrapper>
  );
};

export default RedesignedGlossary;
