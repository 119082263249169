import { Tab, Tabs, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link, useResolvedPath } from 'react-router-dom';

import useRouteMatch from '../../../../../../../../utils/hooks/useRouteMatch';

const ModulesNavigationBar: React.FC = () => {
  const { palette } = useTheme();

  const baseUrl = useResolvedPath('').pathname;

  const modules = [
    {
      module_id: 1,
      module_name: 'Revenues',
      module_path: `${baseUrl}/revenues/*`,
      module_initial_path: `${baseUrl}/revenues`,
    },
    {
      module_id: 2,
      module_name: 'Costs',
      module_path: `${baseUrl}/costs/*`,
      module_initial_path: `${baseUrl}/costs`,
    },
    {
      module_id: 3,
      module_name: 'Customers',
      module_path: `${baseUrl}/customers/*`,
      module_initial_path: `${baseUrl}/customers`,
    },
    {
      module_id: 4,
      module_name: 'Products',
      module_path: `${baseUrl}/products/*`,
      module_initial_path: `${baseUrl}/products`,
    },
    {
      module_id: 5,
      module_name: 'Digital Channels',
      module_path: `${baseUrl}/digital-channels/*`,
      module_initial_path: `${baseUrl}/digital-channels`,
    },
    {
      module_id: 6,
      module_name: 'Physical Channels',
      module_path: `${baseUrl}/physical-channels/*`,
      module_initial_path: `${baseUrl}/physical-channels`,
    },
    {
      module_id: 7,
      module_name: 'Call Center Channels',
      module_path: `${baseUrl}/call-center-channels/*`,
      module_initial_path: `${baseUrl}/call-center-channels`,
    },
  ];

  const routeMatch = useRouteMatch(modules.map((module) => module.module_path));
  const currentModule = routeMatch?.pattern.path;

  return (
    <Tabs
      value={currentModule ?? false}
      variant="fullWidth"
      sx={{
        padding: '0',
        borderRadius: '8px 8px 0 0',
        borderBottom: '1px solid',
        backgroundColor: palette.gray.white,
        borderBottomColor: palette.gray[300],
        '& .MuiTabs-indicator': { backgroundColor: palette.green[500] },
      }}
    >
      {modules.map((module) => (
        <Tab
          key={module.module_id}
          label={
            <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
              {module.module_name}
            </Typography>
          }
          value={module.module_path}
          to={module.module_initial_path}
          component={Link}
          disableRipple
          sx={{
            textTransform: 'none',
            paddingY: '20px',
            cursor: 'default',
            '&:hover': {
              backgroundColor: palette.green[100],
            },
            color: palette.gray[700],
            letterSpacing: '0.5px',
            '&.Mui-selected': {
              color: palette.green[500],
              backgroundColor: palette.green[100],
            },
          }}
        ></Tab>
      ))}
    </Tabs>
  );
};

export default ModulesNavigationBar;
