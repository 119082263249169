import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { generateTableData } from '../../pages/DataTables/utils/generateTableData';
import { initialValues, initialValuesFromResponse } from '../../pages/DataTables/utils/initialValues';
import { type CommentThread } from '../types/CommentThread';
import { type DataTable } from '../types/DataTable';
import { type DataTableValues, ProductAndSegmentTables, type TabKeys } from '../types/DataTablesEnum';
import { type Issue } from '../types/Issue';

export interface DataTableWithValues extends DataTable {
  values: Record<string, number | null>;
  tabKey: TabKeys;
  columns: string[];
  rows: string[];
  commentThreads: CommentThread[];
  issues: Issue[];
  commentCount: number;
}

export interface SelectedCell {
  row: string;
  column: string;
  cellId: string;
}

interface DataTableState {
  isCommentPanelOpen: boolean;
  unsavedChanges: boolean;
  activeTabKey: TabKeys;
  dataTables: DataTableWithValues[];
  activeDataTable: {
    dataTableId: number | null;
    activeCell: SelectedCell | null;
  };
}

interface AddDataTablePayload {
  dataTable: Omit<DataTableWithValues, 'values' | 'commentThreads' | 'issues'>;
  columns: string[];
  rowCount: number;
  totalColumns?: string[];
}

interface UpdateDataTablePayload {
  dataTable: Pick<DataTableWithValues, 'tabKey' | 'table' | 'values'>;
  columns: string[];
  rowCount: number;
}

interface UpdateDataTableCommentThreadsPayload {
  commentThreads: CommentThread[];
  dataTableId: number;
}

interface UpdateDataTableIssuesPayload {
  issues: Issue[];
  dataTableId: number;
}

interface UpdateCommentThreadPayload {
  dataTableId?: number;
  commentThread: CommentThread;
}

interface SetActiveCellPayload {
  cellId: string;
  row: string;
  column: string;
}

const initialState: DataTableState = {
  isCommentPanelOpen: false,
  activeTabKey: ProductAndSegmentTables.AverageBalanceByProduct,
  unsavedChanges: false,
  dataTables: [],
  activeDataTable: {
    dataTableId: null,
    activeCell: null,
  },
};

const dataTablesSlice = createSlice({
  name: 'dataTables',
  initialState,
  reducers: {
    addDataTable: (state, action: PayloadAction<AddDataTablePayload>) => {
      const { dataTable, columns, rowCount, totalColumns } = action.payload;
      const currentDataTableIndex = state.dataTables.findIndex(
        (table) => table.tabKey === dataTable.tabKey && table.table === dataTable.table,
      );
      const values =
        dataTable.data.length > 0
          ? initialValuesFromResponse(dataTable.data, columns, totalColumns)
          : initialValues(columns, rowCount, totalColumns);
      const data = dataTable.data.length > 0 ? dataTable.data : generateTableData(columns, rowCount, values);

      if (currentDataTableIndex === -1) {
        state.dataTables.push({ ...dataTable, values, data, commentThreads: [], issues: [] });
      } else {
        state.dataTables[currentDataTableIndex] = { ...dataTable, values, data, commentThreads: [], issues: [] };
      }
    },
    updateDataTable: (state, action: PayloadAction<UpdateDataTablePayload>) => {
      const { dataTable, columns, rowCount } = action.payload;
      const index = state.dataTables.findIndex(
        (table) => table.tabKey === dataTable.tabKey && table.table === dataTable.table,
      );

      if (index !== -1) {
        const data = generateTableData(columns, rowCount, dataTable.values);
        state.dataTables[index].values = dataTable.values;
        state.dataTables[index].data = data;
        state.unsavedChanges = true;
      }
    },
    clearDataTables: (state) => {
      state.dataTables = [];
      state.unsavedChanges = false;
    },
    setActiveDataTableId: (state, action: PayloadAction<number>) => {
      state.activeDataTable.dataTableId = action.payload;
    },
    setActiveCell: (state, action: PayloadAction<SetActiveCellPayload | null>) => {
      state.activeDataTable.activeCell = action.payload;
    },
    updateDataTableCommentThreads: (state, action: PayloadAction<UpdateDataTableCommentThreadsPayload>) => {
      const { commentThreads, dataTableId } = action.payload;
      const index = state.dataTables.findIndex((dataTable) => dataTable.id === dataTableId);

      if (index !== -1) {
        state.dataTables[index].commentThreads = commentThreads;
      }
    },
    updateCommentThread: (state, action: PayloadAction<UpdateCommentThreadPayload>) => {
      const { commentThread, dataTableId } = action.payload;
      if (dataTableId === undefined) return;
      const dataTable = state.dataTables.find((dataTable) => dataTable.id === dataTableId);

      if (dataTable === undefined) return;
      const commentThreadIndex = dataTable.commentThreads.findIndex(
        (ct) => ct.comment_thread_id === commentThread.comment_thread_id,
      );
      if (commentThreadIndex === -1) return;

      dataTable.commentThreads[commentThreadIndex] = {
        ...commentThread,
        cell: dataTable.commentThreads[commentThreadIndex].cell,
      };
    },
    updateDataTableIssues: (state, action: PayloadAction<UpdateDataTableIssuesPayload>) => {
      const { issues, dataTableId } = action.payload;
      const index = state.dataTables.findIndex((dataTable) => dataTable.id === dataTableId);

      if (index !== -1) {
        state.dataTables[index].issues = issues;
      }
    },
    setActiveTabKey: (state, action: PayloadAction<TabKeys>) => {
      state.activeTabKey = action.payload;
      state.unsavedChanges = false;
    },
    resetUnsavedChanges: (state) => {
      state.unsavedChanges = true;
    },
    setOpenComments: (state, action: PayloadAction<boolean>) => {
      state.isCommentPanelOpen = action.payload;
    },
    clearActiveDataTableId: (state) => {
      state.activeDataTable.dataTableId = null;
    },
  },
  selectors: {
    selectDataTablesByTabKey: createSelector(
      (state: DataTableState) => state.dataTables,
      (state: DataTableState) => state.activeTabKey,
      (dataTables, activeTabKey) => dataTables.filter((dataTable) => dataTable.tabKey === activeTabKey),
    ),
    selectDataTableByTabKeyAndTable: (state, tabKey: TabKeys, table: DataTableValues) =>
      state.dataTables.find((dataTable) => dataTable.tabKey === tabKey && dataTable.table === table),
    selectDataTableById: (state, id: number) => state.dataTables.find((dataTable) => dataTable.id === id),
    selectActiveDataTable: (state) => {
      const { dataTableId } = state.activeDataTable;
      if (dataTableId === null) return null;
      return state.dataTables.find((dataTable) => dataTable.id === dataTableId);
    },
  },
});

export const {
  addDataTable,
  clearDataTables,
  updateDataTable,
  updateDataTableCommentThreads,
  updateCommentThread,
  setActiveCell,
  setActiveDataTableId,
  updateDataTableIssues,
  setActiveTabKey,
  resetUnsavedChanges,
  setOpenComments,
  clearActiveDataTableId,
} = dataTablesSlice.actions;
export const {
  selectDataTablesByTabKey,
  selectDataTableByTabKeyAndTable,
  selectDataTableById,
  selectActiveDataTable,
} = dataTablesSlice.selectors;
export default dataTablesSlice.reducer;
