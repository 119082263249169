export interface Currency {
  label: string;
  alphaCode: string;
  code: number;
}

const currencies: Currency[] = [
  { label: 'UAE Dirham', alphaCode: 'AED', code: 784 },
  { label: 'Afghani', alphaCode: 'AFN', code: 971 },
  { label: 'Lek', alphaCode: 'ALL', code: 8 },
  { label: 'Armenian Dram', alphaCode: 'AMD', code: 51 },
  { label: 'Netherlands Antillean Guilder', alphaCode: 'ANG', code: 532 },
  { label: 'Kwanza', alphaCode: 'AOA', code: 973 },
  { label: 'Argentine Peso', alphaCode: 'ARS', code: 32 },
  { label: 'Australian Dollar', alphaCode: 'AUD', code: 36 },
  { label: 'Aruban Florin', alphaCode: 'AWG', code: 533 },
  { label: 'Azerbaijan Manat', alphaCode: 'AZN', code: 944 },
  { label: 'Convertible Mark', alphaCode: 'BAM', code: 977 },
  { label: 'Barbados Dollar', alphaCode: 'BBD', code: 52 },
  { label: 'Taka', alphaCode: 'BDT', code: 50 },
  { label: 'Bulgarian Lev', alphaCode: 'BGN', code: 975 },
  { label: 'Bahraini Dinar', alphaCode: 'BHD', code: 48 },
  { label: 'Burundi Franc', alphaCode: 'BIF', code: 108 },
  { label: 'Bermudian Dollar', alphaCode: 'BMD', code: 60 },
  { label: 'Brunei Dollar', alphaCode: 'BND', code: 96 },
  { label: 'Boliviano', alphaCode: 'BOB', code: 68 },
  { label: 'Mvdol', alphaCode: 'BOV', code: 984 },
  { label: 'Brazilian Real', alphaCode: 'BRL', code: 986 },
  { label: 'Bahamian Dollar', alphaCode: 'BSD', code: 44 },
  { label: 'Ngultrum', alphaCode: 'BTN', code: 64 },
  { label: 'Pula', alphaCode: 'BWP', code: 72 },
  { label: 'Belarusian Ruble', alphaCode: 'BYN', code: 933 },
  { label: 'Belize Dollar', alphaCode: 'BZD', code: 84 },
  { label: 'Canadian Dollar', alphaCode: 'CAD', code: 124 },
  { label: 'Congolese Franc', alphaCode: 'CDF', code: 976 },
  { label: 'WIR Euro', alphaCode: 'CHE', code: 947 },
  { label: 'Swiss Franc', alphaCode: 'CHF', code: 756 },
  { label: 'WIR Franc', alphaCode: 'CHW', code: 948 },
  { label: 'Unidad de Fomento', alphaCode: 'CLF', code: 990 },
  { label: 'Chilean Peso', alphaCode: 'CLP', code: 152 },
  { label: 'Yuan Renminbi', alphaCode: 'CNY', code: 156 },
  { label: 'Colombian Peso', alphaCode: 'COP', code: 170 },
  { label: 'Unidad de Valor Real', alphaCode: 'COU', code: 970 },
  { label: 'Costa Rican Colon', alphaCode: 'CRC', code: 188 },
  { label: 'Peso Convertible', alphaCode: 'CUC', code: 931 },
  { label: 'Cuban Peso', alphaCode: 'CUP', code: 192 },
  { label: 'Cabo Verde Escudo', alphaCode: 'CVE', code: 132 },
  { label: 'Czech Koruna', alphaCode: 'CZK', code: 203 },
  { label: 'Djibouti Franc', alphaCode: 'DJF', code: 262 },
  { label: 'Danish Krone', alphaCode: 'DKK', code: 208 },
  { label: 'Dominican Peso', alphaCode: 'DOP', code: 214 },
  { label: 'Algerian Dinar', alphaCode: 'DZD', code: 12 },
  { label: 'Egyptian Pound', alphaCode: 'EGP', code: 818 },
  { label: 'Nakfa', alphaCode: 'ERN', code: 232 },
  { label: 'Ethiopian Birr', alphaCode: 'ETB', code: 230 },
  { label: 'Euro', alphaCode: 'EUR', code: 978 },
  { label: 'Fiji Dollar', alphaCode: 'FJD', code: 242 },
  { label: 'Falkland Islands Pound', alphaCode: 'FKP', code: 238 },
  { label: 'Pound Sterling', alphaCode: 'GBP', code: 826 },
  { label: 'Lari', alphaCode: 'GEL', code: 981 },
  { label: 'Ghana Cedi', alphaCode: 'GHS', code: 936 },
  { label: 'Gibraltar Pound', alphaCode: 'GIP', code: 292 },
  { label: 'Dalasi', alphaCode: 'GMD', code: 270 },
  { label: 'Guinean Franc', alphaCode: 'GNF', code: 324 },
  { label: 'Quetzal', alphaCode: 'GTQ', code: 320 },
  { label: 'Guyana Dollar', alphaCode: 'GYD', code: 328 },
  { label: 'Hong Kong Dollar', alphaCode: 'HKD', code: 344 },
  { label: 'Lempira', alphaCode: 'HNL', code: 340 },
  { label: 'Gourde', alphaCode: 'HTG', code: 332 },
  { label: 'Forint', alphaCode: 'HUF', code: 348 },
  { label: 'Rupiah', alphaCode: 'IDR', code: 360 },
  { label: 'New Israeli Sheqel', alphaCode: 'ILS', code: 376 },
  { label: 'Indian Rupee', alphaCode: 'INR', code: 356 },
  { label: 'Iraqi Dinar', alphaCode: 'IQD', code: 368 },
  { label: 'Iranian Rial', alphaCode: 'IRR', code: 364 },
  { label: 'Iceland Krona', alphaCode: 'ISK', code: 352 },
  { label: 'Jamaican Dollar', alphaCode: 'JMD', code: 388 },
  { label: 'Jordanian Dinar', alphaCode: 'JOD', code: 400 },
  { label: 'Yen', alphaCode: 'JPY', code: 392 },
  { label: 'Kenyan Shilling', alphaCode: 'KES', code: 404 },
  { label: 'Som', alphaCode: 'KGS', code: 417 },
  { label: 'Riel', alphaCode: 'KHR', code: 116 },
  { label: 'Comorian Franc ', alphaCode: 'KMF', code: 174 },
  { label: 'North Korean Won', alphaCode: 'KPW', code: 408 },
  { label: 'Won', alphaCode: 'KRW', code: 410 },
  { label: 'Kuwaiti Dinar', alphaCode: 'KWD', code: 414 },
  { label: 'Cayman Islands Dollar', alphaCode: 'KYD', code: 136 },
  { label: 'Tenge', alphaCode: 'KZT', code: 398 },
  { label: 'Lao Kip', alphaCode: 'LAK', code: 418 },
  { label: 'Lebanese Pound', alphaCode: 'LBP', code: 422 },
  { label: 'Sri Lanka Rupee', alphaCode: 'LKR', code: 144 },
  { label: 'Liberian Dollar', alphaCode: 'LRD', code: 430 },
  { label: 'Loti', alphaCode: 'LSL', code: 426 },
  { label: 'Libyan Dinar', alphaCode: 'LYD', code: 434 },
  { label: 'Moroccan Dirham', alphaCode: 'MAD', code: 504 },
  { label: 'Moldovan Leu', alphaCode: 'MDL', code: 498 },
  { label: 'Malagasy Ariary', alphaCode: 'MGA', code: 969 },
  { label: 'Denar', alphaCode: 'MKD', code: 807 },
  { label: 'Kyat', alphaCode: 'MMK', code: 104 },
  { label: 'Tugrik', alphaCode: 'MNT', code: 496 },
  { label: 'Pataca', alphaCode: 'MOP', code: 446 },
  { label: 'Ouguiya', alphaCode: 'MRU', code: 929 },
  { label: 'Mauritius Rupee', alphaCode: 'MUR', code: 480 },
  { label: 'Rufiyaa', alphaCode: 'MVR', code: 462 },
  { label: 'Malawi Kwacha', alphaCode: 'MWK', code: 454 },
  { label: 'Mexican Peso', alphaCode: 'MXN', code: 484 },
  { label: 'Mexican Unidad de Inversion (UDI)', alphaCode: 'MXV', code: 979 },
  { label: 'Malaysian Ringgit', alphaCode: 'MYR', code: 458 },
  { label: 'Mozambique Metical', alphaCode: 'MZN', code: 943 },
  { label: 'Namibia Dollar', alphaCode: 'NAD', code: 516 },
  { label: 'Naira', alphaCode: 'NGN', code: 566 },
  { label: 'Cordoba Oro', alphaCode: 'NIO', code: 558 },
  { label: 'Norwegian Krone', alphaCode: 'NOK', code: 578 },
  { label: 'Nepalese Rupee', alphaCode: 'NPR', code: 524 },
  { label: 'New Zealand Dollar', alphaCode: 'NZD', code: 554 },
  { label: 'Rial Omani', alphaCode: 'OMR', code: 512 },
  { label: 'Balboa', alphaCode: 'PAB', code: 590 },
  { label: 'Sol', alphaCode: 'PEN', code: 604 },
  { label: 'Kina', alphaCode: 'PGK', code: 598 },
  { label: 'Philippine Peso', alphaCode: 'PHP', code: 608 },
  { label: 'Pakistan Rupee', alphaCode: 'PKR', code: 586 },
  { label: 'Zloty', alphaCode: 'PLN', code: 985 },
  { label: 'Guarani', alphaCode: 'PYG', code: 600 },
  { label: 'Qatari Rial', alphaCode: 'QAR', code: 634 },
  { label: 'Romanian Leu', alphaCode: 'RON', code: 946 },
  { label: 'Serbian Dinar', alphaCode: 'RSD', code: 941 },
  { label: 'Russian Ruble', alphaCode: 'RUB', code: 643 },
  { label: 'Rwanda Franc', alphaCode: 'RWF', code: 646 },
  { label: 'Saudi Riyal', alphaCode: 'SAR', code: 682 },
  { label: 'Solomon Islands Dollar', alphaCode: 'SBD', code: 90 },
  { label: 'Seychelles Rupee', alphaCode: 'SCR', code: 690 },
  { label: 'Sudanese Pound', alphaCode: 'SDG', code: 938 },
  { label: 'Swedish Krona', alphaCode: 'SEK', code: 752 },
  { label: 'Singapore Dollar', alphaCode: 'SGD', code: 702 },
  { label: 'Saint Helena Pound', alphaCode: 'SHP', code: 654 },
  { label: 'Leone', alphaCode: 'SLE', code: 925 },
  { label: 'Somali Shilling', alphaCode: 'SOS', code: 706 },
  { label: 'Surinam Dollar', alphaCode: 'SRD', code: 968 },
  { label: 'South Sudanese Pound', alphaCode: 'SSP', code: 728 },
  { label: 'Dobra', alphaCode: 'STN', code: 930 },
  { label: 'El Salvador Colon', alphaCode: 'SVC', code: 222 },
  { label: 'Syrian Pound', alphaCode: 'SYP', code: 760 },
  { label: 'Lilangeni', alphaCode: 'SZL', code: 748 },
  { label: 'Baht', alphaCode: 'THB', code: 764 },
  { label: 'Somoni', alphaCode: 'TJS', code: 972 },
  { label: 'Turkmenistan New Manat', alphaCode: 'TMT', code: 934 },
  { label: 'Tunisian Dinar', alphaCode: 'TND', code: 788 },
  { label: 'Pa’anga', alphaCode: 'TOP', code: 776 },
  { label: 'Turkish Lira', alphaCode: 'TRY', code: 949 },
  { label: 'Trinidad and Tobago Dollar', alphaCode: 'TTD', code: 780 },
  { label: 'New Taiwan Dollar', alphaCode: 'TWD', code: 901 },
  { label: 'Tanzanian Shilling', alphaCode: 'TZS', code: 834 },
  { label: 'Hryvnia', alphaCode: 'UAH', code: 980 },
  { label: 'Uganda Shilling', alphaCode: 'UGX', code: 800 },
  { label: 'US Dollar', alphaCode: 'USD', code: 840 },
  { label: 'US Dollar (Next day)', alphaCode: 'USN', code: 997 },
  { label: 'Uruguay Peso en Unidades Indexadas (UI)', alphaCode: 'UYI', code: 940 },
  { label: 'Peso Uruguayo', alphaCode: 'UYU', code: 858 },
  { label: 'Unidad Previsional', alphaCode: 'UYW', code: 927 },
  { label: 'Uzbekistan Sum', alphaCode: 'UZS', code: 860 },
  { label: 'Bolívar Soberano', alphaCode: 'VED', code: 926 },
  { label: 'Bolívar Soberano', alphaCode: 'VES', code: 928 },
  { label: 'Dong', alphaCode: 'VND', code: 704 },
  { label: 'Vatu', alphaCode: 'VUV', code: 548 },
  { label: 'Tala', alphaCode: 'WST', code: 882 },
  { label: 'CFA Franc BEAC', alphaCode: 'XAF', code: 950 },
  { label: 'Silver', alphaCode: 'XAG', code: 961 },
  { label: 'Gold', alphaCode: 'XAU', code: 959 },
  { label: 'Bond Markets Unit European Composite Unit (EURCO)', alphaCode: 'XBA', code: 955 },
  { label: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)', alphaCode: 'XBB', code: 956 },
  { label: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)', alphaCode: 'XBC', code: 957 },
  { label: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)', alphaCode: 'XBD', code: 958 },
  { label: 'East Caribbean Dollar', alphaCode: 'XCD', code: 951 },
  { label: 'SDR (Special Drawing Right)', alphaCode: 'XDR', code: 960 },
  { label: 'CFA Franc BCEAO', alphaCode: 'XOF', code: 952 },
  { label: 'Palladium', alphaCode: 'XPD', code: 964 },
  { label: 'CFP Franc', alphaCode: 'XPF', code: 953 },
  { label: 'Platinum', alphaCode: 'XPT', code: 962 },
  { label: 'Sucre', alphaCode: 'XSU', code: 994 },
  { label: 'Codes specifically reserved for testing purposes', alphaCode: 'XTS', code: 963 },
  { label: 'ADB Unit of Account', alphaCode: 'XUA', code: 965 },
  { label: 'The codes assigned for transactions where no currency is involved', alphaCode: 'XXX', code: 999 },
  { label: 'Yemeni Rial', alphaCode: 'YER', code: 886 },
  { label: 'Rand', alphaCode: 'ZAR', code: 710 },
  { label: 'Zambian Kwacha', alphaCode: 'ZMW', code: 967 },
  { label: 'Zimbabwe Gold', alphaCode: 'ZWG', code: 924 },
];

export default currencies;
