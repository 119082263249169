import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';
import { ToastContext } from '../../../../../../../../context/toastContext';
import { useUploadFullReportFileMutation } from '../../../../../../../../utils/redux/api';
import FileUploadButton from '../../../../../../../../components/materials/actions/FileUploadButton';

interface UploadFullReportButtonProps {
  onUploadFinished: () => void;
}

const UploadFullReportButton: React.FC<UploadFullReportButtonProps> = ({ onUploadFinished }) => {
  const { handleOpenToast } = useContext(ToastContext);
  const [uploadFullReportFileMutation, { isLoading }] = useUploadFullReportFileMutation();
  const { workspaceId } = useParams<{ workspaceId: string }>();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file == null) {
      handleOpenToast({
        severity: 'error',
        message: 'No file selected',
      });
      return;
    }

    try {
      if (workspaceId !== undefined) {
        await uploadFullReportFileMutation({ workspaceId: Number(workspaceId), file }).unwrap();

        handleOpenToast({
          severity: 'success',
          message: 'Full report uploaded successfully',
        });
      }
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'File upload error',
      });
      console.error('File upload error:', error);
    } finally {
      onUploadFinished();
    }
  };

  return (
    <FileUploadButton
      onFileChange={handleFileChange}
      isLoading={isLoading}
      buttonText="Upload Full Report"
      acceptedFileTypes="application/pdf"
    />
  );
};

export default UploadFullReportButton;
