import { Box, CircularProgress, type CircularProgressProps, type Theme } from '@mui/material';
import React from 'react';

interface Props extends CircularProgressProps {}

const AppCircularProgress: React.FC<Props> = ({ sx, ...props }) => {
  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress {...props} sx={{ ...sx, color: (theme: Theme) => theme.palette.green[700] }} />
    </Box>
  );
};

export default AppCircularProgress;
