import React, { useCallback, useState, useEffect, useContext } from 'react';
import AppLayout from '../../components/materials/containers/AppLayout';
import AccountHeader from '../../components/materials/navigation/AccountHeader';
import FormBox from './components/FormBox';
import { Box, Container, Divider, Typography, useTheme, CircularProgress } from '@mui/material';
import AppButton from '../../components/materials/actions/AppButton';
import { debounce } from './requestResetPassword';
import { ToastContext } from '../../context/toastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVerifyChangePasswordTokenMutation, useChangePasswordMutation } from '../../utils/redux/api';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { type InputFieldProps, IconType, InputField } from './components/InputField';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const passwordRequirements = [
  {
    text: 'Password must have at least 12 characters long',
    isValid: (password: string) => password.length >= 12,
  },
  {
    text: 'Password must have at least one uppercase letter',
    isValid: (password: string) => /[A-Z]/.test(password),
  },
  {
    text: 'Password must have at least one number',
    isValid: (password: string) => /\d/.test(password),
  },
  {
    text: 'Password must have at least one symbol',
    isValid: (password: string) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  },
];

export const ResetPassword: React.FC = () => {
  const query = useQuery();
  const urlToken = query.get('token');
  const navigate = useNavigate();
  const { handleOpenToast } = useContext(ToastContext);

  const inputRefPassword = React.useRef<HTMLInputElement>(null);
  const inputRefRepeatPassword = React.useRef<HTMLInputElement>(null);

  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [token, setToken] = useState<string | null>(null);
  const { palette } = useTheme();

  const [verifyTokenRequest] = useVerifyChangePasswordTokenMutation();
  const [changePasswordRequest, changePasswordDetails] = useChangePasswordMutation();

  const checkPassword = useCallback(
    (password: string) => {
      if (password !== '' && password !== null)
        passwordRequirements.map((requirement) => {
          if (!requirement.isValid(password)) setPasswordError(requirement.text);
          return requirement;
        });
    },
    [setPasswordError],
  );

  const handlePasswordChange = useCallback(() => {
    if (inputRefPassword.current?.value === '' || inputRefRepeatPassword.current?.value === '') {
      setNewPassword('');
      return;
    }
    if (inputRefPassword.current?.value !== inputRefRepeatPassword.current?.value) {
      setPasswordError('Passwords do not match');
      return;
    }
    setPasswordError('');
    setNewPassword(inputRefPassword.current?.value ?? '');
  }, [inputRefPassword, inputRefRepeatPassword, setPasswordError]);

  const debouncedHandlePasswordChange = useCallback(() => debounce(handlePasswordChange, 500), [handlePasswordChange]);

  const verifyURLToken = useCallback(async (): Promise<string | null> => {
    if (urlToken === null) {
      return null;
    }
    const reqBody = { password_reset_token: urlToken };
    const response = await verifyTokenRequest(reqBody).unwrap();
    return response.temp_token.access_token;
  }, [urlToken, verifyTokenRequest]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (token !== null && newPassword !== '') {
      await changePasswordRequest({ tempToken: token, new_password: newPassword }).unwrap();
      handleOpenToast({
        severity: 'success',
        message: 'Password changed with success!',
      });
      navigate('/');
    }
  };

  const inputFieldDetails: InputFieldProps[] = [
    {
      inputRef: inputRefPassword,
      iconType: IconType.SHOW_PASSWORD,
      id: 'password',
      label: 'New password',
      onChange: debouncedHandlePasswordChange(),
      error: passwordError !== '',
      helperText: passwordError,
      type: 'password',
    },
    {
      inputRef: inputRefRepeatPassword,
      iconType: IconType.SHOW_PASSWORD,
      id: 'repeated-password',
      label: 'Repeat password',
      onChange: debouncedHandlePasswordChange(),
      type: 'password',
    },
  ];

  useEffect(() => {
    verifyURLToken()
      .then((result) => {
        if (result === null) {
          handleOpenToast({
            severity: 'error',
            message: 'Invalid link, request a new one',
          });
          navigate('/');
        }
        setToken(result);
      })
      .catch(() => {
        handleOpenToast({
          severity: 'error',
          message: 'Invalid link, request a new one',
        });
        setToken(null);
        navigate('/');
      });
  }, [setToken, urlToken, verifyTokenRequest, handleOpenToast, navigate, verifyURLToken]);

  useEffect(() => {
    checkPassword(newPassword);
  }, [newPassword, checkPassword]);

  return (
    <AppLayout>
      <AccountHeader variant="page" />
      <Container component="main" maxWidth="sm" sx={token === null ? { width: 'max-content' } : {}}>
        {token === null ? (
          <CircularProgress
            sx={{
              marginTop: 16,
            }}
          />
        ) : (
          <FormBox>
            <Typography variant="displaySmall" sx={{ color: palette.green[700] }}>
              Change your password
            </Typography>
            <Divider sx={{ width: '100%' }} />
            <Typography variant="bodyLarge" sx={{ color: palette.gray.black }}>
              Please set a new password for your account.
            </Typography>
            <Box component={'form'} noValidate autoComplete="off" sx={{ width: '100%' }}>
              {inputFieldDetails.map((details, i) => (
                <InputField key={i} {...details} />
              ))}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 0 }}>
                <AppButton
                  disabled={
                    passwordError !== '' || newPassword === '' || changePasswordDetails.status === QueryStatus.pending
                  }
                  variant="contained"
                  sx={{
                    mt: 4,
                    backgroundColor: palette.green[500],
                    borderRadius: '100px',
                    '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
                  }}
                  onClick={(event) => {
                    void handleSubmit(event);
                  }}
                >
                  <Typography variant="buttonMedium">Change password</Typography>
                </AppButton>
              </Box>
            </Box>
          </FormBox>
        )}
      </Container>
    </AppLayout>
  );
};
