import { Box, Divider, Typography, useTheme } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import AppButton from '../../../components/materials/actions/AppButton';
import { addResource } from '../../../utils/redux/keyThemeSlice';
import { KeyThemeResourceType } from '../../../utils/types/KeyTheme';
import SearchBox from '../../Insights/components/SearchBox';
import ResourcesList from './ResourcesList';

interface Props {
  resourceType: KeyThemeResourceType;
  onNewResource: (id: string, resource: FormData) => void;
}

const ResourcesUpload: React.FC<Props> = ({ resourceType, onNewResource }) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const handleFileUpload = useMemo(() => {
    return async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files != null ? event.target.files[0] : null;

      if (file === null || file === undefined) return;

      const formData = new FormData();

      const id = nanoid();
      formData.append('file', file);
      formData.append('file_category', resourceType);
      const newKeyThemeFile = {
        file_storage_path: '',
        file_type: file.type,
        file_name: file.name,
        id,
        resource_type: resourceType,
      };
      onNewResource(id, formData);

      dispatch(addResource({ resourceType, resource: newKeyThemeFile }));
    };
  }, [dispatch, resourceType, onNewResource]);

  return (
    <>
      <input
        accept=".jpg, .jpeg, .png, .pdf"
        style={{ display: 'none' }}
        id={`contained-button-file-${resourceType}`}
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor={`contained-button-file-${resourceType}`}>
        <AppButton
          component="span"
          sx={{
            color: palette.green[500],
            borderRadius: '100px',
            borderColor: palette.green[500],
            '&:hover, &:active, &:focus': {
              backgroundColor: 'rgba(97, 97, 97, 0.04)',
              borderColor: palette.green[700],
            },
          }}
          variant="outlined"
        >
          Choose files to upload
        </AppButton>
      </label>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={palette.gray.white}
        borderRadius="8px"
        border={`1px solid ${palette.gray[250]}`}
        gap={1}
      >
        <Box display="flex" m={2} gap={1} justifyContent={'space-between'}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="labelLarge">
              <b>{resourceType === KeyThemeResourceType.Kpi ? 'KPI Page Name' : 'Uploaded Files'}</b>
            </Typography>
            <Typography variant="labelSmall">
              The page at the top of this list will appear in the Theme Card preview and be the first to be displayed
              in the card’s supporting data section.
            </Typography>
          </Box>
          {resourceType === KeyThemeResourceType.Kpi && <SearchBox />}
        </Box>
        <Divider />
        <ResourcesList resourceType={resourceType} />
      </Box>
    </>
  );
};

export default ResourcesUpload;
