import { FlatfileProvider } from '@flatfile/react';
import { CheckCircle, HourglassTop, Upload, Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AppCircularProgress from '../../../../../../../../components/materials/loading/AppCircularProgress';
import { useWorkspaceDataContext } from '../../../../../../../../context/WorkspaceDataContext';
import { useGetFlatfileAccessTokenQuery } from '../../../../../../../../utils/redux/api';
import { SubmoduleStatus } from '../../../../../../../../utils/types/SubModule';
import FlatfilePortal from './FlatfilePortal';

const UploadFlatFile: React.FC = () => {
  const { activeWorkspace: workspace, activeSubmodule } = useWorkspaceDataContext();
  const status = activeSubmodule?.status ?? SubmoduleStatus.AssignDataCollector;
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { data: tokenData, isLoading: isLoadingAccessToken } = useGetFlatfileAccessTokenQuery(Number(workspaceId));

  const getFlatfileContent = useCallback(() => {
    switch (status) {
      case SubmoduleStatus.ProcessingFile:
        return {
          icon: <HourglassTop sx={{ color: ({ palette }) => palette.yellow[450] }} />,
          title: (
            <Typography sx={{ color: ({ palette }) => palette.yellow[700] }} variant="titleMedium">
              Processing File
            </Typography>
          ),
          description: (
            <Typography sx={{ color: ({ palette }) => palette.yellow[700] }} variant="body2">
              The file has been successfully uploaded and processing it will take around 15 minutes. You will receive
              an e-mail once the processing and validating is completed.
            </Typography>
          ),
          buttonProps: { sx: { display: 'none' } },
        };
      case SubmoduleStatus.AwaitingIssueResolution:
        return {
          icon: <CheckCircle sx={{ color: ({ palette }) => palette.green[400] }} />,
          title: <Typography variant="titleMedium">Data Uploaded and Successfully Analyzed</Typography>,
          description: <Typography variant="body2"></Typography>,
          buttonProps: { disabled: true },
        };
      case SubmoduleStatus.DataUploadFinalized:
        return {
          icon: <CheckCircle sx={{ color: ({ palette }) => palette.green[400] }} />,
          title: <Typography variant="titleMedium">Data Upload Finalized</Typography>,
          description: (
            <Typography variant="body2">Data uploaded successfully! No further action required.</Typography>
          ),
          buttonProps: { disabled: true },
        };
      case SubmoduleStatus.ValidateDataUpload:
        return {
          icon: <CheckCircle sx={{ color: ({ palette }) => palette.green[400] }} />,
          title: <Typography variant="titleMedium">Validate Uploaded Data</Typography>,
          description: (
            <Typography variant="body2">
              File uploaded successfully. Validate the data from the grids below, update changes (if any) and click on
              Submit to finalize
            </Typography>
          ),
          buttonProps: {},
        };
      case SubmoduleStatus.Error:
        return {
          icon: <Error sx={{ color: ({ palette }) => palette.red[500] }} />,
          title: <Typography variant="titleMedium">Error</Typography>,
          description: (
            <Typography variant="body2">
              Error processing the file. Kindly recheck the file and try uploading it again
            </Typography>
          ),
          buttonProps: {},
        };
      default:
        return {
          icon: <Upload sx={{ color: ({ palette }) => palette.green[500] }} />,
          title: <Typography variant="titleMedium">Upload File</Typography>,
          description: (
            <Typography variant="body2">
              Save time by using our data upload and mapping tool to auto-populate the data request templates.
            </Typography>
          ),
          buttonProps: {},
        };
    }
  }, [status]);

  const { icon, title, description, buttonProps } = getFlatfileContent();

  return (
    <Box
      sx={{
        bgcolor: ({ palette }) => {
          if (status === SubmoduleStatus.ProcessingFile) {
            return palette.yellow[200];
          } else if (status === SubmoduleStatus.Error) {
            return palette.red[200];
          } else {
            return palette.green[100];
          }
        },
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={1.5}>
          {icon}
          <Box display="flex" flexDirection="column" gap={0.5}>
            {title}
            {description}
          </Box>
        </Box>
        <FlatfileProvider
          accessToken={tokenData?.access_token ?? ''}
          config={{
            displayAsModal: true,
            preload: false,
            iframeStyles: { marginLeft: 64, width: '90%' },
          }}
        >
          {isLoadingAccessToken ? (
            <AppCircularProgress />
          ) : (
            <FlatfilePortal spaceId={workspace?.flatfile_space_id} buttonProps={buttonProps} />
          )}
        </FlatfileProvider>
      </Box>
    </Box>
  );
};

export default UploadFlatFile;
