import { format } from 'date-fns';

/**
 * Formats a given date string into a specified format.
 *
 * @param dateString - The date string to be formatted. This should be a valid date string that can be parsed by the `Date` constructor.
 * @param dateFormat - (Optional) The format string to use for formatting the date. Defaults to 'MMM d, yyyy' (e.g., Jan 1, 2024).
 * @returns The formatted date string according to the specified format.
 */

export const formatDate = (dateString: string, dateFormat: string = 'MMM d, yyyy'): string => {
  if (dateString === '' || dateString === null) return '';

  const date = new Date(dateString);
  return format(date, dateFormat);
};
