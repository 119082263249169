import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../../../utils/hooks/useAppSelector';
import { selectIsUserAdmin } from '../../../../../utils/redux/authSlice';
import BackButton from '../Workspace/components/BackButton';

const WorkspaceWrapper: React.FC = () => {
  const isUserAdmin = useAppSelector((state) => selectIsUserAdmin(state));

  return (
    <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', mb: '16px' }}>
      {isUserAdmin ? <BackButton text="Go back to Workspaces" to="/app/workspaces" /> : null}
      <Outlet />
    </Box>
  );
};

export default WorkspaceWrapper;
