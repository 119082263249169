import { Box, useTheme } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import ModulesNavigationBar from './Modules/components/ModulesNavigationBar';

const DetailedResults: React.FC = () => {
  const { palette } = useTheme();

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderRadius: '8px 8px 0 0',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
        }}
        border={1}
      >
        <ModulesNavigationBar />
      </Box>
      {/* modules */}
      <Outlet />
    </>
  );
};

export default DetailedResults;
