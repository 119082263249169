import { type Module } from './Module';
import { type Workspace } from './Workspace';

export interface User {
  id?: number;
  name?: string;
  email?: string;
  password?: string;
  jwt?: string;
  is_active?: boolean;
  roles?: string[];
  created_date?: Date;
  workspaces?: Workspace[];
  modules?: Module[];
}

export enum UserRoles {
  ADMIN = 'admin',
  DATA_COLLECTOR = 'data_collector',
  DATA_LEAD = 'data_lead',
}

export interface UserInfo {
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
}

export interface ChangeUserDetailsRequest {
  first_name: string;
  last_name: string;
  email: string;
  userId: number;
}

export interface UpdateUserInfoRequest {
  userId: number;
  name?: string;
  password?: string;
  email?: string;
  roles?: string[];
}

export interface InviteAdminRequest {
  name: string;
  email: string;
}
