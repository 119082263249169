import React from 'react';
import {
  TextField,
  Autocomplete,
  type SxProps,
  type InputProps,
  type AutocompleteRenderGroupParams,
} from '@mui/material';

interface AppAutoCompleteProps {
  options: any[];
  label?: string;
  placeholder?: string;
  sx?: SxProps;
  getOptionLabel?: (option: any) => string;
  groupBy?: (option: any) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  onChange?: (event: any, value: any) => void;
  InputProps?: InputProps & { inputProps?: React.InputHTMLAttributes<HTMLInputElement> };
  multiple?: boolean;
  value?: any;
}

const AppAutoComplete: React.FC<AppAutoCompleteProps> = ({
  options,
  label,
  placeholder,
  sx,
  getOptionLabel,
  groupBy,
  onChange,
  renderGroup,
  InputProps,
  multiple = false,
  value,
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel ?? ((option) => option)}
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderInput={(params) => {
        return <TextField {...params} label={label} placeholder={placeholder} variant="outlined" />;
      }}
      sx={sx}
      onChange={onChange}
      value={value}
    />
  );
};

export default AppAutoComplete;
