import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material';
import { Box, Container, Divider, IconButton, InputAdornment, Link, Typography, useTheme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OktaVerifierIcon from '../../assets/icons/OktaSign';
import AppButton from '../../components/materials/actions/AppButton';
import AppLayout from '../../components/materials/containers/AppLayout';
import AppTextField from '../../components/materials/forms/AppTextField';
import AppCircularProgress from '../../components/materials/loading/AppCircularProgress';
import AccountHeader from '../../components/materials/navigation/AccountHeader';
import oktaAuth from '../../components/okta/OktaAuth';
import generatePkceChallenge from '../../components/okta/PkceChallenge';
import { ToastContext } from '../../context/toastContext';
import { useLazyGetUserQuery, useLoginMutation } from '../../utils/redux/api';
import { setAuth, setUserDetails } from '../../utils/redux/authSlice';
import { type LoginResponseToken } from '../../utils/types/Login';
import { type User } from '../../utils/types/User';
import FormBox from './components/FormBox';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const generateStateParam = () => {
  return window.crypto.randomUUID();
};

const AccountLogin = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [getUserTrigger] = useLazyGetUserQuery();

  // Context
  const { issuer, clientId, redirectUri, scopes } = oktaAuth.options;
  const responseType: string = 'code';
  const [oktaState, setOktaState] = useState<string>('');
  const [codeVerifying, setCodeVerifying] = useState<string>('');
  const [codeChallenging, setCodeChallenging] = useState<string>('');
  const safeClientId: string = clientId ?? '';
  const safeRedirectUri: string = redirectUri ?? '';
  const safeScopes: string = scopes?.join(' ') ?? '';

  const { handleOpenToast } = useContext(ToastContext);
  const [login] = useLoginMutation();

  const authUrl =
    `${issuer}/v1/authorize?` +
    `client_id=${encodeURIComponent(safeClientId)}&` +
    `response_type=${encodeURIComponent(responseType)}&` +
    `scope=${encodeURIComponent(safeScopes)}&` +
    `redirect_uri=${encodeURIComponent(safeRedirectUri)}&` +
    `state=${encodeURIComponent(oktaState)}&` +
    `code_challenge=${codeChallenging}&` +
    `code_challenge_method=S256`;

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);
    login(formData)
      .then((response: any) => {
        if (response?.data !== null) {
          const decode = jwtDecode<LoginResponseToken>(response.data.access_token as string);

          dispatch(setAuth({ accessToken: response.data.access_token as string, email: decode.email }));

          getUserTrigger(decode.email)
            .then((userResponse: any) => {
              if (userResponse?.status === 'fulfilled' && userResponse?.data !== null) {
                dispatch(setUserDetails(userResponse.data as User));
                setLoading(false);
                navigate('/authorization-code/role-check-and-redirect');
              }
            })
            .catch((err: any) => {
              setLoading(false);
              handleOpenToast({
                severity: 'error',
                message: err?.error?.data?.detail ?? 'An error occurred, please check your credentials',
              });
            });
        } else {
          setLoading(false);
          handleOpenToast({
            severity: 'error',
            message: response?.error?.data?.detail ?? 'An error occurred, please check your credentials',
          });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleOpenToast({
          severity: 'error',
          message: err?.error?.data?.detail ?? 'An error occurred, please check your credentials',
        });
      });
  };

  const oktaLogin = () => {
    window.location.href = authUrl;
  };

  useEffect(() => {
    setIsFormValid(emailRegex.test(email) && password.length > 0);
  }, [email, password]);

  useEffect(() => {
    generatePkceChallenge()
      .then((pkce: { verifier: string; challenge: string }) => {
        setCodeVerifying(pkce.verifier);
        setCodeChallenging(pkce.challenge);
        setOktaState(generateStateParam());
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // Whenever codeVerifying changes and is not empty, save it to local storage
    if (codeVerifying !== '') {
      localStorage.setItem('oktaVerifier', codeVerifying);
    }
    if (oktaState !== '') {
      localStorage.setItem('state', oktaState);
    }
  }, [codeVerifying, oktaState]); // This effect depends on the codeVerifying state

  return (
    <AppLayout>
      <AccountHeader variant="page" />
      <Container component="main" maxWidth="xs">
        <FormBox>
          <Typography variant="displaySmall" sx={{ color: palette.green[700] }}>
            Log In
          </Typography>
          <Divider sx={{ width: '320px' }} />
          <AppButton
            onClick={oktaLogin}
            variant="text"
            sx={{
              border: `1px solid ${palette.green[500]}`,
              borderRadius: '4px',
              padding: '0px, 16px, 0px, 16px',
              width: '100%',
            }}
            startIcon={<OktaVerifierIcon />}
          >
            <Typography variant="buttonMedium" sx={{ color: palette.green[500], textDecoration: 'underline' }}>
              Sign in with Okta FastPass
            </Typography>
          </AppButton>
          <Divider sx={{ width: '320px' }}>OR</Divider>
          <Typography variant="bodyLarge" sx={{ color: palette.gray.black }}>
            Enter your username (email) and password to log in to the REBEX portal.
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <AppTextField
              id="email"
              label="Email (Username)"
              placeholder="Enter your username"
              value={email}
              variant="outlined"
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px', mb: 5 }}
            />
            <AppTextField
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your password"
              value={password}
              variant="outlined"
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px' }}
            />
            <Typography variant="labelMedium" sx={{ mt: 2, textAlign: 'flex-start' }} color={palette.gray.black}>
              Forgot your password? Click{' '}
              <Link href="/request-reset-password" rel="noopener" target="_blank" color={palette.green[500]}>
                here
              </Link>
            </Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: '104px' }}>
              {loading ? (
                <AppCircularProgress sx={{ mt: 7 }} />
              ) : (
                <AppButton
                  variant="contained"
                  disabled={!isFormValid}
                  sx={{
                    mt: 7,
                    backgroundColor: palette.green[500],
                    borderRadius: '100px',
                    '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
                  }}
                  onClick={(event) => {
                    void handleSubmit(event);
                  }}
                >
                  <Typography variant="buttonMedium">Log In</Typography>
                </AppButton>
              )}
            </Box>
          </Box>
        </FormBox>
      </Container>
    </AppLayout>
  );
};

export default AccountLogin;
