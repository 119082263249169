import { type DatapointOption, type KPIOption } from '../../types/Reports';
import { rebexApi } from './baseRebexEndpoints';

export const reportsExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getKPIs: builder.query<KPIOption[], null>({
      query: () => '/reports/kpis',
    }),
    getDatapoints: builder.query<DatapointOption[], null>({
      query: () => '/reports/datapoints',
    }),
  }),
});
