import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { PersonalBankingFTETables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const FrontAndMiddleOfficesFTECompensation: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable1 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
    ),
  );
  const dataTable2 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice,
    ),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse1,
    isLoading: isLoadingDataTable1,
    refetch: refetchDataTable1,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
  });
  const {
    data: dataTableResponse2,
    isLoading: isLoadingDataTable2,
    refetch: refetchDataTable2,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const table1Categories = useRef<Category[]>([
    { label: 'Branches', editable: true, indentLevel: 1 },
    { label: 'Call center', editable: true, indentLevel: 1 },
    { label: 'Remote advisor', editable: true, indentLevel: 1 },
    { label: 'Third party sales force', editable: true, indentLevel: 1 },
    { label: 'Non branch sales', editable: true, indentLevel: 1 },
    { label: 'Digital banking', editable: true, indentLevel: 1 },
  ]);
  const table1Columns = useRef<Column[]>([
    { key: 'A', label: 'Base pay ($)', editable: true },
    { key: 'B', label: 'Variable pay ($)', editable: true },
    { key: 'C', label: 'Benefits ($)', editable: true },
    { key: 'D', label: 'Total compensation ($)', editable: false },
  ]);

  const table2Categories = useRef<Category[]>([
    { label: 'Sales mgmt, Channel mgmt, & Product mgmt', editable: true, indentLevel: 1 },
    { label: 'Collections & Recoveries', editable: true, indentLevel: 1 },
    { label: 'Operations', editable: true, indentLevel: 1 },
  ]);
  const table2Columns = useRef<Column[]>([
    { key: 'A', label: 'Base pay ($)', editable: true },
    { key: 'B', label: 'Variable pay ($)', editable: true },
    { key: 'C', label: 'Benefits ($)', editable: true },
    { key: 'D', label: 'Total compensation ($)', editable: false },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable1) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse1?.id ?? 0,
            commentCount: dataTableResponse1?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
            data: dataTableResponse1?.data ?? [],
            columns: dataTableResponse1?.columns ?? [],
            rows: dataTableResponse1?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D'],
          rowCount: 6,
          totalColumns: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6'],
        }),
      );
    }

    dataTableResponse1?.rows.forEach((rowName, index) => {
      table1Categories.current[index].rowName = rowName;
    });

    dataTableResponse1?.columns?.forEach((columnName, index) => {
      table1Columns.current[index].columnName = columnName;
    });

    if (!isLoadingDataTable2) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse2?.id ?? 0,
            commentCount: dataTableResponse2?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice,
            data: dataTableResponse2?.data ?? [],
            columns: dataTableResponse2?.columns ?? [],
            rows: dataTableResponse2?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D'],
          rowCount: 3,
          totalColumns: ['D1', 'D2', 'D3'],
        }),
      );

      dataTableResponse2?.rows.forEach((rowName, index) => {
        table2Categories.current[index].rowName = rowName;
      });

      dataTableResponse2?.columns?.forEach((columnName, index) => {
        table2Columns.current[index].columnName = columnName;
      });
    }
  }, [
    dispatch,
    dataTableResponse1,
    isLoadingDataTable1,
    dataTableResponse2,
    isLoadingDataTable2,
    tabKey,
    tableSubmoduleId,
  ]);

  useEffect(() => {
    const refetchAll = async () => {
      try {
        await Promise.all([refetchDataTable1(), refetchDataTable2()]);
      } catch (error) {
        console.log('Error in fetching data');
      }
    };

    refetchAll().catch((err) => {
      console.log(err);
    });
  }, [fetchFlag, refetchDataTable1, refetchDataTable2]);

  useEffect(() => {
    if (dataTable1 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable1.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable1.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable1, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable2 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable2.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable2.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable2, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>, table: string) => {
    if (table === PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice) {
      const table1d1 = formatNumber(sumValues(['A1', 'B1', 'C1'], values));
      const table1d2 = formatNumber(sumValues(['A2', 'B2', 'C2'], values));
      const table1d3 = formatNumber(sumValues(['A3', 'B3', 'C3'], values));
      const table1d4 = formatNumber(sumValues(['A4', 'B4', 'C4'], values));
      const table1d5 = formatNumber(sumValues(['A5', 'B5', 'C5'], values));
      const table1d6 = formatNumber(sumValues(['A6', 'B6', 'C6'], values));

      return {
        ...values,
        D1: table1d1,
        D2: table1d2,
        D3: table1d3,
        D4: table1d4,
        D5: table1d5,
        D6: table1d6,
      };
    }

    if (table === PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice) {
      const table2d1 = formatNumber(sumValues(['A1', 'B1', 'C1'], values));
      const table2d2 = formatNumber(sumValues(['A2', 'B2', 'C2'], values));
      const table2d3 = formatNumber(sumValues(['A3', 'B3', 'C3'], values));

      return {
        ...values,
        D1: table2d1,
        D2: table2d2,
        D3: table2d3,
      };
    }

    return values;
  };

  const handleClick = (cellId: string, column: string, row: string, dataTableId: number | undefined) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTableId, { cellId, column, row });
    }
  };

  const handleValueChangeTable1 = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable1?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(
      values as Record<string, number | null>,
      PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
    );
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
          values: updatedValues,
        },
        columns: ['A', 'B', 'C', 'D'],
        rowCount: 6,
      }),
    );
  };
  const handleValueChangeTable2 = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable2?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(
      values as Record<string, number | null>,
      PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice,
    );
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationMiddleOffice,
          values: updatedValues,
        },
        columns: ['A', 'B', 'C', 'D'],
        rowCount: 3,
      }),
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box>
        <TableComponent
          categories={table1Categories.current}
          columns={table1Columns.current}
          values={dataTable1?.values ?? {}}
          onValueChange={handleValueChangeTable1}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getColumnStyle={(column) => {
            if (column.label === 'Total compensation ($)') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable1?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table2Categories.current}
          columns={table2Columns.current}
          values={dataTable2?.values ?? {}}
          onValueChange={handleValueChangeTable2}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable2?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable2?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getColumnStyle={(column) => {
            if (column.label === 'Total compensation ($)') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable2?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default FrontAndMiddleOfficesFTECompensation;
