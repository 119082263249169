import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../utils/hooks/useAppSelector';
import { clearActiveDataTableId, setActiveTabKey } from '../../../../../../../utils/redux/dataTablesSlice';
import {
  type TabKeys,
  TabKeysBySubmodule,
  TypeAndFunctionTables,
  CustomersTables,
  ProductSalesTables,
  OnlineAndMobileCustomersTables,
  BranchAndATMCustomersTables,
  ContactCenterTables,
  PersonalBankingFTETables,
} from '../../../../../../../utils/types/DataTablesEnum';

const labelChanges: Record<string, string> = {
  [TypeAndFunctionTables.MarketingCostsTable1TotalMarketing]: 'Marketing Costs',
  [TypeAndFunctionTables.CostByType]: 'Cost P&L View',
  [CustomersTables.DistributionByProductHoldingTable1]: 'Distribution By Product Holding',
  [CustomersTables.TotalCustomersOverviewAndSegmentsTable1]: 'Total Customers Overview And Segments',
  [CustomersTables.DistributionOfCheckingCustomerAverageBalance]: 'Distribution By Balance',
  [CustomersTables.DistributionOfTenure]: 'Distribution By Tenure',
  [ProductSalesTables.CallCenterRemoteChannels]: 'New Accounts Opened Via Remote Advisor and Call Center Channels',
  [ProductSalesTables.DigitalChannels]: 'New Accounts Opened Via Digital Channels',
  [ProductSalesTables.NewProductsSoldToNewToBankCustomers]: 'Sales To New-To-Bank Customers',
  [ProductSalesTables.PhysicalChannels]: 'New Accounts Opened Via Physical Channels',
  [ProductSalesTables.SummaryOfSalesByProductType]: 'Summary Of New Products Sold By Type',
  [OnlineAndMobileCustomersTables.ChatbotUsageTable1]: 'Chatbot Usage 90 Days',
  [OnlineAndMobileCustomersTables.CustomerActivity90DayByAge]: '90-Days Customer Activity By Age',
  [OnlineAndMobileCustomersTables.CustomerActivityTable1]: 'Customer Activity',
  [OnlineAndMobileCustomersTables.DigitalEnrollmentTable1]: 'Digital Enrollment',
  [OnlineAndMobileCustomersTables.DigitalSalesFunnel]: 'Checking Accounts Digital Sales Funnel',
  [BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1]: 'Customer Activity In Physical Channels',
  transactions_in_digital_physical: 'Transactions In Physical Channels',
  [ContactCenterTables.CallCenterMetricsTable1]: 'Call Center Metrics',
  [ContactCenterTables.CustomerActivityInCallCenter]: 'Customer Activity In Call Center And Remote Advisory',
  [PersonalBankingFTETables.BranchFTE]: 'Branch FTE',
  [PersonalBankingFTETables.BranchFTECompensation]: 'Branch FTE Compensation',
  [PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice]: 'Front, Middle Offices And Support FTE',
  [PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice]: 'Front, Middle Offices FTE Compensation',
  [PersonalBankingFTETables.OperationsFTE]: 'Operations FTE',
  [PersonalBankingFTETables.OperationsFTECompensation]: 'Operations FTE Compensation',
};

const convertToLabel = (str: string) => {
  if (labelChanges[str] !== undefined) {
    return labelChanges[str];
  }
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const DataTableTabs: React.FC = () => {
  const { activeSubmodule } = useWorkspaceDataContext();
  const activeTab = useAppSelector((state) => state.dataTables.activeTabKey);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { palette } = useTheme();
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  const dispatch = useDispatch();

  const scrollLeft = () => {
    if (scrollContainerRef.current != null) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current != null) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const scrollToTab = (index: number) => {
    if (scrollContainerRef.current != null) {
      const tab = scrollContainerRef.current.children[index] as HTMLElement;
      const containerLeft = scrollContainerRef.current.scrollLeft;
      const tabLeft = tab.offsetLeft;
      const containerWidth = scrollContainerRef.current.clientWidth;
      const tabWidth = tab.clientWidth;

      const scrollPosition = tabLeft - containerLeft - containerWidth / 2 + tabWidth / 2;

      scrollContainerRef.current.scrollBy({ left: scrollPosition + 10, behavior: 'smooth' });
    }
  };

  const checkScrollPosition = useCallback(() => {
    if (scrollContainerRef.current != null) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setAtStart(scrollLeft === 0);
      setAtEnd(scrollLeft + clientWidth === scrollWidth);
    }
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;

    checkScrollPosition();

    if (container != null) {
      container.addEventListener('scroll', checkScrollPosition);
    }

    window.addEventListener('resize', checkScrollPosition);

    return () => {
      if (container != null) {
        container.removeEventListener('scroll', checkScrollPosition);
      }
      window.removeEventListener('resize', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  if (activeSubmodule === undefined) return;

  const tabKeys = TabKeysBySubmodule[activeSubmodule.name];

  return (
    <>
      {!atStart && (
        <ButtonBase onClick={scrollLeft} disableRipple sx={{ boxShadow: '8px 0px 3px -5px rgba(0, 0, 0, 0.2)' }}>
          <ArrowLeft sx={{ color: palette.green[500] }} />
        </ButtonBase>
      )}
      <Box
        flexShrink={1}
        display="flex"
        width="100%"
        justifyContent="space-between"
        sx={{
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          whiteSpace: 'nowrap',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none', // IE and Edge
          scrollbarWidth: 'none', // Firefox
        }}
        ref={scrollContainerRef}
      >
        {tabKeys.map((tabKey: TabKeys, index) => {
          const isSelected = activeTab === tabKey;
          return (
            <Box
              onClick={() => {
                scrollToTab(index);
                dispatch(setActiveTabKey(tabKey));
                dispatch(clearActiveDataTableId());
              }}
              key={tabKey}
              color={isSelected ? palette.green[500] : palette.gray[500]}
              bgcolor={isSelected ? palette.green[50] : 'inherit'}
              borderBottom={isSelected ? `2px solid ${palette.green[500]}` : 'inherit'}
              px={1}
              py={2}
              flexGrow={1}
              minWidth="150px"
              sx={{
                textWrap: 'wrap',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: palette.green[100],
                  color: palette.green[400],
                  borderBottom: `2px solid ${palette.green[400]}`,
                },
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="labelLarge">{convertToLabel(tabKey)}</Typography>
            </Box>
          );
        })}
      </Box>
      {!atEnd && (
        <ButtonBase onClick={scrollRight} disableRipple sx={{ boxShadow: '-8px 0px 3px -5px rgba(0, 0, 0, 0.2)' }}>
          <ArrowRight sx={{ color: palette.green[500] }} />
        </ButtonBase>
      )}
    </>
  );
};

export default DataTableTabs;
