import { Box, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppCircularProgress from '../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../context/toastContext';
import { useAppSelector } from '../../../../../../utils/hooks/useAppSelector';
import { useDeleteThemeMutation, useGetThemesQuery } from '../../../../../../utils/redux/api';
import { selectIsUserAdmin } from '../../../../../../utils/redux/authSlice';
import {
  clearSelectedKeyThemeId,
  deleteKeyThemeById,
  setKeyThemes,
  setSelectedKeyThemeId,
} from '../../../../../../utils/redux/keyThemeSlice';
import { KeyThemeStatus } from '../../../../../../utils/types/KeyTheme';
import KeyThemeCarousel from '../../../../../KeyThemesAndInsights/components/KeyThemeCarousel';
import KeyThemeEditView from '../../../../../KeyThemesAndInsights/components/KeyThemeEditView';
import KeyThemeList from '../../../../../KeyThemesAndInsights/components/KeyThemeList';
import KeyThemeView from '../../../../../KeyThemesAndInsights/components/KeyThemeView';

const KeyThemesAndInsights = () => {
  const { isEditingKeyTheme, keyThemes } = useAppSelector((state) => state.keyThemes);
  const { workspaceId } = useParams();
  const { handleOpenToast } = useContext(ToastContext);
  const { data, isFetching, isSuccess, isError } = useGetThemesQuery(Number(workspaceId));
  const [deleteThemeTrigger] = useDeleteThemeMutation();
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const userIsAdmin = useAppSelector((state) => selectIsUserAdmin(state));

  const handleDeleteTheme = useCallback(
    async (themeId: number) => {
      try {
        await deleteThemeTrigger(themeId).unwrap();
        dispatch(deleteKeyThemeById({ id: themeId }));
        dispatch(clearSelectedKeyThemeId());

        handleOpenToast({
          message: 'Theme deleted succesfully',
          severity: 'success',
        });
      } catch (error) {
        handleOpenToast({
          severity: 'error',
          message: 'Could not delete the theme',
        });
      }
    },
    [deleteThemeTrigger, dispatch, handleOpenToast],
  );

  const getKeyThemeView = useCallback(() => {
    if (isEditingKeyTheme) {
      return <KeyThemeEditView handleDeleteTheme={handleDeleteTheme} />;
    }
    return <KeyThemeView handleDeleteTheme={handleDeleteTheme} />;
  }, [handleDeleteTheme, isEditingKeyTheme]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setKeyThemes(data));
      dispatch(setSelectedKeyThemeId(data[0]?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleOpenToast({
        message: 'Failed to fetch the key themes, try again later or contact support',
        severity: 'error',
      });
    }
  }, [handleOpenToast, isError]);

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
          padding: '20px 32px',
          flexGrow: 1,
        }}
        border={1}
      >
        {isFetching ? (
          <AppCircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
            }}
            color="inherit"
          />
        ) : (
          <Box display="flex" m={3} columnGap={1} flexGrow={1} alignItems="flex-start">
            <KeyThemeList />
            <Box width="100%" overflow={'hidden'}>
              <KeyThemeCarousel
                keyThemeIds={
                  userIsAdmin
                    ? keyThemes.map((kt) => kt.id)
                    : keyThemes.filter((kt) => kt.status === KeyThemeStatus.Published).map((kt) => kt.id)
                }
              >
                {getKeyThemeView()}
              </KeyThemeCarousel>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default KeyThemesAndInsights;
