import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControlLabel,
  IconButton,
  InputBase,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import {
  deleteKeyThemeItemByIndex,
  selectCurrentKeyTheme,
  switchItemConsumerSurveyLabelByIndex,
  updateKeyThemeItemByIndex,
} from '../../../utils/redux/keyThemeSlice';
import { KeyThemeStatus, type Insight, KeyThemeItemType, type Opportunity } from '../../../utils/types/KeyTheme';

interface Props {
  item?: Insight | Opportunity;
  itemType: KeyThemeItemType;
  index: number;
}

const KeyThemeItemCard: React.FC<Props> = ({ item, itemType, index }) => {
  const isEditingKeyTheme = useAppSelector((state) => state.keyThemes.isEditingKeyTheme);
  const status = useAppSelector((state) => selectCurrentKeyTheme(state)?.status);
  const { palette, typography } = useTheme();
  const [isEditingItem, setIsEditingItem] = useState(false);
  const dispatch = useDispatch();

  if (item === undefined) return;

  const handleEditClick = () => {
    setIsEditingItem(true);
  };

  const handleSaveClick = () => {
    setIsEditingItem(false);
  };

  const handleItemUpdate = (updatedItem: Insight | Opportunity) => {
    dispatch(updateKeyThemeItemByIndex({ item: updatedItem, itemType, index }));
  };

  return (
    <Card
      sx={{
        bgcolor: palette.green[100],
        width: '100%',
        border: `1px solid ${palette.gray[250]}`,
        p: 2,
        height: status === KeyThemeStatus.Draft || isEditingKeyTheme ? '300px' : 'inherit',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, height: '100%' }}>
        {isEditingKeyTheme ? (
          <Box display="flex" justifyContent="space-between">
            <FormControlLabel
              control={
                <Switch
                  checked={item.consumerSurveyLabel}
                  onChange={() => {
                    dispatch(switchItemConsumerSurveyLabelByIndex({ itemIndex: index, itemType }));
                  }}
                />
              }
              label={<Typography variant="labelSmall">Add Consumer Survey Label</Typography>}
            />
            <Box>
              <IconButton
                size="small"
                sx={{ color: palette.green[500] }}
                onClick={isEditingItem ? handleSaveClick : handleEditClick}
                aria-label={isEditingItem ? 'save' : 'edit'}
              >
                {isEditingItem ? <CheckIcon /> : <ModeEditOutlineOutlinedIcon />}
              </IconButton>
              <IconButton
                size="small"
                onClick={() => dispatch(deleteKeyThemeItemByIndex({ index, itemType }))}
                sx={{ color: palette.green[500] }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        ) : itemType === KeyThemeItemType.Insight ? (
          <Box>
            <Chip
              label={item.consumerSurveyLabel ? `Consumer Survey ${itemType}` : `REBEX Benchmarking ${itemType}`}
              variant={item.consumerSurveyLabel ? 'filled' : 'outlined'}
              sx={item.consumerSurveyLabel ? { backgroundColor: palette.green[500], color: palette.gray.white } : {}}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box display="flex">
          <Box display="flex" alignItems="center">
            <LightbulbOutlined sx={{ color: palette.green[700], fontSize: '40px' }} />
          </Box>
          <Box bgcolor={palette.green[200]} p={2} borderRadius="4px" flexGrow={1}>
            <InputBase
              disabled={!isEditingItem}
              value={item.title}
              multiline
              onChange={(e) => {
                handleItemUpdate({ ...item, title: e.target.value });
              }}
              sx={{
                fontSize: typography.headlineSmall,
                padding: 0,
                width: '100%',
                '& .MuiInputBase-input.Mui-disabled': {
                  color: 'black',
                  WebkitTextFillColor: 'inherit',
                },
              }}
            />
          </Box>
        </Box>
        <Box width="100%">
          <InputBase
            maxRows={8}
            disabled={!isEditingItem}
            value={item.description}
            multiline
            onChange={(e) => {
              handleItemUpdate({ ...item, description: e.target.value });
            }}
            sx={{
              fontSize: typography.bodyLarge,
              padding: 0,
              width: '100%',
              '& .MuiInputBase-input.Mui-disabled': {
                color: 'black',
                WebkitTextFillColor: 'inherit',
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default KeyThemeItemCard;
