import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { PersonalBankingFTETables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const FrontAndMiddleOfficesAndSupportFTE: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice,
    ),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse,
    isLoading: isLoadingDataTable,
    refetch,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Branches', editable: true, indentLevel: 1 },
    { label: 'Call center', editable: true, indentLevel: 1 },
    { label: 'Remote advisor', editable: true, indentLevel: 1 },
    { label: 'Third party sales force', editable: true, indentLevel: 1 },
    { label: 'Non-branch sales', editable: true, indentLevel: 1 },
    { label: 'Digital banking', editable: true, indentLevel: 1 },
    { label: 'Sales mgmt, Channel mgmt & Product mgmt', editable: true, indentLevel: 1 },
    { label: 'Collections & recoveries', editable: true, indentLevel: 1 },
    { label: 'Operations', editable: true, indentLevel: 1 },
    { label: 'Finance', editable: true, indentLevel: 1 },
    { label: 'Human resources', editable: true, indentLevel: 1 },
    { label: 'Risk management', editable: true, indentLevel: 1 },
    { label: 'Compliance, internal audit, & legal', editable: true, indentLevel: 1 },
    { label: 'IT', editable: true, indentLevel: 1 },
    { label: 'Other', editable: true, indentLevel: 1 },
    { label: 'Total', editable: false, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([{ key: 'A', label: 'Personal banking FTE (#)', editable: true }]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 16,
          totalColumns: ['A16'],
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    refetch().catch((err) => {
      console.error(err);
    });
  }, [fetchFlag, refetch]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const a16 = formatNumber(
      sumValues(
        ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'A11', 'A12', 'A13', 'A14', 'A15'],
        values,
      ),
    );

    return {
      ...values,
      A16: a16,
    };
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice,
          values: updatedValues,
        },
        columns: ['A'],
        rowCount: 16,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace!.end_of_period}`}
          getRowStyle={(category) => {
            if (category.label === 'Total') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default FrontAndMiddleOfficesAndSupportFTE;
