import { Close, Dashboard, Workspaces } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import { Drawer, IconButton, List, ListItem, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import { selectIsUserAdmin } from '../../../../utils/redux/authSlice';

const menuItems = [
  { title: 'Workspaces', icon: <Dashboard />, path: 'workspaces', adminOnly: false },
  { title: 'Peer Groups', icon: <Workspaces />, path: 'peer-groups', adminOnly: true },
  { title: 'Users', icon: <GroupsIcon />, path: 'users', adminOnly: true },
];

interface StyledMenuListItemProps {
  isSelected?: boolean;
}

const StyledMenuListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<StyledMenuListItemProps>(({ isSelected = false, theme }) => ({
  height: '56px',
  color: isSelected ? theme.palette.green[500] : theme.palette.gray.white,
  background: isSelected ? '#F7FEF8BF' : 'inherit',
  display: 'flex',
  padding: '10px 16px 10px 16px',
  gap: 16,
  marginBottom: 24,
  cursor: 'pointer',
}));

const isPathSelected = (pathToMatch: string, currentPath: string) =>
  matchPath(`app/${pathToMatch}/*`, currentPath) !== null;

interface Props {
  isOpen: boolean;
  handleCloseSideMenu: () => void;
}

const SideMenu: React.FC<Props> = ({ handleCloseSideMenu, isOpen }) => {
  const { palette } = useTheme();
  const { pathname: currentPath } = useLocation();
  const navigate = useNavigate();
  const userIsAdmin = useAppSelector((state) => selectIsUserAdmin(state));

  return (
    <Drawer
      open={isOpen}
      onClose={handleCloseSideMenu}
      PaperProps={{
        sx: {
          width: 240,
          bgcolor: palette.green[700],
        },
      }}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ListItem
          sx={{
            height: '48px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <IconButton onClick={handleCloseSideMenu}>
            <Close sx={{ color: palette.gray.white }} />
          </IconButton>
        </ListItem>

        {menuItems.map((item) => {
          if (item.adminOnly && !userIsAdmin) {
            return null;
          }
          const isSelected = isPathSelected(item.path, currentPath);
          return (
            <StyledMenuListItem
              onClick={() => {
                handleCloseSideMenu();
                navigate(item.path);
              }}
              key={item.title}
              isSelected={isSelected}
            >
              <IconButton sx={{ minWidth: 'inherit' }}>
                {React.cloneElement(item.icon, {
                  fontSize: 'large',
                  sx: { color: isSelected ? palette.green[500] : palette.gray.white },
                })}
              </IconButton>
              <Typography variant="bodyLarge">{item.title}</Typography>
            </StyledMenuListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default SideMenu;
