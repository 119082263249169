import './App.css';
import AppLayout from './components/materials/containers/AppLayout';
import RoutesApp from './utils/routes';

const App = () => {
  return (
    <AppLayout>
      <RoutesApp />
    </AppLayout>
  );
};

export default App;
