import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWorkspaceDataContext } from '../../../../../context/WorkspaceDataContext';
import { formatNameForPath } from '../../../../../utils/stringUtils';
import { type Workspace } from '../../../../../utils/types/Workspace';
import BankCard from './BankCard';
import {
  useArchiveWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useGetPeerGroupsByWorkspaceIdQuery,
  useUnarchiveWorkspaceMutation,
} from '../../../../../utils/redux/api';
import { ConfirmationModal } from './ConfirmationModal';
import { ToastContext } from '../../../../../context/toastContext';
import { number } from 'echarts';

interface DynamicTabContentProps {
  data?: Workspace[];
  archivedTab: boolean;
  setDataUpdated: (dataUpdated: boolean) => void;
}

interface IConfirmationModalDetails {
  title: string;
  description: string;
  action?: () => void;
}

const DynamicTabContent: React.FC<DynamicTabContentProps> = ({ data, archivedTab, setDataUpdated }) => {
  const navigate = useNavigate();
  const { activeWorkspace, setActiveWorkspace } = useWorkspaceDataContext();

  // archiving workspace
  const [archiveWorkspace, archiveWorkspaceResponse] = useArchiveWorkspaceMutation();
  const { handleOpenToast } = useContext(ToastContext);
  const { isLoading: isArchivingWorkspace } = archiveWorkspaceResponse;

  // unarchiving workspace
  const [unarchiveWorkspace, unarchiveWorkspaceResponse] = useUnarchiveWorkspaceMutation();
  const { isLoading: isUnArchivingWorkspace } = unarchiveWorkspaceResponse;

  // deleting workspace
  const [deleteWorkspace, deleteWorkspaceResponse] = useDeleteWorkspaceMutation();
  const { isLoading: isDeletingWorkspace } = deleteWorkspaceResponse;

  const isLoading = isArchivingWorkspace || isUnArchivingWorkspace || isDeletingWorkspace;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState<IConfirmationModalDetails>();

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const workspaceId = activeWorkspace?.workspace_id;

  // fetching peer groups
  const { data: peerGroups } = useGetPeerGroupsByWorkspaceIdQuery(workspaceId!, {
    skip: !isArchiveModalOpen || workspaceId === null || workspaceId === undefined, // Skip query if modal is not open or no valid ID
  });

  useEffect(() => {
    if (isArchiveModalOpen) {
      if ((peerGroups?.length ?? 0) > 0) {
        setConfirmationModalDetails({
          title: confirmationModalDetailsModes.peerGroupsFound.title,
          description: confirmationModalDetailsModes.peerGroupsFound.description,
          action: () => {
            handleDownloadPeerGroups();
          },
        });
      } else {
        setConfirmationModalDetails({
          title: confirmationModalDetailsModes.archive.title,
          description: confirmationModalDetailsModes.archive.description,
          action: () => {
            void handleArchiveWorkspace(activeWorkspace!);
          },
        });
      }
      setIsConfirmationModalOpen(true);
    }
  }, [peerGroups, isArchiveModalOpen]);

  const mountWorkspaceUrl = (workspaceName: string, workspaceId: number | null) => {
    const workspacePath = formatNameForPath(workspaceName);
    return `/app/workspaces/${workspacePath}/${workspaceId}`;
  };

  const handleSettingsClick = (workspace: Workspace) => {
    setActiveWorkspace(workspace);
    const url = mountWorkspaceUrl(workspace.workspace_name, workspace.workspace_id);
    navigate(`${url}/edit-workspace`);
  };

  const handleWorkspaceClick = (workspace: Workspace) => {
    setActiveWorkspace(workspace);
    const url = mountWorkspaceUrl(workspace.workspace_name, workspace.workspace_id);
    navigate(`${url}`);
  };

  const confirmationModalDetailsModes: Record<string, IConfirmationModalDetails> = {
    archive: {
      title: 'Are you sure that you want to archive this workspace?',
      description: 'This action will deactivate the workspace',
    },
    unarchive: {
      title: 'Are you sure you want to restore this workspace from archive?',
      description: 'This action will restore the workspace',
    },
    delete: {
      title: 'Are you sure that you want to delete this workspace?',
      description: 'This action is irreversible!',
    },
    peerGroupsFound: {
      title: 'Cannot Archive: Remove Peer Group Associations',
      description: 'Download the associated peer groups list to remove links before archiving.',
    },
  };

  const handleDownloadPeerGroups = () => {
    try {
      // 1. Convert records to a text format (e.g., JSON or custom)
      const textData = peerGroups!.map((record) => `ID: ${record.id}, Name: ${record.name}`).join('\n');

      // 2. Create a Blob with the text data
      const blob = new Blob([textData], { type: 'text/plain;charset=utf-8' });

      // 3. Create a link to trigger download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'peer_groups.txt'; // File name
      link.click();

      // 4. Cleanup the link
      URL.revokeObjectURL(link.href);
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not download the list of associated peer groups',
      });
    } finally {
      setIsConfirmationModalOpen(false);
      setIsArchiveModalOpen(false);
    }
  };

  const handleArchiveWorkspace = async (workspace: Workspace) => {
    try {
      await archiveWorkspace(workspace).unwrap();
      setDataUpdated(true);
      handleOpenToast({
        message: 'Workspace archived successfully',
        severity: 'success',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not archive the workspace',
      });
    } finally {
      setIsConfirmationModalOpen(false);
      setIsArchiveModalOpen(false);
    }
  };

  const handleUnArchiveWorkspace = async (workspace: Workspace) => {
    try {
      await unarchiveWorkspace(workspace).unwrap();
      setDataUpdated(true);
      handleOpenToast({
        message: 'Workspace restored successfully',
        severity: 'success',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not restore the workspace',
      });
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleDeleteWorkspace = async (workspace: Workspace) => {
    try {
      await deleteWorkspace(workspace.workspace_id!).unwrap();
      setDataUpdated(true);
      handleOpenToast({
        message: 'Workspace deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not delete the workspace',
      });
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleArchiveClick = (workspace: Workspace) => {
    setActiveWorkspace(workspace);
    setIsArchiveModalOpen(true);
  };

  const handleUnArchiveClick = (workspace: Workspace) => {
    setConfirmationModalDetails({
      title: confirmationModalDetailsModes.unarchive.title,
      description: confirmationModalDetailsModes.unarchive.description,
      action: () => {
        void handleUnArchiveWorkspace(workspace);
      },
    });
    setIsConfirmationModalOpen(true);
  };

  const handleDeleteClick = (workspace: Workspace) => {
    setConfirmationModalDetails({
      title: confirmationModalDetailsModes.delete.title,
      description: confirmationModalDetailsModes.delete.description,
      action: () => {
        void handleDeleteWorkspace(workspace);
      },
    });
    setIsConfirmationModalOpen(true);
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      {data?.map((workspace, index) => (
        <Grid item key={workspace.workspace_id ?? `workspace-${index}`} xs={12} sm={6} lg={4} xl={3}>
          <BankCard
            title={workspace?.workspace_name ?? ''}
            location={workspace.country}
            onSettingsClick={() => {
              handleSettingsClick(workspace);
            }}
            onButtonClick={() => {
              handleWorkspaceClick(workspace);
            }}
            onArchiveClick={() => {
              handleArchiveClick(workspace);
            }}
            onUnArchiveClick={() => {
              handleUnArchiveClick(workspace);
            }}
            onDeleteClick={() => {
              handleDeleteClick(workspace);
            }}
            dueDate={workspace?.final_due_date}
            workspaceId={Number(workspace.workspace_id)}
            issues={workspace.issues}
            comments={workspace.comments}
            progress={workspace.progress}
            archivedTab={archivedTab}
          />
        </Grid>
      ))}
      <ConfirmationModal
        title={confirmationModalDetails?.title ?? 'Confirm Operation'}
        text={confirmationModalDetails?.description ?? 'Are you sure?'}
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          if (isArchiveModalOpen) {
            setIsArchiveModalOpen(false);
          }
        }}
        onSave={
          confirmationModalDetails?.action ??
          (() => {
            setIsConfirmationModalOpen(false);
            if (isArchiveModalOpen) {
              setIsArchiveModalOpen(false);
            }
          })
        }
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default DynamicTabContent;
