import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface TableauState {
  trustedTicket: string | null;
  viewUrl: string | null;
}

const initialState: TableauState = {
  trustedTicket: null,
  viewUrl: null,
};

const tableauSlice = createSlice({
  name: 'tableau',
  initialState,
  reducers: {
    setTicket(state, action: PayloadAction<{ trustedTicket: string }>) {
      state.trustedTicket = action.payload.trustedTicket;
    },
    setViewUrl(state, action: PayloadAction<{ viewUrl: string | null }>) {
      state.viewUrl = action.payload.viewUrl;
    },
    clearViewUrl(state) {
      state.viewUrl = null;
    },
    clearTicket(state) {
      state.trustedTicket = null;
    },
  },
});

export const { setTicket, clearTicket, setViewUrl, clearViewUrl } = tableauSlice.actions;

export default tableauSlice.reducer;
