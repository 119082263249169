import { Box, ButtonBase, Divider, Grid, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RedesignAppButton from '../../../../components/materials/actions/RedesignAppButton';
import AppTextField from '../../../../components/materials/forms/AppTextField';
import AppCircularProgress from '../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../context/toastContext';
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import { useChangeUserDetailsMutation } from '../../../../utils/redux/api';
import { setUserDetails } from '../../../../utils/redux/authSlice';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.{1}[a-zA-Z]{2,})+$/;

const Profile = () => {
  const { userDetails } = useAppSelector((state) => state.auth);
  const [firstName, setFirstName] = useState<string>(userDetails.name?.split(' ')[0] ?? '');
  const [lastName, setLastName] = useState<string>(userDetails.name?.split(' ')[1] ?? '');
  const [email, setEmail] = useState<string>(userDetails.email ?? '');

  const [updateUserDetailsRequest, { isLoading }] = useChangeUserDetailsMutation();
  const { handleOpenToast } = useContext(ToastContext);

  const navigate = useNavigate();
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const checkSubmitDisable = (): boolean => {
    let result = true;

    if (firstName.trim() !== userDetails.name?.split(' ')[0]) result = false;
    if (lastName.trim() !== userDetails.name?.split(' ')[1]) result = false;
    if (email.trim() !== userDetails.email) result = false;
    if (firstName.trim() === '') result = true;
    if (lastName.trim() === '') result = true;
    if (email.trim() === '' || !emailRegex.test(email)) result = true;

    return result;
  };

  const handleSubmit = async () => {
    if (userDetails.id === undefined) {
      handleOpenToast({ severity: 'error', message: 'Invalid user' });
      return;
    }

    try {
      const updatedUser = await updateUserDetailsRequest({
        userId: userDetails.id,
        first_name: firstName,
        last_name: lastName,
        email,
      }).unwrap();

      dispatch(setUserDetails(updatedUser));

      handleOpenToast({ severity: 'success', message: 'User information updated successfully' });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Error updating user information, try again later or contact support',
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          py: '20px',
          px: '32px',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
        }}
        border={1}
      >
        <Typography variant="displaySmall">My Profile</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          py: 3,
          px: 4,
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
        }}
        border={1}
        gap={3}
      >
        <Typography variant="headlineSmall">Your Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AppTextField
              id="user-first-name"
              variant={'outlined'}
              label="First Name *"
              required
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField
              id="user-last-name"
              variant={'outlined'}
              label="Last Name *"
              required
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField
              id="user-email"
              variant={'outlined'}
              label="Email Address *"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextField
              disabled
              id="user-role"
              variant={'outlined'}
              label="Role"
              required
              value={userDetails.roles?.join(', ') ?? ''}
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="headlineSmall">Change Password</Typography>
        <Box>
          <ButtonBase
            onClick={() => {
              navigate('/request-reset-password');
            }}
            sx={{ color: palette.green[700], textDecoration: 'underline' }}
          >
            Click here to request password reset
          </ButtonBase>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <RedesignAppButton
            appVariant="outlined"
            widePadding={true}
            onClick={() => {
              navigate(-1);
            }}
          >
            Discard
          </RedesignAppButton>
          <RedesignAppButton
            appVariant="filled"
            widePadding={true}
            disabled={isLoading || checkSubmitDisable()}
            startIcon={isLoading ? <AppCircularProgress size={20} /> : null}
            onClick={handleSubmit}
          >
            Save Changes
          </RedesignAppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
