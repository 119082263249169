import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface PageHeaderProps {
  name: string;
  children: any;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ name, children }) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        border: '1px solid',
        borderRadius: '8px',
        padding: '20px 32px',
        backgroundColor: palette.gray.white,
        borderColor: palette.gray[300],
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display={'flex'} gap={'16px'} alignItems="baseline">
          <Typography variant="displaySmall">{name}</Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
};
