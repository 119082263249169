import { InputBase, type Palette, TableCell, Tooltip, styled, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import { type Category, type Column } from './TableComponent';
import ConditionalTooltipWrapper from './ConditionalTooltipWrapper';
import { type Issue } from '../../../utils/types/Issue';

interface CustomStyledTableCellProps {
  isCellDisabled?: (category: Category, column: Column) => boolean;
  column: Column;
  index: number;
  category: Category;
  onClickCell?: (key: string, columnName: string, rowName: string) => void;
  onRightClickCell?: (key: string, columnName: string, rowName: string) => void;
  values: Record<string, number | null>;
  onValueChange: (key: string, newValue: number | null) => void;
  customTextFieldStyle?: (category: Category, column: Column) => React.CSSProperties;
  hasComment: boolean;
  issues: Issue[];
}

const StyledTableCell = styled(TableCell)({
  minWidth: '100px',
});

const getTextFieldSx = (palette: Palette, cellDisabled: boolean, category: Category, column: Column) => {
  if (cellDisabled) {
    return { backgroundColor: palette.gray[300], pointerEvents: 'none' };
  }
  if (category.editable === false || !column.editable) {
    return { backgroundColor: palette.blue[200] };
  }
  return {};
};

const TableCellComponent: React.FC<CustomStyledTableCellProps> = ({
  isCellDisabled,
  column,
  index,
  category,
  onClickCell,
  onRightClickCell,
  values,
  onValueChange,
  customTextFieldStyle,
  hasComment,
  issues,
}) => {
  const { palette } = useTheme();
  const cellDisabled = isCellDisabled != null ? isCellDisabled(category, column) : false;
  const textFieldSx = getTextFieldSx(palette, cellDisabled, category, column);
  const customTextFieldSx = customTextFieldStyle != null ? customTextFieldStyle(category, column) : {};
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <ConditionalTooltipWrapper issues={issues}>
      <StyledTableCell
        onClick={() => {
          !cellDisabled && onClickCell?.(`${column.key}${index + 1}`, column.columnName ?? '', category.rowName ?? '');
          inputRef?.current?.focus();
        }}
        onContextMenu={(event) => {
          if (!cellDisabled) {
            event.preventDefault();
            onRightClickCell?.(`${column.key}${index + 1}`, column.columnName ?? '', category.rowName ?? '');
          }
        }}
        sx={{
          ...textFieldSx,
          ...customTextFieldSx,
          ...(Array.isArray(issues) && issues.length > 0 ? { backgroundColor: palette.red[200] } : {}),
          ...(hasComment
            ? {
                '&::before': {
                  content: '""',
                  width: 0,
                  height: 0,
                  borderRight: '10px solid transparent',
                  borderBottom: '10px solid transparent',
                  borderTop: `10px solid ${palette.yellow[400]}`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              }
            : {}),
          position: 'relative',
        }}
      >
        <Tooltip title={values[`${column.key}${index + 1}`] ?? ''} placement="bottom">
          {category.editable === true && column.editable ? (
            <InputBase
              value={values[`${column.key}${index + 1}`] ?? ''}
              onChange={(event) => {
                const newValue = event.target.value === '' ? null : Number(event.target.value);
                onValueChange(`${column.key}${index + 1}`, newValue);
              }}
              type="number"
              id={`${column.key}${index + 1}`}
              inputProps={{ readOnly: cellDisabled || !column.editable, ref: inputRef }}
              placeholder={cellDisabled ? '' : 'Enter a value'}
              sx={{ width: '100%' }}
            />
          ) : (
            <InputBase
              value={values[`${column.key}${index + 1}`] ?? ''}
              placeholder={cellDisabled ? '' : 'Auto-calculated Value'}
              inputProps={{ readOnly: true }}
            />
          )}
        </Tooltip>
      </StyledTableCell>
    </ConditionalTooltipWrapper>
  );
};

export default TableCellComponent;
