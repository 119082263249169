import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface ResourceWrapperProps {
  title: string;
  subtitle?: string;
  defaultPadding?: boolean;
  children?: React.ReactNode;
}

const ResourcesWrapper: React.FC<ResourceWrapperProps> = ({ title, subtitle, defaultPadding = true, children }) => {
  const { palette } = useTheme();

  return (
    <>
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', mb: '16px' }}>
        <Box
          sx={{
            border: '1px',
            borderRadius: '8px',
            padding: '20px 32px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            backgroundColor: palette.gray.white,
            borderColor: palette.gray[300],
          }}
          border={1}
        >
          <Typography variant="displayMedium" sx={{ color: palette.gray.black, paddingRight: 3 }}>
            {title}
          </Typography>
          {subtitle !== null && subtitle !== undefined ? (
            <Typography variant="labelLarge" color={'textSecondary'}>
              {subtitle}
            </Typography>
          ) : null}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '16px',
          border: '1px',
          borderRadius: '8px',
          gap: '32px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
          padding: defaultPadding ? '24px 32px 40px 24px' : '0',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default ResourcesWrapper;
