import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import BlankKPI from '../../../../../../../../assets/icons/BlankKPI';
const SubmodulesPlaceholder: React.FC = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: palette.gray[200],
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '32px',
        py: 8,
      }}
    >
      <BlankKPI />
      <Typography variant="headlineSmallBold">You have not selected a KPI to view yet.</Typography>
      <Typography variant="bodySmall">After selecting a KPI above you will be able to view its figures.</Typography>
    </Box>
  );
};

export default SubmodulesPlaceholder;
