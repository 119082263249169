import CheckIcon from '@mui/icons-material/Check';
import {
  Accordion,
  AccordionDetails,
  type AccordionSlots,
  AccordionSummary,
  Box,
  Button,
  darken,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContext } from '../../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../utils/hooks/useAppSelector';
import {
  useAddCommentToThreadMutation,
  useUpdateCommentThreadMutation,
} from '../../../../../../../../utils/redux/api';
import {
  updateCommentThread,
  updateDataTableCommentThreads,
} from '../../../../../../../../utils/redux/dataTablesSlice';
import { type CommentThread } from '../../../../../../../../utils/types/CommentThread';
import NewCommentBox from './NewCommentBox';

interface CommentListProps {
  commentThread: CommentThread;
  cellId: string;
  isResolved: boolean;
}

function formatDateString(dateInput: string | number) {
  let parsedDate: Date;

  if (typeof dateInput === 'string') {
    parsedDate = parseISO(dateInput);
  } else if (typeof dateInput === 'number') {
    parsedDate = new Date(dateInput * 1000);
  } else {
    throw new Error('Invalid date input type');
  }

  const formattedDate = format(parsedDate, "d MMM yyyy 'at' H:mm 'UTC'");

  return formattedDate;
}

const CommentCard: React.FC<CommentListProps> = ({ commentThread, cellId, isResolved }) => {
  const { refetchSubmodule } = useWorkspaceDataContext();
  const { palette } = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [addCommentToThread, { isLoading: isAddCommentToThreadLoading }] = useAddCommentToThreadMutation();
  const [resolveCommentThread, { isLoading: isUpdateCommentThreadLoading }] = useUpdateCommentThreadMutation();
  const dataTableId = useAppSelector((state) => state.dataTables.activeDataTable?.dataTableId);
  const dispatch = useDispatch();
  const { handleOpenToast } = useContext(ToastContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNewCommentRequest = async () => {
    if (dataTableId === undefined || isSubmitting) return;
    setIsSubmitting(true);
    try {
      await addCommentToThread({
        commentThreadId: commentThread.comment_thread_id,
        comment_text: newComment,
      }).unwrap();
      dispatch(
        updateDataTableCommentThreads({
          commentThreads: [],
          dataTableId: dataTableId!,
        }),
      );
      setNewComment('');
      await refetchSubmodule();
    } catch (err) {
      handleOpenToast({ message: 'Error saving new comment', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Accordion
      onClick={() => {
        setExpanded((prev) => !prev);
      }}
      disabled={isResolved}
      expanded={expanded}
      slots={{ transition: Fade as AccordionSlots['transition'] }}
      slotProps={{ transition: { timeout: 500 } }}
      sx={{
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        width: '100%',
        borderRadius: '4px',
      }}
    >
      <AccordionSummary sx={{ width: '100%' }}>
        <Box width={'100%'}>
          <Box mb="10px" display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Typography
              sx={{
                border: `1px solid ${palette.green[300]}`,
                borderRadius: '4px',
                backgroundColor: palette.green[100],
                padding: '0 7px 0 7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="labelSmall"
            >
              {cellId.replace(/(\D+)(\d+)/, (_, c, n) => `${c}${+n + 1}`)}
            </Typography>
            <Button
              disabled={isUpdateCommentThreadLoading || !commentThread.is_open}
              onClick={(e) => {
                e.stopPropagation();
                void resolveCommentThread({ commentThreadId: commentThread.comment_thread_id, is_open: false });
                dispatch(updateCommentThread({ commentThread, dataTableId: dataTableId! }));
              }}
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                padding: '2px 5px 2px 5px',
                backgroundColor: palette.green[500],
                '&:hover': {
                  backgroundColor: darken(palette.green[500], 0.2),
                },
              }}
              variant="contained"
              endIcon={<CheckIcon />}
            >
              {commentThread.is_open ? 'Resolve' : 'Resolved'}
            </Button>
          </Box>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Typography variant="labelMediumBold">{commentThread.opened_by}</Typography>
            <Typography variant="labelSmall" sx={{ color: palette.gray[400] }}>
              {formatDateString(commentThread.created_at)}
            </Typography>
            <Typography variant="bodySmall">{commentThread.issue_text}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {Object.entries(commentThread.comments ?? {}).map(([key, value]) => (
          <Box key={key} display={'flex'} flexDirection={'column'} margin={'10px 0 10px 0'}>
            <Typography variant="labelMediumBold">{value.username}</Typography>
            <Typography variant="labelSmall" sx={{ color: palette.gray[400] }}>
              {formatDateString(+key)}
            </Typography>
            <Typography variant="bodySmall">{value.comment}</Typography>
          </Box>
        ))}
        <NewCommentBox
          handleNewCommentRequest={handleNewCommentRequest}
          isLoading={isAddCommentToThreadLoading}
          newComment={newComment}
          setNewComment={setNewComment}
          isSubmitting={isSubmitting}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CommentCard;
