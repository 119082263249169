import { type FlatFileAccessToken } from '../../types/FlatFile';
import { type SubmoduleWithDescription } from '../../types/SubModule';
import { type Workspace } from '../../types/Workspace';
import { rebexApi } from './baseRebexEndpoints';

interface FullReportUploadType {
  workspaceId: number;
  file: File;
}

export const workspacesExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWorkspaces: builder.query<Workspace[], null>({
      query: () => '/workspaces/user',
    }),
    getAllWorkspaces: builder.query<Workspace[], null>({
      query: () => '/workspaces',
    }),
    getWorkspacesWithKpis: builder.query<Workspace[], null>({
      query: () => '/workspaces/workspaces-with-kpis',
    }),
    getWorkspaceById: builder.query<Workspace, number>({
      query: (workspaceId: number) => `/workspaces/${workspaceId}`,
    }),
    addWorkspaces: builder.mutation<Workspace, Partial<Workspace>>({
      query: (workspace: Workspace) => ({ url: '/workspaces', method: 'POST', body: workspace }),
    }),
    updateWorkspace: builder.mutation<Workspace, Partial<Workspace>>({
      query: (workspace: Workspace) => ({
        url: `/workspaces/${workspace.workspace_id}`,
        method: 'PUT',
        body: workspace,
      }),
    }),
    getFlatfileAccessToken: builder.query<FlatFileAccessToken, number>({
      query: (workspaceId: number) => {
        return {
          url: `/workspaces/${workspaceId}/flatfile-access-token`,
          method: 'GET',
        };
      },
    }),
    getWorkspaceSubmodules: builder.query<SubmoduleWithDescription[], number>({
      query: (workspaceId: number) => `/workspaces/${workspaceId}/submodules`,
    }),
    uploadFullReportFile: builder.mutation<FullReportUploadType, Partial<FullReportUploadType>>({
      query: ({ workspaceId, file }) => {
        if (!(file instanceof File)) {
          throw new Error('Invalid file type');
        }

        const formData = new FormData();
        formData.append('report_file', file);
        return {
          url: `/workspaces/${workspaceId}/full-report`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    downloadFullReportFile: builder.query<Blob, { workspaceId: number }>({
      query: ({ workspaceId }) => ({
        url: `/workspaces/${workspaceId}/full-report`,
        method: 'GET',
        responseHandler: async (response) => await response.blob(),
        contentType: 'application/pdf',
      }),
      keepUnusedDataFor: 300, // Keep the cached data for 5 minutes
    }),
    getIsFullReportFileUploaded: builder.query<boolean, number>({
      query: (workspaceId: number) => `/workspaces/${workspaceId}/is-full-report-uploaded`,
    }),
    resendWorkspaceInvite: builder.mutation<null, Partial<Workspace>>({
      query: (workspace: Workspace) => ({ url: '/workspaces/resend-invite', method: 'POST', body: workspace }),
    }),
    archiveWorkspace: builder.mutation<null, Partial<Workspace>>({
      query: (workspace: Workspace) => ({ url: '/workspaces/archive', method: 'PATCH', body: workspace }),
    }),
    unarchiveWorkspace: builder.mutation<null, Partial<Workspace>>({
      query: (workspace: Workspace) => ({ url: '/workspaces/unarchive', method: 'PATCH', body: workspace }),
    }),
    deleteWorkspace: builder.mutation<null, number>({
      query: (id: number) => ({
        url: `/workspaces/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    getAllArchivedWorkspaces: builder.query<Workspace[], null>({
      query: () => '/workspaces/archived',
    }),
  }),
});
