import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  Checkbox,
  FormControlLabel,
  Grid,
  ListSubheader,
  Popper,
  Radio,
  RadioGroup,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { createContext, forwardRef, useContext, useMemo, useState } from 'react';
import { VariableSizeList, type ListChildComponentProps } from 'react-window';

import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../context/toastContext';
import currencies, { type Currency } from '../../../../../utils/currencies';
import { useAppSelector } from '../../../../../utils/hooks/useAppSelector';
import { useGetDatapointsQuery, useGetKPIsQuery } from '../../../../../utils/redux/api';
import { getToken } from '../../../../../utils/redux/authSlice';
import { type PeerGroup } from '../../../../../utils/types/PeerGroup';
import { type DatapointOption, type KPIOption } from '../../../../../utils/types/Reports';
import ModalWrapper from './ModalWrapper';

interface Props {
  record?: PeerGroup | null;
  open: boolean;
  handleClose: () => void;
}

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    whiteSpace: 'nowrap',
    width: '100%',
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const { key, ...optionProps } = dataSet[0];
  return (
    <li {...optionProps} key={key} style={inlineStyle}>
      <Checkbox
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        checkedIcon={<CheckBox fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={dataSet[2].selected}
      />
      {`${dataSet[1].kpi_name !== undefined ? dataSet[1].kpi_name : dataSet[1].datapoint_name}`}
    </li>
  );
};

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: Array<React.ReactElement<any>> = [];
    (children as Array<React.ReactElement<any>>).forEach(
      (item: React.ReactElement<any> & { children?: Array<React.ReactElement<any>> }) => {
        itemData.push(item);
        itemData.push(...(item.children ?? []));
      },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactElement<any>) => {
      if (Object.prototype.hasOwnProperty.call(child, 'group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="600px"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: any) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
            style={{ overflowX: 'hidden' }}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const DownloadModal: React.FC<Props> = ({ record, open, handleClose }) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);
  const token = useAppSelector((state) => getToken(state));
  let endpoint: 'client-output' | 'outlier-analysis' | null = null;
  let fileName: 'bank_output' | 'outlier_analysis' | null = null;

  const { data: kpis } = useGetKPIsQuery(null);
  const kpiOptions: KPIOption[] = useMemo(
    () => (kpis !== undefined ? [{ kpi_mapping_id: 0, kpi_name: 'Select all KPIs', module: 'All' }, ...kpis] : []),
    [kpis],
  );
  const { data: datapoints } = useGetDatapointsQuery(null);
  const datapointOptions: DatapointOption[] = useMemo(
    () =>
      datapoints !== undefined
        ? [
            { datapoint_id: 0, datapoint_name: 'Select all datapoints', module_name: 'All', submodule_name: '' },
            ...datapoints,
          ]
        : [],
    [datapoints],
  );

  const [reportType, setReportType] = useState<string | null>(null);
  const handleRadioSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportType((event.target as HTMLInputElement).value);
  };

  const [selectedKPIs, setSelectedKPIs] = useState<KPIOption[]>([]);
  const [selectedDatapoints, setSelectedDatapoints] = useState<DatapointOption[]>([]);
  const [currency, setCurrency] = useState<Currency | null>(null);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_REBEX_API_HOST}/reports/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        peer_group_id: Number(record!.id),
        kpis: selectedKPIs.filter((kpi) => kpi.kpi_mapping_id !== 0).map((kpi) => kpi.kpi_mapping_id),
        data_points: selectedDatapoints.filter((dp) => dp.datapoint_id !== 0).map((dp) => dp.datapoint_id),
        currency: currency!.alphaCode,
      }),
    });

    if (response.body !== null && response.body !== undefined) {
      const reader = response.body.getReader();
      const chunks = [];
      let done = false;

      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        if (value !== null && value !== undefined) {
          chunks.push(value);
        }
      }

      const blob = new Blob(chunks.filter(Boolean), {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      return url;
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    switch (reportType) {
      case 'output':
        endpoint = 'client-output';
        fileName = 'bank_output';
        break;
      case 'outlier':
        endpoint = 'outlier-analysis';
        fileName = 'outlier_analysis';
        break;
    }

    fetchData()
      .then((res) => {
        setLoading(false);

        if (res !== undefined) {
          const link = document.createElement('a');
          link.href = res;
          link.download = `${fileName}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          handleOpenToast({ message: 'File downloaded successfully', severity: 'success' });
          handleReset();
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        handleOpenToast({ message: 'Unable to download file', severity: 'success' });
        console.error(err);
      });
  };

  const handleReset = () => {
    setReportType(null);
    setSelectedKPIs([]);
    setSelectedDatapoints([]);
    setCurrency(null);
  };

  const handleCancel = () => {
    handleReset();
    handleClose();
  };

  const StyledRadio = styled(Radio)(() => ({
    color: palette.green[500],
    '&.Mui-checked': {
      color: palette.green[500],
    },
  }));

  const widePopper = function (props: any) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title="Download Group Files"
      wide={true}
      buttons={[
        { label: 'Cancel', action: handleCancel, variant: 'outlined' },
        {
          label: 'Download',
          action: handleSubmit,
          variant: 'filled',
          disabled: reportType === null || selectedKPIs.length === 0 || currency === null,
        },
      ]}
    >
      <Grid item xs={12}>
        <RadioGroup row name="peer-group-report-type" value={reportType} onChange={handleRadioSelection}>
          <FormControlLabel value="output" control={<StyledRadio />} label="Client Output File" />
          <FormControlLabel value="outlier" control={<StyledRadio />} label="Completed Outlier Analysis File" />
          <FormControlLabel value="ppt" control={<StyledRadio />} label="Generate PPT" disabled />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          multiple
          disablePortal
          disableCloseOnSelect
          disableListWrap
          value={selectedKPIs}
          options={kpiOptions}
          getOptionLabel={(option) => option.kpi_name}
          getOptionKey={(option) => option.kpi_mapping_id}
          groupBy={(option) => option.module.toUpperCase()}
          renderInput={(params) => <TextField {...params} label="Select KPIs *" />}
          renderOption={(props, option, state) => [props, option, state] as React.ReactNode}
          renderGroup={(params) => params as any}
          renderTags={(tagValue) => `${tagValue.length} selected`}
          PopperComponent={widePopper}
          ListboxComponent={ListboxComponent}
          onChange={(event, selected, reason) => {
            if (event.type === 'click' || (event.type === 'keydown' && reason !== 'removeOption')) {
              // select all
              if (selected.find((sel) => sel.kpi_mapping_id === 0) !== undefined) {
                setSelectedKPIs(kpiOptions);
              } else {
                // deselect all
                if (selectedKPIs.find((sel) => sel.kpi_mapping_id === 0) !== undefined) {
                  setSelectedKPIs([]);
                } else {
                  // normal selection
                  setSelectedKPIs(selected);
                }
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          multiple
          disablePortal
          disableCloseOnSelect
          disableListWrap
          value={selectedDatapoints}
          options={datapointOptions}
          getOptionLabel={(option) => option.datapoint_name}
          groupBy={(option) => option.module_name.toUpperCase()}
          renderInput={(params) => <TextField {...params} label="Select Datapoints" />}
          renderOption={(props, option, state) => [props, option, state] as React.ReactNode}
          renderGroup={(params) => params as any}
          renderTags={(tagValue) => `${tagValue.length} selected`}
          PopperComponent={widePopper}
          ListboxComponent={ListboxComponent}
          onChange={(event, selected, reason: any) => {
            if (event.type === 'click' || (event.type === 'keydown' && reason !== 'removeOption')) {
              // select all
              if (selected.find((sel) => sel.datapoint_id === 0) !== undefined) {
                setSelectedDatapoints(datapointOptions);
              } else {
                // deselect all
                if (selectedDatapoints.find((sel) => sel.datapoint_id === 0) !== undefined) {
                  setSelectedDatapoints([]);
                } else {
                  // normal selection
                  setSelectedDatapoints(selected);
                }
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          disablePortal
          value={currency}
          options={currencies}
          getOptionLabel={(option) => option.alphaCode}
          getOptionKey={(option) => option.code}
          onChange={(e, selected) => {
            setCurrency(selected);
          }}
          renderInput={(params) => <TextField {...params} label="Select Currency *" />}
        />
      </Grid>
      {loading ? (
        <Backdrop open sx={{ zIndex: 1000 }}>
          <AppCircularProgress />
        </Backdrop>
      ) : null}
    </ModalWrapper>
  );
};

export default DownloadModal;
