import { AddCircle } from '@mui/icons-material';
import { Backdrop, Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AppButton from '../../../../components/materials/actions/AppButton';
import AppCircularProgress from '../../../../components/materials/loading/AppCircularProgress';
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import {
  useGetAllArchivedWorkspacesQuery,
  useGetAllWorkspacesQuery,
  useGetUserQuery,
  useGetUserWorkspacesQuery,
} from '../../../../utils/redux/api';
import { selectUserRoles, setUserDetails } from '../../../../utils/redux/authSlice';
import { type Workspace } from '../../../../utils/types/Workspace';
import DynamicTabContent from './components/DynamicTabContent';
import FilterWorkspace from './components/FilterWorkspace';

const AdminWorkspace = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const email = useAppSelector((state) => state?.auth?.userDetails?.email?.toLowerCase() ?? '');
  const [tabSelected, setTabSelected] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [banks, setBanks] = useState<string[]>([]);
  const [workspaceLength, setWorkspaceLength] = useState<number | undefined>(0);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [filteredData, setFilteredData] = useState<Workspace[] | undefined>([]);
  const [sort, setSort] = useState('name');
  const shouldRefetch = location.state?.refetch ?? false;
  const [dataUpdated, setDataUpdated] = useState(false);

  const { data: userDetails, isLoading: userLoading } = useGetUserQuery(email, {
    skip: !shouldFetch,
  });

  const {
    data: userWorkspaceData,
    isLoading: userWorkspacesLoading,
    refetch: userWorkspaceRefetch,
  } = useGetUserWorkspacesQuery(null);

  const {
    data: archivedWorkspaces,
    isLoading: archivedWorkspacesLoading,
    refetch: archivedWorkspacesRefetch,
  } = useGetAllArchivedWorkspacesQuery(null);

  const {
    data: allWorkspaces,
    isLoading: allWorkspacesLoading,
    refetch: allWorkspacesRefetch,
  } = useGetAllWorkspacesQuery(null);

  useEffect(() => {
    if (email?.length > 0 && !shouldFetch) {
      setShouldFetch(true);
    } else if (email?.length === 0) {
      navigate('/');
    }
    if (!userLoading && userDetails != null) {
      dispatch(setUserDetails(userDetails));
    }
  }, [email, shouldFetch, navigate, userDetails, userLoading, dispatch, allWorkspaces, userWorkspaceData]);

  useEffect(() => {
    if (shouldRefetch === true || dataUpdated) {
      void userWorkspaceRefetch();
      void allWorkspacesRefetch();
      void archivedWorkspacesRefetch();
      setDataUpdated(false);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [
    shouldRefetch,
    userWorkspaceRefetch,
    allWorkspacesRefetch,
    navigate,
    location.pathname,
    archivedWorkspacesRefetch,
    dataUpdated,
  ]);

  useEffect(() => {
    const workspaceData =
      tabSelected === 0 ? userWorkspaceData : tabSelected === 1 ? allWorkspaces : archivedWorkspaces;

    if (workspaceData == null) {
      setFilteredData([]);
      return;
    }

    setCountries(Array.from(new Set(workspaceData?.map((workspace) => workspace.country))));
    setBanks(Array.from(new Set(workspaceData?.map((workspace) => workspace.workspace_name ?? ''))));

    const filterData = workspaceData?.filter((workspace) => {
      const countryFilter = selectedCountry === '' || workspace.country === selectedCountry;
      const bankFilter = selectedBank === '' || workspace.workspace_name === selectedBank;
      return countryFilter && bankFilter;
    });

    switch (sort) {
      case 'name':
        filterData.sort((a, b) => a.workspace_name?.localeCompare(b.workspace_name ?? '') ?? 0);
        break;
      case 'issues':
        filterData.sort((a, b) => (b?.issues ?? 0) - (a?.issues ?? 0));
        break;
      default:
        break;
    }

    setFilteredData(filterData);
    setWorkspaceLength(filterData?.length);
  }, [tabSelected, userWorkspaceData, allWorkspaces, selectedBank, selectedCountry, sort, archivedWorkspaces]);

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setTabSelected(tabIndex);
  };

  return (
    <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', mb: '16px' }}>
      <Box
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          padding: userRoles.includes('admin') ? '20px 32px 0px 32px' : '20px 32px',
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
        }}
        border={1}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="displaySmall">Workspaces</Typography>

          {userRoles.includes('admin') && (
            <AppButton
              startIcon={<AddCircle />}
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: palette.green[500],
                borderColor: palette.green[500],
                backgroundColor: 'transparent',
                borderRadius: '8px',
                padding: '0 14px',
                '&:hover, &:active, &:focus': {
                  backgroundColor: palette.green[500],
                  color: palette.gray.white,
                },
              }}
              onClick={() => {
                navigate('/app/workspaces/create-new-workspace');
              }}
            >
              <Typography variant="buttonMedium">New Workspace</Typography>
            </AppButton>
          )}
        </Box>

        {userRoles.includes('admin') && (
          <Tabs
            value={tabSelected ?? false}
            variant="standard"
            onChange={handleTabChange}
            sx={{
              '& .MuiTabs-indicator': { backgroundColor: palette.green[500] },
            }}
          >
            <Tab
              disableRipple
              label={
                <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                  {'My Workspaces'}
                </Typography>
              }
              sx={{
                textTransform: 'none',
                cursor: 'default',
                color: palette.gray[500],
                '&:hover': {
                  backgroundColor: palette.green[100],
                },
                '&.Mui-selected': {
                  color: palette.green[500],
                  backgroundColor: palette.green[100],
                },
              }}
            />
            <Tab
              disableRipple
              label={
                <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                  {'All Workspaces'}
                </Typography>
              }
              sx={{
                textTransform: 'none',
                cursor: 'default',
                color: palette.gray[500],
                '&:hover': {
                  backgroundColor: palette.green[100],
                },
                '&.Mui-selected': {
                  color: palette.green[500],
                  backgroundColor: palette.green[100],
                },
              }}
            />
            <Tab
              disableRipple
              label={
                <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                  {'Archived Workspaces'}
                </Typography>
              }
              sx={{
                textTransform: 'none',
                cursor: 'default',
                color: palette.gray[500],
                '&:hover': {
                  backgroundColor: palette.green[100],
                },
                '&.Mui-selected': {
                  color: palette.green[500],
                  backgroundColor: palette.green[100],
                },
              }}
            />
          </Tabs>
        )}
      </Box>

      {userLoading || userWorkspacesLoading || allWorkspacesLoading || archivedWorkspacesLoading ? (
        <Backdrop open>
          <AppCircularProgress />
        </Backdrop>
      ) : (
        <Box
          sx={{
            border: '1px solid',
            borderRadius: '8px',
            padding: '24px 32px',
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray.white,
            borderColor: palette.gray[300],
          }}
          border={1}
        >
          <FilterWorkspace
            workspaceLength={workspaceLength}
            countries={countries}
            banks={banks}
            onSortByChange={setSort}
            onCountryChange={(event, value) => {
              setSelectedCountry(value?.length > 0 ? value : '');
            }}
            onBankChange={(event, value) => {
              setSelectedBank(value?.length > 0 ? value : '');
            }}
          />
          {filteredData !== undefined ? (
            <DynamicTabContent data={filteredData} archivedTab={tabSelected === 2} setDataUpdated={setDataUpdated} />
          ) : (
            <AppCircularProgress />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AdminWorkspace;
