export const initialValues = (columns: string[], count: number, totalColumns?: string[]) => {
  const values: Record<string, number | null> = {};
  columns.forEach((column) => {
    for (let i = 1; i <= count; i++) {
      if (totalColumns != null && totalColumns?.includes(`${column}${i}`)) {
        values[`${column}${i}`] = 0;
      } else {
        values[`${column}${i}`] = null;
      }
    }
  });
  return values;
};

export const initialValuesFromResponse = (
  data: Array<Array<number | null>>,
  columns: string[],
  totalColumns?: string[],
): Record<string, number | null> => {
  const result: Record<string, number | null> = {};

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      const key = `${columns[j]}${i + 1}`;
      if (totalColumns != null && totalColumns?.includes(key)) {
        result[key] = data[i][j] ?? 0;
      } else {
        result[key] = data[i][j];
      }
    }
  }

  return result;
};
