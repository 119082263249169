import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type User } from '../types/User';

interface AuthState {
  accessToken: string;
  userDetails: User;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  accessToken: '',
  userDetails: {},
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<{ accessToken: string; email: string }>) => {
      state.accessToken = action.payload.accessToken;
      state.userDetails = { ...state.userDetails, email: action.payload.email };
      state.isAuthenticated = true;
    },
    clearAuth: (state) => {
      state.accessToken = '';
      state.userDetails = {};
      state.isAuthenticated = false;
    },
    setUserDetails: (state, action: PayloadAction<User>) => {
      state.userDetails = action.payload;
    },
  },
  selectors: {
    selectIsUserAdmin: createSelector(
      (state: AuthState) => state.userDetails.roles,
      (roles) => roles?.includes('admin') ?? false,
    ),
    selectUserRoles: createSelector(
      (state: AuthState) => state.userDetails.roles,
      (roles) => roles ?? [],
    ),
    getToken: createSelector(
      (state: AuthState) => state.accessToken,
      (accessToken) => accessToken,
    ),
  },
});

export const { setAuth, clearAuth, setUserDetails } = authSlice.actions;
export const { selectIsUserAdmin, selectUserRoles, getToken } = authSlice.selectors;
export default authSlice.reducer;
