import { rebexApi } from './baseRebexEndpoints';

export const modulesEndpoints = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadFullPpt: builder.query<Blob, { moduleId: number; workspaceId: number }>({
      query: ({ moduleId, workspaceId }) => ({
        url: `/modules/workspaces/${workspaceId}/modules/${moduleId}/download-full-ppt`,
        method: 'GET',
        headers: { ContentType: 'application/octet-stream' },
        responseHandler: async (response) => await response.blob(),
      }),
      keepUnusedDataFor: 300, // Keep the cached data for 5 minutes
    }),
  }),
});
