import { DragDropContext, Draggable, Droppable, type DropResult } from '@hello-pangea/dnd';
import { DeleteOutline, ReorderOutlined } from '@mui/icons-material';
import { Box, IconButton, List, ListItem, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { deleteResourceById, selectResourcesByResourceType, setResources } from '../../../utils/redux/keyThemeSlice';
import { type KeyThemeResource, type KeyThemeResourceType } from '../../../utils/types/KeyTheme';

interface Props {
  resourceType: KeyThemeResourceType;
}

const reorder = (list: KeyThemeResource[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ResourcesList = ({ resourceType }: Props) => {
  const resources = useAppSelector((state) => selectResourcesByResourceType(state, resourceType));
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const onDragEnd = (result: DropResult) => {
    if (result.destination === null || result.destination === undefined) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedResources = reorder(resources, result.source.index, result.destination.index);
    dispatch(setResources({ resourceType, resources: reorderedResources }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {resources?.map((resource, index) => (
              <Draggable key={resource.id} draggableId={resource.id.toString()} index={index}>
                {(provided) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{ padding: '4px 16px 4px 16px' }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                      px={2}
                      sx={{ borderRadius: '2px', bgcolor: palette.gray[200] }}
                    >
                      {resource.file_name}
                      <Box display="flex" gap={1}>
                        <IconButton
                          onClick={() => dispatch(deleteResourceById({ resourceType, resourceId: resource.id }))}
                          sx={{ padding: 0 }}
                        >
                          <DeleteOutline sx={{ color: palette.green[500] }} />
                        </IconButton>
                        <IconButton sx={{ padding: 0 }}>
                          <ReorderOutlined sx={{ color: palette.green[500] }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ResourcesList;
