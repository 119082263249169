import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { ProductSalesTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const PhysicalChannels: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, ProductSalesTables.PhysicalChannels),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse,
    isLoading: isLoadingDataTable,
    refetch,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: ProductSalesTables.PhysicalChannels,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Deposits', editable: false, indentLevel: 1 },
    { label: 'Checking accounts', editable: true, indentLevel: 2 },
    { label: 'Savings accounts', editable: true, indentLevel: 2 },
    { label: 'of which money market', editable: true, indentLevel: 3 },
    { label: 'Term deposits', editable: true, indentLevel: 1 },
    { label: 'Credit', editable: false, indentLevel: 2 },
    { label: 'Overdrafts', editable: true, indentLevel: 2 },
    { label: 'Credit cards', editable: true, indentLevel: 2 },
    { label: 'Unsecured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Secured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Mortgages total', editable: true, indentLevel: 2 },
    { label: 'of which new mortgages', editable: true, indentLevel: 3 },
    { label: 'of which mortage refinances', editable: true, indentLevel: 3 },
    { label: 'Home equity', editable: true, indentLevel: 2 },
    { label: 'Investments', editable: true, indentLevel: 1 },
    { label: 'Insurance', editable: true, indentLevel: 1 },
    { label: 'Other', editable: true, indentLevel: 1 },
    { label: 'Total', editable: false, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Third party', editable: true },
    { key: 'B', label: 'Non-branch', editable: true },
    { key: 'C', label: 'ATM', editable: true },
    { key: 'D', label: 'Branch', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: ProductSalesTables.PhysicalChannels,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D'],
          rowCount: 18,
          totalColumns: ['A1', 'B1', 'C1', 'D1', 'A6', 'B6', 'C6', 'D6', 'A18', 'B18', 'C18', 'D18'],
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    refetch().catch((err) => {
      console.error(err);
    });
  }, [fetchFlag, refetch]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A5'], values));
    const b1 = formatNumber(sumValues(['B2', 'B3', 'B5'], values));
    const c1 = formatNumber(sumValues(['C2', 'C3', 'C5'], values));
    const d1 = formatNumber(sumValues(['D2', 'D3', 'D5'], values));
    const a6 = formatNumber(sumValues(['A7', 'A8', 'A9', 'A10', 'A11', 'A14'], values));
    const b6 = formatNumber(sumValues(['B7', 'B8', 'B9', 'B10', 'B11', 'B14'], values));
    const c6 = formatNumber(sumValues(['C7', 'C8', 'C9', 'C10', 'C11', 'C14'], values));
    const d6 = formatNumber(sumValues(['D7', 'D8', 'D9', 'D10', 'D11', 'D14'], values));
    const a18 = formatNumber(a1 + a6 + sumValues(['A15', 'A16', 'A17'], values));
    const b18 = formatNumber(b1 + b6 + sumValues(['B15', 'B16', 'B17'], values));
    const c18 = formatNumber(c1 + c6 + sumValues(['C15', 'C16', 'C17'], values));
    const d18 = formatNumber(d1 + d6 + sumValues(['D15', 'D16', 'D17'], values));

    return {
      ...values,
      A1: a1,
      B1: b1,
      C1: c1,
      D1: d1,
      A6: a6,
      B6: b6,
      C6: c6,
      D6: d6,
      A18: a18,
      B18: b18,
      C18: c18,
      D18: d18,
    };
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: { tabKey, table: ProductSalesTables.PhysicalChannels, values: updatedValues },
        columns: ['A', 'B', 'C', 'D'],
        rowCount: 18,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getRowStyle={(category) => {
            if (category.label === 'Total') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default PhysicalChannels;
