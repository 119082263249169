import React, { useState } from 'react';
import { MenuItem, FormControl, Select, InputLabel, type SelectChangeEvent, useTheme } from '@mui/material';

interface SortWorkspaceProps {
  onSortByChange: (value: string) => void;
}

const width = '240px';

const SortWorkspace: React.FC<SortWorkspaceProps> = ({ onSortByChange }) => {
  const { palette } = useTheme();
  const [sortByValue, setSortByValue] = useState('name');

  const handleSortByChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setSortByValue(newValue);
    onSortByChange(newValue);
  };

  const MenuItemStyling = {
    '&:hover': { backgroundColor: palette.green[300] },
    '&.Mui-focusVisible': { backgroundColor: 'inherit' },
    '&.Mui-selected': { backgroundColor: `${palette.green[300]} !important` },
    '&.MuiMenuItem-root:hover': { backgroundColor: palette.green[300] },
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
  };

  return (
    <FormControl sx={{ minWidth: width }}>
      <InputLabel>Sort By</InputLabel>
      <Select
        id="sort-by-select"
        value={sortByValue}
        label="Sort By"
        onChange={handleSortByChange}
        sx={{
          width,
          '& .MuiInputBase-input': {
            typography: 'bodyLarge',
          },
          '& .MuiSelect-select': {
            typography: 'bodyLarge',
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              width,
            },
          },
        }}
      >
        <MenuItem value="name" sx={MenuItemStyling}>
          Name (Ascending)
        </MenuItem>
        <MenuItem value="issues" sx={MenuItemStyling}>
          Outstanding Issues (Descending)
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SortWorkspace;
