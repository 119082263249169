import { Autocomplete, CircularProgress, Grid, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import ModalWrapper from '../../PeerGroups/components/ModalWrapper';
import { UserRoles } from '../../../../../utils/types/User';

export interface CreateUserDetails {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  role: string | undefined;
}

interface CreateUserModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (user: CreateUserDetails) => Promise<void> | void;
  isLoading?: boolean;
}

export const CreateUserModal: React.FC<CreateUserModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
  onSave,
  isLoading,
}) => {
  const handleSave = async () => {
    await onSave(user);
    // Cleanning the modal
    setUser({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      role: undefined,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const buttons: Array<{
    label: string | JSX.Element;
    action: () => void | Promise<void>;
    variant: 'filled' | 'outlined';
    disabled?: boolean;
  }> = [
    {
      label: 'Cancel',
      action: handleClose,
      variant: 'outlined',
      disabled: isLoading,
    },
    {
      label: isLoading === true ? <CircularProgress size={20} color="inherit" /> : 'Add New User',
      action: handleSave,
      variant: 'filled',
    },
  ];

  const { palette } = useTheme();
  const [user, setUser] = useState<CreateUserDetails>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    role: undefined,
  });

  return (
    <ModalWrapper open={isOpen} handleClose={onClose} title={title} summary={text} buttons={buttons}>
      <Grid item xs={12} md={6}>
        <TextField
          label={<div style={{ color: palette.gray[500] }}>{"Enter User's First Name"}</div>}
          variant="outlined"
          fullWidth={true}
          value={user?.firstName}
          onChange={(e) => {
            setUser((prev) => ({ ...prev, firstName: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={<div style={{ color: palette.gray[500] }}>{"Enter User's Last Name"}</div>}
          variant="outlined"
          fullWidth={true}
          value={user.lastName}
          onChange={(e) => {
            setUser((prev) => ({ ...prev, lastName: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={<div style={{ color: palette.gray[500] }}>Enter Email Address</div>}
          variant="outlined"
          fullWidth={true}
          value={user?.email}
          onChange={(e) => {
            setUser((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          value={user?.role}
          onChange={(e, selected) => {
            setUser((prev) => ({ ...prev, role: selected ?? prev.role }));
          }}
          options={[UserRoles.ADMIN]}
          getOptionLabel={(option) => {
            switch (option) {
              case UserRoles.ADMIN:
                return 'Admin';
              case UserRoles.DATA_LEAD:
                return 'Data Lead';
              case UserRoles.DATA_COLLECTOR:
                return 'Data Collector';
              default:
                return '';
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={<div style={{ color: palette.gray[500] }}>Select User Role</div>} />
          )}
        />
      </Grid>
    </ModalWrapper>
  );
};
