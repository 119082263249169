import { AddCircle, Edit, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';

import AppChip from '../../../../../../../components/materials/alert/AppChip';
import AppCircularProgress from '../../../../../../../components/materials/loading/AppCircularProgress';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { formatDate } from '../../../../../../../utils/dateUtils';
import { SubmodulesEnum } from '../../../../../../../utils/types/SubmodulesEnum';
import DataCollectorModal from './DataCollectorModal';
import DataTableRedesign from './DataTableRedesign';
import UploadFlatFile from './Flatfile/UploadFlatFile';

const submodulesWithoutFlatfileUpload = [
  SubmodulesEnum.OnlineAndMobileCustomers,
  SubmodulesEnum.BranchAndATMCustomers,
  SubmodulesEnum.ContactCenter,
];

const DataDetails: React.FC = () => {
  const { activeSubmodule, isLoading } = useWorkspaceDataContext();
  const [isDataCollectorModalOpen, setIsDataCollectorModalOpen] = useState(false);
  const assignedUsers = activeSubmodule !== undefined ? activeSubmodule.assigned_users : [];

  const assignedUsersNames =
    assignedUsers.length > 0
      ? assignedUsers.map((user) => `${user.first_name} ${user.last_name}`).join('; ')
      : 'Assign Collector';

  if (isLoading.submodule) {
    return <AppCircularProgress sx={{ padding: 4 }} />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} px={3} py={2}>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="headlineSmall">{activeSubmodule?.name}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box display="flex" flexDirection="column" py={1} px={2} gap={2}>
            <Box display="flex" gap={10}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="titleMediumBold" sx={{ color: ({ palette }) => palette.gray[500] }}>
                  Data Collector
                </Typography>
                <ButtonBase
                  onClick={() => {
                    setIsDataCollectorModalOpen(true);
                  }}
                  sx={{ px: 2, color: ({ palette }) => palette.green[500], fontFamily: 'inherit' }}
                >
                  <Typography
                    title={assignedUsersNames}
                    variant="buttonMedium"
                    sx={{ maxWidth: '250px', textOverflow: 'ellipsis', textWrap: 'nowrap', overflow: 'hidden' }}
                  >
                    {assignedUsers.length > 0 ? assignedUsersNames : 'Assign Collector'}
                  </Typography>
                  {assignedUsers.length > 0 ? (
                    <AddCircle sx={{ marginLeft: '8px', fontSize: '20px' }} />
                  ) : (
                    <Edit sx={{ marginLeft: '8px', fontSize: '20px' }} />
                  )}
                </ButtonBase>
              </Box>

              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="titleMediumBold" sx={{ color: ({ palette }) => palette.gray[500] }}>
                  Status
                </Typography>
                <AppChip label={activeSubmodule?.status} type="success" />
              </Box>

              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="titleMediumBold" sx={{ color: (theme) => theme.palette.gray[500] }}>
                  Due
                </Typography>
                <Typography variant="bodyLarge" sx={{ color: (theme) => theme.palette.gray[500] }}>
                  {formatDate(activeSubmodule?.end_timestamp ?? '')}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="titleMediumBold" sx={{ color: (theme) => theme.palette.gray[500] }}>
                Description
              </Typography>
              <Typography variant="bodyLarge">{activeSubmodule?.description}</Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Divider />

      {!submodulesWithoutFlatfileUpload.some((submodule) => submodule === activeSubmodule?.name) && <UploadFlatFile />}

      <DataTableRedesign />

      <DataCollectorModal
        open={isDataCollectorModalOpen}
        handleClose={() => {
          setIsDataCollectorModalOpen(false);
        }}
      />
    </Box>
  );
};

export default DataDetails;
