import { createSlice } from '@reduxjs/toolkit';

const initialStateFlags = {
  refetch: true,
};

const flagsSlice = createSlice({
  name: 'flags',
  initialState: initialStateFlags,
  reducers: {
    toggleFetchFlag: (state) => {
      state.refetch = !state.refetch;
    },
  },
});

export const { toggleFetchFlag } = flagsSlice.actions;
export const flagReducer = flagsSlice.reducer;
