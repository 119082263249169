import { type FlatfileRecord } from '@flatfile/hooks';
import moment from 'moment';
import { momentFormats } from '../../../../utils/dateFormats';
import { RevenueWorkbook, OpexWorkbook, EmployeeWorkbook, ProductWorkbook } from '../configs/workbook';

const combinedWorkbook = [
  { ...RevenueWorkbook },
  { ...OpexWorkbook },
  { ...EmployeeWorkbook },
  { ...ProductWorkbook },
];

const formatDate = (dateString: string) => {
  if (dateString.length === 10 && Boolean(moment(dateString, 'YYYY-MM-DD', true).isValid())) {
    return dateString;
  }

  if (dateString.length === 8 && Boolean(moment(dateString, 'MMDDYYYY', true).isValid())) {
    return moment(dateString, 'MMDDYYYY').format('YYYY-MM-DD');
  }

  for (const format of momentFormats) {
    const momentDate = moment(dateString, format, true);
    if (momentDate !== null && Boolean(momentDate.isValid())) {
      return momentDate.format('YYYY-MM-DD');
    }
  }
  return 'Invalid Date';
};

const validateFields = (record: FlatfileRecord, name: string) => {
  const workbook = combinedWorkbook.find((wb) => wb.name === name);
  const stringRegex = /^-?\d+$|^[^\d,.\s].*$/;
  const numberRegex = /^-?\d+(,\d{3})*(\.\d+)?$/;

  const numberFields =
    workbook?.sheets?.flatMap((s) => s.fields.filter((f) => f.type === 'number')).map((field) => field.key) ?? [];

  const stringFields =
    workbook?.sheets?.flatMap((s) => s.fields.filter((f) => f.type === 'string')).map((field) => field.key) ?? [];

  const dateFields =
    workbook?.sheets?.flatMap((s) => s.fields.filter((f) => f.type === 'date')).map((field) => field.key) ?? [];

  for (const stringField of stringFields) {
    record.validate(
      stringField,
      (value) => {
        return value !== null && stringRegex.test(String(value).trim());
      },
      'This field must be a string',
    );
  }

  for (const numberField of numberFields) {
    record.validate(
      numberField,
      (value) => {
        return value !== null && numberRegex.test(String(value).trim());
      },
      'This field must be a number',
    );
  }

  for (const dateField of dateFields) {
    const inputDate = record.get(dateField);

    if (typeof inputDate === 'string' && inputDate.trim().length > 0) {
      const formattedDate = formatDate(inputDate.trim());

      if (formattedDate === 'Invalid Date') {
        record.addError(dateField, 'Please check that the date is in yyyy-MM-dd format.');
      } else if (formattedDate !== inputDate.trim()) {
        record.set(dateField, formattedDate);
        record.addComment(dateField, 'Date has been formatted as yyyy-MM-dd');
      }
    } else if (typeof inputDate === 'string' && inputDate.trim().length === 0) {
      record.addError(dateField, 'This field cannot be empty');
    }
  }
};

export default validateFields;
