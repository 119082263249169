import { type Flatfile } from '@flatfile/api';

const RevenueWorkbook: Flatfile.CreateWorkbookConfig = {
  name: 'Revenue Data',
  sheets: [
    {
      name: 'Revenue Data',
      slug: 'revenue-data',
      fields: [
        {
          key: 'costCenterDivision',
          type: 'string',
          label: 'Cost Center Division',
          description: 'Revenue by type, cost centers, general ledger cost type',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'costCenterSubDivision1',
          type: 'string',
          label: 'Cost Center Sub-Division1',
          description: '',
        },
        {
          key: 'costCenterSubDivision2',
          type: 'string',
          label: 'Cost Center Sub-Division2',
          description: '',
        },
        {
          key: 'glCostType1',
          type: 'string',
          label: 'GL Cost Type 1',
          description: '',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'glCostType2',
          type: 'string',
          label: 'GL Cost Type 2',
          description: '',
        },
        {
          key: 'glCostType3',
          type: 'string',
          label: 'GL Cost Type 3',
          description: '',
        },
        {
          key: 'glCostType4',
          type: 'string',
          label: 'GL Cost Type 4',
          description: '',
        },
        {
          key: 'amount',
          type: 'number',
          label: 'Amount',
          description: '',
          constraints: [{ type: 'required' }],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit Data',
      primary: true,
    },
  ],
};

const OpexWorkbook: Flatfile.CreateWorkbookConfig = {
  name: 'OPEX Data',
  sheets: [
    {
      name: 'OPEX Data',
      slug: 'opex-data',
      fields: [
        {
          key: 'costCenterDivision',
          type: 'string',
          label: 'Cost Center Division',
          description: 'Costs by type, cost centers, general ledger cost type',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'costCenterSubDivision1',
          type: 'string',
          label: 'Cost Center Sub-Division1',
          description: '',
        },
        {
          key: 'costCenterSubDivision2',
          type: 'string',
          label: 'Cost Center Sub-Division2',
          description: '',
        },
        {
          key: 'glCostType1',
          type: 'string',
          label: 'GL Cost Type 1',
          description: '',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'glCostType2',
          type: 'string',
          label: 'GL Cost Type 2',
          description: '',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'glCostType3',
          type: 'string',
          label: 'GL Cost Type 3',
          description: '',
        },
        {
          key: 'glCostType4',
          type: 'string',
          label: 'GL Cost Type 4',
          description: '',
        },
        {
          key: 'amount',
          type: 'number',
          label: 'Amount',
          description: '',
          constraints: [{ type: 'required' }],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit Data',
      primary: true,
    },
  ],
};

const EmployeeWorkbook: Flatfile.CreateWorkbookConfig = {
  name: 'Employee(HR) Data',
  sheets: [
    {
      name: 'Employee(HR) Data',
      slug: 'employee-data',
      fields: [
        {
          key: 'employeeId',
          type: 'string',
          label: 'Employee ID',
          description: 'Unique identifier for a given employee',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'fte',
          type: 'number',
          label: 'FTE',
          description: 'Number of employees converted into full time equivalents (hours, percentage, etc.)',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'branchId',
          type: 'string',
          label: 'Branch ID',
          description: 'Unique identifier for the branch assigned to the employee',
        },
        {
          key: 'contractType',
          type: 'string',
          label: 'Contract type',
          description: 'Permanent employee, contractor, outsourced',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'jobTitle',
          type: 'string',
          label: 'Job Title',
          description: 'Job title or function as defined by the bank ',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'role',
          type: 'string',
          label: 'Role or function',
          description: 'Role of the employee as defined by the bank',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'baseSalary',
          type: 'number',
          label: 'Base Salary',
          description: 'Base salary of the employee',
        },
        {
          key: 'variableCommission',
          type: 'number',
          label: 'Variable / commission',
          description: 'Variable compensation of the employee including bonus and compensation',
        },
        {
          key: 'benefits',
          type: 'number',
          label: 'Benefits',
          description: 'Employee benefits included as part of total compensation',
        },
        {
          key: 'totalCompensation',
          type: 'number',
          label: 'Total compensation',
          description: '',
        },
        {
          key: 'division',
          type: 'string',
          label: 'Division',
          description: '',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'subDivision1',
          type: 'string',
          label: 'SubDivision_1',
          description: '',
        },
        {
          key: 'subDivision2',
          type: 'string',
          label: 'SubDivision_2',
          description: '',
        },
        {
          key: 'city',
          type: 'string',
          label: 'City',
          description: '',
        },
        {
          key: 'state',
          type: 'string',
          label: 'State',
          description: '',
        },
        {
          key: 'zipCode',
          type: 'string',
          label: 'Zip Code',
          description: '',
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit Data',
      primary: true,
    },
  ],
};

const ProductWorkbook: Flatfile.CreateWorkbookConfig = {
  name: 'Product Data',
  sheets: [
    {
      name: 'Product Data',
      slug: 'product-data',
      fields: [
        {
          key: 'householdId',
          type: 'number',
          label: 'Household ID',
          description: 'Unique identifier for the account, client, and households',
        },
        {
          key: 'customerId',
          type: 'number',
          label: 'Customer ID',
          description: 'Unique identifier for the account, client, and households',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'accountID',
          type: 'number',
          label: 'Account ID',
          description: 'Unique identifier for the account, client, and households',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'jointAccountFlag',
          type: 'string',
          label: 'Joint Account Flag',
          description: 'Flag indicating if account belongs to primary account holder or secondary',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'customerSegment',
          type: 'string',
          label: 'Customer Segment',
          description: 'Role of the employee as defined by the bank',
        },
        {
          key: 'customerAge',
          type: 'number',
          label: 'Customer age',
          description: 'Age of the primary account holder in years',
        },
        {
          key: 'householdOpenDate',
          type: 'date',
          label: 'Household Open Date',
          description:
            'Start date of the household relationship with the bank and date when the household closed all products (dd/mm/yyyy)',
        },
        {
          key: 'householdCloseDate',
          type: 'date',
          label: 'Household Close Date',
          description:
            'End date of the household relationship with the bank and date when the household closed all products (dd/mm/yyyy)',
        },
        {
          key: 'customerOpenDate',
          type: 'date',
          label: 'Customer Open Date',
          description:
            'Start date of client relationship with the bank and date when the client closed all products (dd/mm/yyyy)',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'customerCloseDate',
          type: 'date',
          label: 'Customer Close Date',
          description:
            'End date of client relationship with the bank and date when the client closed all products (dd/mm/yyyy)',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'accountOpenDate',
          type: 'date',
          label: 'Account Open Date',
          description: 'Date on which the account is opened with the bank',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'accountCloseDate',
          type: 'date',
          label: 'Account Close Date',
          description: 'Date on which the account is closed with the bank',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'originatingChannel',
          type: 'string',
          label: 'Originating channel',
          description: 'Account acquisition channel ID (Branch, Online, mobile, RM, contact center, etc.)',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'productType',
          type: 'string',
          label: 'Product Type',
          description: 'Name or category that product is allocated to within the bank',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'annualAverageBalance',
          type: 'number',
          label: '12 month average balance',
          description: 'Average monthly balance during the study period',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'lastTransactionDate',
          type: 'date',
          label: 'Last Transaction Date',
          description:
            'Date of the last customer initiated financial transaction. Ignore bank-initiated transactions such as interest posting or fee charges',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberOfTransactionsLast1Month',
          type: 'number',
          label: '# of Transactions in the last 1 months',
          description:
            'Total number of customer-initiated financial transactions associated to the account in the last month of the study period',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberOfTransactionsLast3Months',
          type: 'number',
          label: '# of Transactions in the last 3 months',
          description:
            'Total number of customer-initiated financial transactions associated to the account in the last 3 months',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberofLoginsLast1Month',
          type: 'number',
          label: '# of log ins mobile in the last 1 months',
          description:
            'Total number of customer log ins to the digital channels associated to the account in the last month of the study period',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberofLoginsLast3Months',
          type: 'number',
          label: '# of log ins mobile in the last 3 months',
          description:
            'Total number of customer log ins to the digital channels associated to the account in the last 3 months of the study period',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberofLoginsLast1MonthWebBanking',
          type: 'number',
          label: '# of log ins online (web banking) in the last 1 months',
          description:
            'Total number of customer log ins to the digital channels associated to the account in the last month of the study period',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'numberofLoginsLast3MonthsWebBanking',
          type: 'number',
          label: '# of log ins online (web banking) in the last 3 months',
          description:
            'Total number of customer log ins to the digital channels associated to the account in the last 3 months of the study period',
          constraints: [{ type: 'required' }],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit Data',
      primary: true,
    },
  ],
};

export { RevenueWorkbook, OpexWorkbook, EmployeeWorkbook, ProductWorkbook };
