import { Box, Typography, useTheme } from '@mui/material';
import React, { useId } from 'react';

interface TableauEmbedInterface {
  url: string | null;
}

const TableauEmbed: React.FC<TableauEmbedInterface> = ({ url }) => {
  const { palette } = useTheme();
  const iframeId = useId();
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      {url === null ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: '50px',
            background: palette.green[200],
          }}
        >
          <Typography>Not implemented yet.</Typography>
        </Box>
      ) : (
        <iframe
          id={`iframe-${iframeId}`}
          src={`${url}`}
          width="100%"
          height="100%"
          style={{
            border: 'none',
          }}
          loading="lazy"
          onLoad={() => {
            console.log(`Iframe loaded for URL: ${url}`);
          }}
        ></iframe>
      )}
    </div>
  );
};

export default TableauEmbed;
