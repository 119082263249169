import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ToastContext } from '../../context/toastContext';
import { useAppSelector } from '../../utils/hooks/useAppSelector';

const ProtectedRoute = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { handleOpenToast } = useContext(ToastContext);

  if (!isAuthenticated) {
    handleOpenToast({
      message: 'You must be logged in to view this content. Please log in to continue.',
      severity: 'error',
    });
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
