import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { TypeAndFunctionTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const CostType: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, TypeAndFunctionTables.CostByType),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse,
    isLoading: isLoadingDataTable,
    refetch,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: TypeAndFunctionTables.CostByType,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Personnel', editable: true, indentLevel: 1 },
    { label: 'Premises', editable: true, indentLevel: 1 },
    { label: 'IT', editable: true, indentLevel: 1 },
    { label: 'Marketing', editable: true, indentLevel: 1 },
    { label: 'Other', editable: false, indentLevel: 1 },
    { label: 'Cash handling / vault services', editable: true, indentLevel: 2 },
    { label: 'Outsourced staff (contractors)', editable: true, indentLevel: 2 },
    { label: 'Professional services (Consultancy, audit & legal)', editable: true, indentLevel: 2 },
    { label: 'Postage', editable: true, indentLevel: 2 },
    { label: 'Office equipment', editable: true, indentLevel: 2 },
    { label: 'Regulatory fees', editable: true, indentLevel: 2 },
    { label: 'Other expenses', editable: true, indentLevel: 2 },
    { label: 'Operational losses', editable: true, indentLevel: 2 },
    { label: 'Fraud losses', editable: true, indentLevel: 3 },
    { label: 'Ammortization/Depreciation', editable: true, indentLevel: 1 },
    { label: 'Total', editable: false, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Direct costs ($)', editable: true },
    { key: 'B', label: 'Allocated costs ($)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            table: TypeAndFunctionTables.CostByType,
            tabKey,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B'],
          rowCount: 16,
          totalColumns: ['A5', 'B5', 'A16', 'B16'],
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tableSubmoduleId, tabKey]);

  useEffect(() => {
    refetch().catch((err) => {
      console.error(err);
    });
  }, [fetchFlag, refetch]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const aTotalSum = sumValues(['A1', 'A2', 'A3', 'A4'], values);
    const bTotalSum = sumValues(['B1', 'B2', 'B3', 'B4'], values);

    const aOtherSum = sumValues(['A6', 'A7', 'A8', 'A9', 'A10', 'A11', 'A12', 'A13'], values);
    const bOtherSum = sumValues(['B6', 'B7', 'B8', 'B9', 'B10', 'B11', 'B12', 'B13'], values);

    const a5 = formatNumber(aOtherSum + (values.A15 ?? 0));
    const b5 = formatNumber(bOtherSum + (values.B15 ?? 0));
    const a16 = formatNumber(aTotalSum + aOtherSum + (values.A15 ?? 0));
    const b16 = formatNumber(bTotalSum + bOtherSum + (values.B15 ?? 0));

    return {
      ...values,
      A5: a5,
      B5: b5,
      A16: a16,
      B16: b16,
    };
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: { tabKey, table: TypeAndFunctionTables.CostByType, values: updatedValues },
        columns: ['A', 'B'],
        rowCount: 16,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getRowStyle={(category) => {
            if (category.label === 'Total') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default CostType;
