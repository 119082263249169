import React, { useRef } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppButton from './AppButton'; // Adjust the import path as necessary

interface FileUploadButtonProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  buttonText: string;
  acceptedFileTypes: React.InputHTMLAttributes<HTMLInputElement>['accept']; // New prop for accepted file types
}
const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileChange,
  isLoading,
  buttonText,
  acceptedFileTypes,
}) => {
  const { palette } = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current === null) return;
    fileInputRef.current.click();
  };

  if (isLoading) return <CircularProgress color="inherit" />;

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onFileChange}
        accept={acceptedFileTypes}
        style={{ display: 'none' }}
      />
      <AppButton
        variant="contained"
        sx={{
          backgroundColor: palette.green[500],
          borderRadius: '8px',
          padding: '0 14px',
          '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
          height: '100%',
        }}
        onClick={handleButtonClick}
      >
        <Typography variant="buttonMedium">{buttonText}</Typography>
      </AppButton>
    </div>
  );
};

export default FileUploadButton;
