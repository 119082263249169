import { Box, Card, CardContent, CardMedia, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Delete, Download, Edit, Update, Person } from '@mui/icons-material';
import React, { useState } from 'react';

import { type WorkspaceDocument } from '../../../../../utils/types/WorkspaceDocument';
import { formatDate } from '../../../../../utils/dateUtils';
import { type User } from '../../../../../utils/types/User';

interface ReportPublicationCardProps {
  document: WorkspaceDocument;
  setSelectedDocument: (doc: WorkspaceDocument) => void;
  setIsEditModalOpen: (openFlag: boolean) => void;
  handleDeleteDocument: (docId: number) => void;
  sasToken: any;
  userDetails: User;
}

const ReportPublicationCard: React.FC<ReportPublicationCardProps> = ({
  document,
  setSelectedDocument,
  setIsEditModalOpen,
  handleDeleteDocument,
  sasToken,
  userDetails,
}) => {
  const { palette } = useTheme();

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Card
      sx={{
        border: `1px solid ${palette.gray[250]}`,
        borderRadius: '8px',
      }}
      elevation={2}
    >
      <>
        <CardMedia
          image={document?.image_url}
          component="img"
          alt={document.document_name.slice(0, document.document_name.lastIndexOf('.'))}
          sx={{
            height: 200,
            objectFit: 'cover',
            filter: isLoaded || document.image_url === null ? 'none' : 'blur(10px)',
            transition: 'filter 0.5s ease-in-out',
          }}
          onLoad={() => {
            setIsLoaded(true);
          }}
        ></CardMedia>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            gap: 2,
            flex: 1,
            padding: '16px !important',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            maxWidth="100%"
            minHeight={100}
            maxHeight={100}
          >
            <Tooltip
              title={document.document_name.slice(0, document.document_name.lastIndexOf('.'))}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                variant="headlineSmall"
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: palette.gray.black,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {document.document_name.slice(0, document.document_name.lastIndexOf('.'))}
              </Typography>
            </Tooltip>
            <Tooltip
              title={document.document_description}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                variant="bodySmall"
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {document.document_description}
              </Typography>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Person htmlColor={palette.common.black} sx={{ margin: '8px', fontSize: 'large' }} />
                <Typography sx={{ textTransform: 'capitalize' }} fontSize="small">
                  {document.user.split('@')[0].split('.')[1] + ' ' + document.user.split('@')[0].split('.')[0]}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Update htmlColor={palette.common.black} sx={{ margin: '8px', fontSize: 'large' }} />
                <Typography fontSize="small">{formatDate(document.updated_at ?? '')}</Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {userDetails.roles?.[0] === 'admin' && (
                <IconButton
                  aria-label="edit"
                  sx={{ padding: 0 }}
                  onClick={(e) => {
                    setSelectedDocument({
                      ...document,
                      document_name: document.document_name.slice(0, document.document_name.lastIndexOf('.')),
                    });
                    setIsEditModalOpen(true);
                  }}
                >
                  <Edit htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: 'large' }} />
                </IconButton>
              )}
              <a
                href={`${document.file_path}?${sasToken?.sas_token}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <IconButton aria-label="download" sx={{ padding: 0 }}>
                  <Download htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: 'large' }} />
                </IconButton>
              </a>
              {userDetails.roles?.[0] === 'admin' && (
                <IconButton
                  onClick={(e) => {
                    handleDeleteDocument(document.document_id);
                  }}
                  aria-label="delete"
                  sx={{ padding: 0 }}
                >
                  <Delete htmlColor={palette.red[500]} sx={{ margin: '8px', fontSize: 'large' }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </CardContent>
      </>
    </Card>
  );
};

export default ReportPublicationCard;
