import CheckIcon from '@mui/icons-material/Check';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, InputBase, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface EditableTitleProps {
  title: string;
  onSave: (newTitle: string) => void;
}

const FONT_WIDTH = 15;
const DEFAULT_INPUT_WIDTH = 500;

const EditableTitle: React.FC<EditableTitleProps> = ({ title, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);
  const { palette, typography } = useTheme();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(currentTitle);
  };

  useEffect(() => {
    if (currentTitle.length * FONT_WIDTH > DEFAULT_INPUT_WIDTH) {
      setInputWidth(currentTitle.length * FONT_WIDTH);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  }, [currentTitle, inputWidth]);

  return (
    <>
      <Box>
        <InputBase
          disabled={!isEditing}
          value={currentTitle}
          onChange={(e) => {
            setCurrentTitle(e.target.value);
          }}
          inputProps={{
            style: {
              color: palette.green[700],
              fontSize: typography.headlineLarge?.fontSize,
              padding: 0,
              width: `${inputWidth}px`,
              maxWidth: '800px',
            },
          }}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'inherit',
            },
          }}
        />
      </Box>
      <IconButton
        size="small"
        sx={{ mt: '4px', color: ({ palette }) => palette.green[500] }}
        onClick={isEditing ? handleSaveClick : handleEditClick}
        aria-label={isEditing ? 'save' : 'edit'}
      >
        {isEditing ? <CheckIcon /> : <ModeEditOutlineOutlinedIcon />}
      </IconButton>
    </>
  );
};

export default EditableTitle;
