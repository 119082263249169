import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

interface BackButtonProps {
  text: string;
  to: string;
}

const BackButton: React.FC<BackButtonProps> = ({ text, to }) => {
  const { palette } = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <Button
        component={RouterLink}
        to={to}
        startIcon={<ArrowBackIcon />}
        style={{ textTransform: 'none', color: palette.green[500] }}
      >
        <Typography variant="buttonSmall">{text}</Typography>
      </Button>
    </Box>
  );
};

export default BackButton;
