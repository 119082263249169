import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { addKeyThemeItem } from '../../../utils/redux/keyThemeSlice';
import { KeyThemeItemType, type BaseKeyThemeItem } from '../../../utils/types/KeyTheme';
import KeyThemeItemCard from './KeyThemeItemCard';

interface Props {
  items: BaseKeyThemeItem[] | undefined;
  itemType: KeyThemeItemType;
}

const KeyThemeItemCardContainer: React.FC<Props> = ({ items, itemType }) => {
  const isEditing = useAppSelector((state) => state.keyThemes.isEditingKeyTheme);
  const [page, setPage] = useState(0);
  const { palette } = useTheme();
  const dispatch = useDispatch();

  if (items === undefined) return;

  const chunkedItems = items.reduce<BaseKeyThemeItem[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (resultArray[chunkIndex] === undefined) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return (
    <Box display="flex" flexDirection="column" rowGap={3} alignItems="center" mt={2}>
      <Box width="100%">
        <Typography variant="headlineMedium">
          {itemType === KeyThemeItemType.Insight ? 'Insights' : 'Opportunity Areas / Next Steps  '}
        </Typography>
      </Box>

      <Stack direction="row" gap={2} width={items.length === 0 ? 'inherit' : '100%'} justifyContent="center">
        <Carousel
          swipe={false}
          navButtonsAlwaysInvisible
          index={page}
          autoPlay={false}
          animation="slide"
          sx={{ width: '100%' }}
        >
          {chunkedItems.map((itemChunk, chunkIndex) => (
            <Stack key={chunkIndex} direction="row" gap={2} width="100%" height="10%">
              {itemChunk.map((item, itemIndex) => (
                <KeyThemeItemCard
                  item={item}
                  itemType={itemType}
                  key={chunkIndex * 2 + itemIndex}
                  index={chunkIndex * 2 + itemIndex}
                />
              ))}
            </Stack>
          ))}
        </Carousel>
        {isEditing && (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <IconButton
              onClick={() => {
                dispatch(
                  addKeyThemeItem({
                    item: { consumerSurveyLabel: false, description: '', title: '' },
                    itemType,
                  }),
                );
                const lastPage = items.length / 2;
                setPage(Number.isInteger(lastPage) ? Math.ceil(lastPage) + 1 : Math.ceil(lastPage));
              }}
            >
              <AddCircleIcon fontSize="large" sx={{ color: palette.green[500] }} />
            </IconButton>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default KeyThemeItemCardContainer;
