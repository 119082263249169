import { CircularProgress } from '@mui/material';
import React from 'react';

import ModalWrapper from '../../PeerGroups/components/ModalWrapper';

interface ConfirmationModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  isLoading?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
  onSave,
  isLoading,
}) => {
  const buttons: Array<{
    label: string | JSX.Element;
    action: () => void;
    variant: 'filled' | 'outlined';
    disabled?: boolean;
  }> = [
    {
      label: 'Cancel',
      action: onClose,
      variant: 'outlined',
      disabled: isLoading,
    },
    {
      label: isLoading === true ? <CircularProgress size={20} color="inherit" /> : 'Confirm',
      action: onSave,
      variant: 'filled',
    },
  ];

  return (
    <ModalWrapper open={isOpen} handleClose={onClose} title={title} summary={text} buttons={buttons}></ModalWrapper>
  );
};
