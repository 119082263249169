import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts';

import RedesignAppButton from '../../../../../../components/materials/actions/RedesignAppButton';

interface Props {
  open: boolean;
  handleClose: () => void;
  dataset: any;
  labels: any;
  xAxis: any;
  chartSetting: any;
}

const ChartModal: React.FC<Props> = ({ open, handleClose, dataset, labels, xAxis, chartSetting }) => {
  const [series, setSeries] = useState<any[]>([]);

  useEffect(() => {
    if (open) {
      const chartSeries = [];
      for (const record of dataset) {
        for (const key in record) {
          if (Object.prototype.hasOwnProperty.call(record, key)) {
            if (key !== xAxis[0].dataKey) {
              chartSeries.push({
                dataKey: key,
                label: labels[key],
                valueFormatter: (value: number) => value.toFixed(2),
              });
            }
          }
        }
      }
      setSeries(chartSeries);
    }
  }, [open, dataset, labels, xAxis]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        position="absolute"
        bgcolor="white"
        sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        p={2}
        display="flex"
        flexDirection="column"
        borderRadius={1}
        gap={3}
        width="680px"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="titleMedium">Chart</Typography>
        </Box>

        <BarChart
          colors={['#97E3D5']}
          dataset={dataset}
          xAxis={xAxis}
          series={series}
          slotProps={{ legend: { hidden: true } }}
          {...chartSetting}
          sx={{ marginLeft: '24px' }}
        />

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <RedesignAppButton appVariant="filled" onClick={handleClose}>
            Close
          </RedesignAppButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChartModal;
