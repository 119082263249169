import { Button, CircularProgress, type ButtonProps, type SxProps, type Theme } from '@mui/material';
import React from 'react';

interface AppButtonProps extends ButtonProps {
  children?: React.ReactNode;
  variant: 'text' | 'contained' | 'outlined';
  disabled?: boolean;
  sx?: SxProps;
  startIcon?: React.ReactNode;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const AppButton: React.FC<AppButtonProps> = ({
  children,
  variant,
  disabled = false,
  sx,
  startIcon,
  loading = false,
  onClick,
  ...props
}) => {
  return (
    <>
      {loading ? (
        <CircularProgress
          color="inherit"
          sx={{
            width: '32px !important',
            height: '32px !important',
            color: (theme: Theme) => theme.palette.green[700],
          }}
        />
      ) : (
        <Button
          variant={variant}
          disabled={disabled}
          sx={{ textTransform: 'capitalize', ...sx }}
          startIcon={startIcon}
          onClick={onClick}
          disableElevation
          {...props}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default AppButton;
