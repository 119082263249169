import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  KeyThemeItemType,
  KeyThemeResourceType,
  type Insight,
  type KeyTheme,
  type KeyThemeResource,
  type Opportunity,
} from '../types/KeyTheme';

interface KeyThemesState {
  keyThemes: KeyTheme[];
  selectedKeyThemeId: number | null;
  editingKeyTheme: KeyTheme | null;
  isEditingKeyTheme: boolean;
}

const initialState: KeyThemesState = {
  keyThemes: [],
  selectedKeyThemeId: null,
  editingKeyTheme: null,
  isEditingKeyTheme: false,
};

const keyThemeSlice = createSlice({
  name: 'keyThemes',
  initialState,
  reducers: {
    setKeyThemes: (state, action: PayloadAction<KeyTheme[]>) => {
      state.keyThemes = action.payload;
    },
    addKeyThemes: (state, action: PayloadAction<KeyTheme | KeyTheme[]>) => {
      if (Array.isArray(action.payload)) {
        state.keyThemes.push(...action.payload);
      } else {
        state.keyThemes.push(action.payload);
      }
    },
    updateKeyThemeById: (state, action: PayloadAction<KeyTheme>) => {
      const newKeyTheme = action.payload;
      const currentKeyThemeIndex = state.keyThemes.findIndex((kt) => kt.id === newKeyTheme.id);
      if (currentKeyThemeIndex !== -1) state.keyThemes[currentKeyThemeIndex] = newKeyTheme;
    },
    setSelectedKeyThemeId: (state, action: PayloadAction<number | null>) => {
      state.selectedKeyThemeId = action.payload;
    },
    clearSelectedKeyThemeId: (state) => {
      state.selectedKeyThemeId = null;
    },
    setEditingKeyTheme: (state, action: PayloadAction<KeyTheme>) => {
      state.editingKeyTheme = action.payload;
      state.isEditingKeyTheme = true;
    },
    clearIsEditingKeyTheme: (state) => {
      state.isEditingKeyTheme = false;
    },
    clearEditingKeyTheme: (state) => {
      state.isEditingKeyTheme = false;
      state.editingKeyTheme = null;
    },
    switchItemConsumerSurveyLabelByIndex: (
      state,
      action: PayloadAction<{ itemIndex: number; itemType: KeyThemeItemType }>,
    ) => {
      const { itemIndex, itemType } = action.payload;
      const itemToBeUpdated =
        itemType === KeyThemeItemType.Insight
          ? state.editingKeyTheme?.insights[itemIndex]
          : state.editingKeyTheme?.opportunities[itemIndex];

      if (itemToBeUpdated === undefined) return;

      itemToBeUpdated.consumerSurveyLabel = !itemToBeUpdated.consumerSurveyLabel;
    },
    updateKeyThemeItemByIndex: (
      state,
      action: PayloadAction<{ item: Insight | Opportunity; itemType: KeyThemeItemType; index: number }>,
    ) => {
      if (state.editingKeyTheme == null) return;

      const { item, itemType, index } = action.payload;

      let items;

      if (itemType === KeyThemeItemType.Insight) {
        items = state.editingKeyTheme.insights;
      } else {
        items = state.editingKeyTheme.opportunities;
      }

      items[index] = item;
    },
    addKeyThemeItem: (state, action: PayloadAction<{ item: Insight | Opportunity; itemType: KeyThemeItemType }>) => {
      if (state.editingKeyTheme == null) return;

      const { item, itemType } = action.payload;

      let items;

      if (itemType === KeyThemeItemType.Insight) {
        items = state.editingKeyTheme.insights;
      } else {
        items = state.editingKeyTheme.opportunities;
      }

      items.push(item);
    },
    deleteKeyThemeItemByIndex: (state, action: PayloadAction<{ itemType: KeyThemeItemType; index: number }>) => {
      if (state.editingKeyTheme === null) return;

      const { itemType, index } = action.payload;

      if (itemType === KeyThemeItemType.Insight) {
        state.editingKeyTheme.insights = state.editingKeyTheme.insights.filter((_, i) => i !== index);
      } else if (itemType === KeyThemeItemType.Opportunity) {
        state.editingKeyTheme.opportunities = state.editingKeyTheme.opportunities.filter((_, i) => i !== index);
      }
    },
    addResource: (
      state,
      action: PayloadAction<{ resourceType: KeyThemeResourceType; resource: KeyThemeResource }>,
    ) => {
      if (state.editingKeyTheme === null) return;

      const { resourceType, resource } = action.payload;

      if (resourceType === KeyThemeResourceType.Kpi) {
        state.editingKeyTheme.kpis.push(resource);
      } else if (resourceType === KeyThemeResourceType.ConsumerSurvey) {
        state.editingKeyTheme.consumerSurveys.push(resource);
      }
    },
    setResources: (
      state,
      action: PayloadAction<{ resourceType: KeyThemeResourceType; resources: KeyThemeResource[] }>,
    ) => {
      if (state.editingKeyTheme === null) return;
      const { resourceType, resources } = action.payload;

      if (resourceType === KeyThemeResourceType.Kpi) {
        state.editingKeyTheme.kpis = resources;
      } else if (resourceType === KeyThemeResourceType.ConsumerSurvey) {
        state.editingKeyTheme.consumerSurveys = resources;
      }
    },
    deleteResourceById: (
      state,
      action: PayloadAction<{ resourceType: KeyThemeResourceType; resourceId: number | string }>,
    ) => {
      if (state.editingKeyTheme === null) return;
      const { resourceType, resourceId } = action.payload;

      if (resourceType === KeyThemeResourceType.Kpi) {
        state.editingKeyTheme.kpis = state.editingKeyTheme.kpis.filter((file) => file.id !== resourceId);
      } else if (resourceType === KeyThemeResourceType.ConsumerSurvey) {
        state.editingKeyTheme.consumerSurveys = state.editingKeyTheme.consumerSurveys.filter(
          (file) => file.id !== resourceId,
        );
      }
    },
    deleteKeyThemeById: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      state.keyThemes = state.keyThemes.filter((kt) => kt.id !== id);
    },
  },
  selectors: {
    selectCurrentKeyTheme: createSelector(
      (state: KeyThemesState) => state.keyThemes,
      (state: KeyThemesState) => state.selectedKeyThemeId,
      (keyThemes, selectedKeyThemeId) =>
        selectedKeyThemeId !== null ? keyThemes.find((kt) => kt.id === selectedKeyThemeId) ?? null : null,
    ),
    selectResourcesByResourceType: createSelector(
      (state: KeyThemesState) => state.editingKeyTheme,
      (_: KeyThemesState, resourceType: KeyThemeResourceType) => resourceType,
      (editingKeyTheme, resourceType) => {
        if (editingKeyTheme === null) return [];
        return resourceType === KeyThemeResourceType.Kpi ? editingKeyTheme.kpis : editingKeyTheme.consumerSurveys;
      },
    ),
  },
});

export const {
  setKeyThemes,
  addKeyThemes,
  setSelectedKeyThemeId,
  setEditingKeyTheme,
  clearSelectedKeyThemeId,
  clearIsEditingKeyTheme,
  clearEditingKeyTheme,
  switchItemConsumerSurveyLabelByIndex,
  updateKeyThemeItemByIndex,
  addKeyThemeItem,
  deleteKeyThemeItemByIndex,
  addResource,
  setResources,
  deleteResourceById,
  deleteKeyThemeById,
  updateKeyThemeById,
} = keyThemeSlice.actions;
export const { selectCurrentKeyTheme, selectResourcesByResourceType } = keyThemeSlice.selectors;

export default keyThemeSlice.reducer;
