export const generateTableData = (
  columns: string[],
  rowCount: number,
  values: Record<string, number | null>,
): Array<Array<number | null>> => {
  const data: Array<Array<number | null>> = [];
  for (let i = 1; i <= rowCount; i++) {
    const row = columns.map((col) => values[`${col}${i}`] ?? null);
    data.push(row);
  }
  return data;
};
