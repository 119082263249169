import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Paper,
  styled,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  Typography,
  useTheme,
  type TooltipProps,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContext } from '../../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../utils/hooks/useAppSelector';
import { useCreateCommentThreadMutation } from '../../../../../../../../utils/redux/api';
import {
  selectActiveDataTable,
  setOpenComments,
  updateDataTableCommentThreads,
} from '../../../../../../../../utils/redux/dataTablesSlice';
import { type CommentThread } from '../../../../../../../../utils/types/CommentThread';
import CommentCard from './CommentCard';
import NewCommentBox from './NewCommentBox';

const getCorrectedCellNumber = (input: string): string => {
  const match = input.match(/^([A-Z]+)(\d+)$/);
  if (match === null) return '';

  return `${match[1]}${parseInt(match[2], 10) + 1}`;
};

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.green[500],
  },
  '&.Mui-indicator:': {
    backgroundColor: theme.palette.green[500],
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
  },
}));

const CommentPanel: React.FC = () => {
  const { refetchSubmodule } = useWorkspaceDataContext();
  const { palette } = useTheme();
  const selectedCell = useAppSelector((state) => state.dataTables.activeDataTable.activeCell);
  const activeDataTableId = useAppSelector((state) => state.dataTables.activeDataTable?.dataTableId);
  const [tabValue, setTabValue] = useState(1);
  const [currentCellCommentCount, setCurrentCellCommentCount] = useState(0);
  const dataTable = useAppSelector((state) => selectActiveDataTable(state));
  const { commentThreads = [], commentCount = 0 } = dataTable ?? {};
  const [newCommentThread, setNewCommentThread] = useState('');
  const [addNewCommentThread, { isLoading }] = useCreateCommentThreadMutation();
  const { handleOpenToast } = useContext(ToastContext);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (selectedCell === null) return;
    const total = commentThreads?.reduce((acc: number, ct: CommentThread) => {
      if (selectedCell.cellId === getCorrectedCellNumber(ct.cell)) {
        if (ct.comments === null) return acc + 1;
        return acc + Object.keys(ct.comments).length + 1;
      }
      return acc;
    }, 0);

    setCurrentCellCommentCount(total);
  }, [selectedCell, commentThreads]);

  const handleNewCommentThread = async () => {
    if (selectedCell === null || activeDataTableId === null || isSubmitting) return;
    setIsSubmitting(true);
    try {
      await addNewCommentThread({
        column: selectedCell.column,
        row: selectedCell.row,
        issue_text: newCommentThread,
        dataTableId: activeDataTableId,
      }).unwrap();
      dispatch(
        updateDataTableCommentThreads({
          commentThreads: [],
          dataTableId: activeDataTableId,
        }),
      );
      setNewCommentThread('');

      await refetchSubmodule();

      handleOpenToast({ message: 'Comment saved successfully', severity: 'success' });
    } catch (err) {
      handleOpenToast({ message: 'Error saving new component', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const CommentList = (commentThreads: CommentThread[]) => {
    return (
      <>
        {commentThreads.map((ct) => (
          <CommentCard commentThread={ct} key={ct.comment_thread_id} cellId={ct.cell} isResolved={!ct.is_open} />
        ))}
      </>
    );
  };

  return (
    <Box bgcolor={palette.green[100]} p="15px" sx={{ borderRadius: '8px', width: '250px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems={'center'}>
          <Typography variant="titleLarge">Comments</Typography>
          <StyledTooltip
            title={
              <Box display={'flex'} flexDirection={'column'} rowGap="10px" style={{ padding: '10px' }}>
                <Typography variant="titleSmallBold">How to leave a comment</Typography>
                <Typography variant="bodySmall">Right click on the desired cell to leave a comment</Typography>
              </Box>
            }
            placement="top-start"
          >
            <IconButton size="small">
              <InfoOutlined htmlColor={palette.green[500]} />
            </IconButton>
          </StyledTooltip>
        </Box>
        <IconButton onClick={() => dispatch(setOpenComments(false))}>
          <Close />
        </IconButton>
      </Box>
      <Tabs
        value={tabValue}
        onChange={(_, newValue: number) => {
          setTabValue(newValue);
        }}
        variant="fullWidth"
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'black',
            color: palette.green[500],
          },
        }}
      >
        <StyledTab label={`Current Cell (${currentCellCommentCount})`} sx={{ textTransform: 'none' }} wrapped />
        <StyledTab label={`All Comments (${commentCount})`} sx={{ textTransform: 'none' }} wrapped />
      </Tabs>
      {selectedCell !== null && (
        <Paper
          style={{ margin: '8px 0px 8px 0px', padding: '10px 0 5px 0', border: `1px solid ${palette.gray[300]}` }}
          elevation={2}
        >
          <List sx={{ paddingTop: '0' }}>
            <ListItem sx={{ padding: '0 16px 0 16px' }}>
              <Typography
                variant="labelSmall"
                style={{
                  marginBottom: '10px',
                  border: `1px solid ${palette.green[300]}`,
                  borderRadius: '4px',
                  backgroundColor: palette.green[100],
                  padding: '0 7px 0 7px',
                }}
              >
                {selectedCell.cellId}
              </Typography>
            </ListItem>
            <ListItem>
              <NewCommentBox
                newComment={newCommentThread}
                setNewComment={setNewCommentThread}
                isLoading={isLoading}
                handleNewCommentRequest={handleNewCommentThread}
                isSubmitting={isSubmitting}
              />
            </ListItem>
          </List>
        </Paper>
      )}
      <List sx={{ rowGap: '15px', display: 'flex', flexDirection: 'column' }}>
        {tabValue === 0
          ? CommentList(
              commentThreads.filter((ct: CommentThread) => getCorrectedCellNumber(ct.cell) === selectedCell?.cellId),
            )
          : CommentList(commentThreads)}
      </List>
    </Box>
  );
};

export default CommentPanel;
