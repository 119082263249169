import { Chip, useTheme, type ChipProps } from '@mui/material';
import React, { useMemo } from 'react';

type ChipType = 'error' | 'warning' | 'success';

interface Props extends ChipProps {
  type: ChipType;
}

const AppChip: React.FC<Props> = ({ type, sx, ...props }) => {
  const { palette } = useTheme();

  const getChipColor = useMemo(() => {
    switch (type) {
      case 'error':
        return [palette.red[200], palette.red[700]];
      case 'warning':
        return [palette.yellow[300], palette.yellow[700]];
      case 'success':
        return [palette.green[200], palette.green[500]];
      default:
        return [palette.gray[200], palette.gray.black];
    }
  }, [palette, type]);

  return (
    <Chip
      sx={{
        bgcolor: getChipColor[0],
        color: getChipColor[1],
        py: 0.25,
        px: 1.5,
        borderRadius: 1,
        ...sx,
      }}
      {...props}
    />
  );
};

export default AppChip;
