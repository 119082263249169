import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Outlet, Link as RouterLink, useNavigate, useResolvedPath } from 'react-router-dom';

import RedesignAppButton from '../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { useWorkspaceDataContext } from '../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../utils/hooks/useAppSelector';
import useRouteMatch from '../../../../../utils/hooks/useRouteMatch';
import { selectUserRoles } from '../../../../../utils/redux/authSlice';

const routePattern = '/app/workspaces/:workspaceName/:workspaceId';

const Workspace: React.FC = () => {
  const { activeWorkspace: { country = '', workspace_name: workspaceName = '' } = {} } = useWorkspaceDataContext();
  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const baseUrl = useResolvedPath('').pathname;
  const navigate = useNavigate();
  const subroutes = [
    {
      subroute_id: 1,
      subroute_name: 'Data',
      subroute_paths: [`${routePattern}/data/*`],
      subroute_initial_path: `${baseUrl}/data`,
    },
    // a workspace can be the primary client of n peer groups
    // a workspace can also be a peer within n peer groups
    // but at a time a workspace has only one peer group associated with it
    // which is used to generate the detailed results
    // in the future this tab could be revisited to display all peer groups related to the workspace, for example
    /*
    {
      subroute_id: 2,
      subroute_name: 'Peer Groups',
      subroute_paths: [`${routePattern}/peer-groups`],
      subroute_initial_path: `${baseUrl}/peer-groups`,
    },
    */
    {
      subroute_id: 3,
      subroute_name: 'Detailed Results',
      subroute_paths: [`${routePattern}/detailed-results/:module/*`],
      subroute_initial_path: `${baseUrl}/detailed-results`,
    },
    {
      subroute_id: 4,
      subroute_name: 'Key Themes & Insights',
      subroute_paths: [`${routePattern}/key-themes-insights`],
      subroute_initial_path: `${baseUrl}/key-themes-insights`,
    },
    {
      subroute_id: 5,
      subroute_name: 'Reports & Publications',
      subroute_paths: [`${routePattern}/reports-publications`],
      subroute_initial_path: `${baseUrl}/reports-publications`,
    },
  ];
  const possibleRoutes = subroutes.flatMap((module) => module.subroute_paths);

  const routeMatch = useRouteMatch(possibleRoutes);
  const currentSubroute = routeMatch?.pattern.path;

  const { palette } = useTheme();

  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          padding: userRoles.includes('data_collector') ? '20px 32px' : '20px 32px 0px 32px',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display={'flex'} gap={'16px'} alignItems="baseline">
            <Typography variant="displaySmall">{workspaceName}</Typography>
            <Typography variant="labelLarge" color={'textSecondary'}>
              {country !== '' ? `${country}` : <AppCircularProgress size={'22px'} />}{' '}
            </Typography>
          </Box>
          {userRoles.includes('admin') && (
            <RedesignAppButton
              appVariant="outlined"
              widePadding={true}
              onClick={() => {
                navigate(`${baseUrl}/edit-workspace`);
              }}
              sx={{ paddingY: '0' }}
            >
              <Typography variant="buttonMedium">Settings</Typography>
            </RedesignAppButton>
          )}
        </Box>

        {(userRoles.includes('admin') || userRoles.includes('data_lead')) && (
          <Tabs
            value={currentSubroute ?? false}
            variant="standard"
            sx={{
              marginTop: '16px',
              '& .MuiTabs-indicator': { backgroundColor: palette.green[500] },
            }}
          >
            {subroutes.map((subroute: any) => {
              return (
                <Tab
                  key={subroute.subroute_id}
                  label={
                    <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                      {subroute.subroute_name}
                    </Typography>
                  }
                  value={subroute.subroute_paths[0]}
                  to={subroute.subroute_initial_path}
                  component={RouterLink}
                  disableRipple
                  sx={{
                    textTransform: 'none',
                    cursor: 'default',
                    color: palette.gray[500],
                    '&:hover': {
                      backgroundColor: palette.green[100],
                    },
                    '&.Mui-selected': {
                      color: palette.green[500],
                      backgroundColor: palette.green[100],
                    },
                  }}
                ></Tab>
              );
            })}
          </Tabs>
        )}
      </Box>
      <Outlet />
    </>
  );
};

export default Workspace;
