import { Search as SearchIcon } from '@mui/icons-material';
import { Backdrop, Box, Divider, Grid, InputAdornment, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RedesignAppButton from '../../../../../components/materials/actions/RedesignAppButton';
import AppTextField from '../../../../../components/materials/forms/AppTextField';
import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../context/toastContext';
import countries, { type Country } from '../../../../../utils/countries';
import { useAppSelector } from '../../../../../utils/hooks/useAppSelector';
import { useAddWorkspacesMutation } from '../../../../../utils/redux/api';

const today = new Date().toISOString().split('T')[0];
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.{1}[a-zA-Z]{2,})+$/;

const CreateWorkspace = () => {
  const { palette } = useTheme();
  const email = useAppSelector((state) => state?.auth?.userDetails?.email?.toLowerCase());
  const navigate = useNavigate();
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [workspace, setWorkspace] = useState({
    workspace_name: '',
    country: '',
    client_point_of_contact_name: '',
    client_point_of_contact_email: '',
    kickoff_meeting_date: '',
    final_due_date: '',
    beginning_of_period: '',
    end_of_period: '',
    bcg_mdp_email: '',
  });
  const { handleOpenToast } = useContext(ToastContext);
  const [addWorkspaces, response] = useAddWorkspacesMutation();
  const { isSuccess, isError, error, isLoading } = response;

  useEffect(() => {
    if (isSuccess) handleOpenToast({ message: 'Workspace created successfully', severity: 'success' });
    if (isError) {
      if ('data' in error) {
        const errorMessage = (error.data as { message: string }).message;
        handleOpenToast({ message: `Error creating workspace - ${errorMessage}`, severity: 'error' });
      } else {
        handleOpenToast({ message: 'Error creating workspace', severity: 'error' });
      }
    }
  }, [isError, error, isSuccess, handleOpenToast]);

  useEffect(() => {
    setIsFormValid(
      workspace.workspace_name !== '' &&
        workspace.country !== '' &&
        workspace.client_point_of_contact_name !== '' &&
        workspace.client_point_of_contact_email !== '' &&
        !isEmailInvalid(workspace.client_point_of_contact_email) &&
        !isEmailInvalid(email) &&
        workspace.bcg_mdp_email !== '' &&
        !isEmailInvalid(workspace.bcg_mdp_email) &&
        workspace.kickoff_meeting_date !== '' &&
        workspace.final_due_date !== '' &&
        workspace.beginning_of_period !== '' &&
        workspace.end_of_period !== '',
    );
  }, [workspace, email]);

  const isEmailInvalid = (email: string | undefined): boolean => {
    if (email === undefined) return false;
    if (email.trim().length === 0) return false;
    return !emailRegex.test(email);
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setWorkspace({ ...workspace, country: value });
    if (value.length > 0) {
      const filtered = countries.filter((country) => country.label.toLowerCase().includes(value.toLowerCase()));
      setFilteredCountries(filtered);
    } else {
      setFilteredCountries([]);
    }
  };

  const handleKickoffDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newKickoffDate = event.target.value;
    setWorkspace({ ...workspace, kickoff_meeting_date: newKickoffDate });
    if (
      workspace?.kickoff_meeting_date?.length > 0 &&
      newKickoffDate?.length > 0 &&
      new Date(workspace?.final_due_date) <= new Date(newKickoffDate)
    ) {
      setWorkspace({ ...workspace, final_due_date: '' });
    }
  };

  const handleFinalDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFinalDueDate = event.target.value;
    if (
      workspace?.kickoff_meeting_date?.length === 0 ||
      new Date(newFinalDueDate) > new Date(workspace?.kickoff_meeting_date)
    ) {
      setWorkspace({ ...workspace, final_due_date: newFinalDueDate });
    } else {
      handleOpenToast({ message: 'Final Due Date must be later than Kickoff Meeting Date.', severity: 'error' });
    }
  };

  const handleBeginningPeriodDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBeginningPeriodDate = event.target.value;
    setWorkspace({ ...workspace, beginning_of_period: newBeginningPeriodDate });
    if (
      workspace?.beginning_of_period?.length > 0 &&
      newBeginningPeriodDate?.length > 0 &&
      new Date(workspace?.end_of_period) <= new Date(newBeginningPeriodDate)
    ) {
      setWorkspace({ ...workspace, end_of_period: '' });
    }
  };

  const handleEndPeriodDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndPeriodDate = event.target.value;
    if (
      workspace?.beginning_of_period?.length === 0 ||
      new Date(newEndPeriodDate) > new Date(workspace?.beginning_of_period)
    ) {
      setWorkspace({ ...workspace, end_of_period: newEndPeriodDate });
    } else {
      handleOpenToast({ message: 'End of Period Date must be later than Beginning Period Date.', severity: 'error' });
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    addWorkspaces(workspace)
      .then((res: any) => {
        if (res.error === undefined) {
          navigate('/app/workspaces', { state: { refetch: true } });
        }
      })
      .catch((error) => {
        console.error('Failed to create workspace', error);
      });
  };

  if (isLoading)
    return (
      <Backdrop open>
        <AppCircularProgress />
      </Backdrop>
    );

  return (
    <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', mb: '16px' }}>
      <Box
        sx={{
          border: `1px solid ${palette.gray[300]}`,
          borderRadius: '8px',
          backgroundColor: palette.gray.white,
          padding: (theme) => `20px ${theme.spacing(4)}`,
        }}
        border={1}
      >
        <Typography variant="displayMedium">New Workspace</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          border: `1px solid ${palette.gray[300]}`,
          borderRadius: '8px',
          backgroundColor: palette.gray.white,
          padding: (theme) => `${theme.spacing(3)} ${theme.spacing(4)}`,
        }}
      >
        <Typography variant="bodyMedium" color={'textSecondary'} marginBottom={1}>
          All required fields are marked with an asterisk (*)
        </Typography>

        <Typography variant="headlineSmall">Client Details</Typography>

        <Grid container columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <AppTextField
              id="workspace-name"
              variant="outlined"
              label="Workspace Name (Bank Name) *"
              labelVariant="labelLarge"
              placeholder="Enter the Bank's Name"
              helperText="Bank Name"
              value={workspace.workspace_name}
              onChange={(event) => {
                setWorkspace({ ...workspace, workspace_name: event.target.value });
              }}
              required
              sx={{ width: '100%', borderRadius: '4px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="block" position="relative" width="100%">
              <AppTextField
                id="bank-country"
                variant="outlined"
                label="Bank Country *"
                labelVariant="labelLarge"
                placeholder="Type to Search"
                helperText="Bank Country"
                value={workspace.country}
                onChange={handleCountryChange}
                required
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {workspace?.country?.length > 0 && filteredCountries?.length > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    maxHeight: '300px',
                    overflow: 'auto',
                    border: `1px solid ${palette.gray[300]}`,
                    backgroundColor: palette.gray.white,
                  }}
                >
                  {filteredCountries?.map((country, index) => (
                    <Box
                      key={index}
                      sx={{
                        padding: '8px',
                        '&:hover': {
                          backgroundColor: palette.gray[250],
                        },
                      }}
                      onMouseDown={() => {
                        setWorkspace({ ...workspace, country: country?.label });
                        setFilteredCountries([]);
                      }}
                    >
                      {country?.label}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <AppTextField
              id="client-contact-name"
              variant="outlined"
              label="Client Point of Contact Name *"
              labelVariant="labelLarge"
              placeholder="Enter the User's Name"
              helperText="First and Last Name"
              value={workspace.client_point_of_contact_name}
              onChange={(event) => {
                setWorkspace({ ...workspace, client_point_of_contact_name: event.target.value });
              }}
              required
              sx={{ width: '100%', borderRadius: '4px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              id="client-email"
              variant="outlined"
              label="Client Point of Contact E-mail Address *"
              labelVariant="labelLarge"
              placeholder="Enter the User's E-mail Address"
              helperText={
                isEmailInvalid(workspace.client_point_of_contact_email)
                  ? 'Inform a valid e-mail address'
                  : 'E-mail Address'
              }
              value={workspace.client_point_of_contact_email}
              onChange={(event) => {
                setWorkspace({ ...workspace, client_point_of_contact_email: event.target.value });
              }}
              required
              error={isEmailInvalid(workspace.client_point_of_contact_email)}
              sx={{ width: '100%', borderRadius: '4px' }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        <Typography variant="headlineSmall">Data Cycle Details</Typography>

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          paddingX={3}
          paddingY={2}
          borderRadius={1}
          sx={{ border: `1px solid ${palette.green[500]}`, background: palette.green[50] }}
        >
          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="bcg-analyst"
                variant="outlined"
                label="Assigned BCG Analyst *"
                labelVariant="labelLarge"
                placeholder="Enter the BCG Analyst E-mail Address"
                helperText={isEmailInvalid(email) ? 'Inform a valid e-mail address' : 'E-mail Address'}
                value={email}
                required
                disabled
                error={isEmailInvalid(email)}
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{ sx: { backgroundColor: palette.gray.white } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="bcg-mdp"
                variant="outlined"
                label="BCG MDP/CCO *"
                labelVariant="labelLarge"
                placeholder="Enter the BCG MDP/CCO E-mail Address"
                helperText={
                  isEmailInvalid(workspace.bcg_mdp_email) ? 'Inform a valid e-mail address' : 'E-mail Address'
                }
                value={workspace.bcg_mdp_email}
                onChange={(event) => {
                  setWorkspace({ ...workspace, bcg_mdp_email: event.target.value });
                }}
                required
                error={isEmailInvalid(workspace.bcg_mdp_email)}
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{ sx: { backgroundColor: palette.gray.white } }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="kickoff-date"
                variant="outlined"
                type="date"
                label="Kickoff Meeting Date *"
                labelVariant="labelLarge"
                placeholder="MM/DD/YYYY"
                helperText="Kickoff Date"
                value={workspace.kickoff_meeting_date}
                onChange={handleKickoffDateChange}
                required
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{ sx: { backgroundColor: palette.gray.white } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="final-due-date"
                variant="outlined"
                type="date"
                label="Final Due Date *"
                labelVariant="labelLarge"
                placeholder="MM/DD/YYYY"
                helperText="Due Date"
                value={workspace.final_due_date}
                onChange={handleFinalDueDateChange}
                required
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{
                  inputProps: {
                    min: workspace?.kickoff_meeting_date?.length > 0 ? workspace?.kickoff_meeting_date : today,
                  },
                  sx: { backgroundColor: palette.gray.white },
                }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={5}>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="beginning-period-date"
                type="date"
                variant="outlined"
                label="Beginning of Period *"
                labelVariant="labelLarge"
                placeholder="MM/DD/YYYY"
                helperText="Beginning Period Date"
                value={workspace.beginning_of_period}
                onChange={handleBeginningPeriodDateChange}
                required
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{ inputProps: { max: today }, sx: { backgroundColor: palette.gray.white } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppTextField
                id="end-period-date"
                type="date"
                variant="outlined"
                label="End of Period *"
                labelVariant="labelLarge"
                placeholder="MM/DD/YYYY"
                helperText="End Period Date"
                value={workspace.end_of_period}
                onChange={handleEndPeriodDateChange}
                required
                sx={{ width: '100%', borderRadius: '4px' }}
                InputProps={{
                  inputProps: {
                    min: workspace?.beginning_of_period?.length > 0 ? workspace?.beginning_of_period : undefined,
                    max: today,
                  },
                  sx: { backgroundColor: palette.gray.white },
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="flex-end" marginTop={1}>
          <RedesignAppButton
            appVariant="filled"
            widePadding={true}
            disabled={!isFormValid}
            onClick={(event) => {
              void handleSubmit(event);
            }}
          >
            Save and Close
          </RedesignAppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateWorkspace;
