import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: '#005CBC',
      main: '#616161',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1192E8',
      contrastText: '#161616',
    },
    error: {
      main: '#D32F2F',
    },
    green: {
      '700': '#0E3E1B',
      '500': '#197A56',
      '400': '#21BF61',
      '300': '#A8F0B8',
      '200': '#DCF9E3',
      '100': '#EEFCF1',
      '50': '#F7FEF8',
      accent: '#7EF473',
      accent50: '#145C39',
      accent25: '#114D2A',
      hover1: '#33985D',
    },
    neutral: {
      '700': '#856E57',
      '500': '#AB947E',
      '400': '#C4B5A4',
      '300': '#DFD7CD',
      '200': '#F1EEEA',
      '100': '#F8F7F4',
    },
    blue: {
      '700': '#062B4B',
      '500': '#0A477D',
      '400': '#0977D7',
      '300': '#76B9F4',
      '200': '#E0F1FF',
    },
    red: {
      '700': '#660F09',
      '500': '#A1150C',
      '400': '#D82216',
      '300': '#F48067',
      '200': '#FCE1DC',
    },
    yellow: {
      '700': '#7E6011',
      '500': '#BD8F1A',
      '450': '#DEAF1F',
      '400': '#FFCF24',
      '300': '#FFE68F',
      '200': '#FFFBE8',
    },
    gray: {
      black: '#000000',
      '700': '#323232',
      '500': '#535353',
      '450': '#828282',
      '400': '#B1B1B1',
      '300': '#D4D4D4',
      '250': '#E6E6E6',
      '200': '#F8F8F8',
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica Neue'].join(','),
    displayLarge: {
      fontSize: '57px',
      fontWeight: 400,
      lineHeight: '64px',
      letterSpacing: '-0.25px',
    },
    displayMedium: {
      fontSize: '45px',
      fontWeight: 400,
      lineHeight: '52px',
    },
    displaySmall: {
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '44px',
    },
    displaySmallBold: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '44px',
    },
    headlineLarge: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    headlineMedium: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    headlineSmall: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    headlineSmallBold: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
    },
    titleLarge: {
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    titleMedium: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    titleMediumBold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    titleSmall: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    titleSmallBold: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    labelLarge: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    labelMedium: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    labelMediumBold: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    labelSmall: {
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    bodyLarge: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    bodyMedium: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    bodyMediumBold: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    bodySmall: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    buttonMedium: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '36px',
      letterSpacing: '0.5px',
    },
    buttonSmall: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    navBarTitle: {
      fontSize: '17px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    navBarStatus: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
  },
});

export default theme;
