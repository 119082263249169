import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { BranchAndATMCustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { handleOpenComments } from '../../utils/handleOpenComments';

const CustomerActivityInPhysicalChannels: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable1 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1,
    ),
  );
  const dataTable2 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(
      state,
      tabKey,
      BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable2,
    ),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const fetchFlag = useAppSelector((state) => state.fetchFlags.refetch);
  const {
    data: dataTableResponse1,
    isLoading: isLoadingDataTable1,
    refetch: refetchDataTable1,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1,
  });
  const {
    data: dataTableResponse2,
    isLoading: isLoadingDataTable2,
    refetch: refetchDataTable2,
  } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable2,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const table1Categories = useRef<Category[]>([
    { label: 'Branch', editable: true, indentLevel: 1 },
    { label: 'ATM', editable: true, indentLevel: 1 },
  ]);
  const table1Columns = useRef<Column[]>([
    { key: 'A', label: 'Number of customers active in the channel for 90 days(#)', editable: true },
    { key: 'B', label: 'of which also active in digital for 90 days (#)', editable: true },
    { key: 'C', label: 'Number of customers active in the channel for 30 days(#)', editable: true },
    { key: 'D', label: 'of which also active in digital for 30 days (#)', editable: true },
  ]);

  const table2Categories = useRef<Category[]>([
    { label: 'Number of branch visits', editable: true, indentLevel: 1 },
    { label: 'Number of unique authentications in ATM', editable: true, indentLevel: 1 },
  ]);
  const table2Columns = useRef<Column[]>([{ key: 'A', label: 'Total (#)', editable: true }]);

  useEffect(() => {
    if (!isLoadingDataTable1) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse1?.id ?? 0,
            commentCount: dataTableResponse1?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1,
            data: dataTableResponse1?.data ?? [],
            columns: dataTableResponse1?.columns ?? [],
            rows: dataTableResponse1?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D'],
          rowCount: 2,
        }),
      );
    }

    dataTableResponse1?.rows.forEach((rowName, index) => {
      table1Categories.current[index].rowName = rowName;
    });

    dataTableResponse1?.columns?.forEach((columnName, index) => {
      table1Columns.current[index].columnName = columnName;
    });

    if (!isLoadingDataTable2) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse2?.id ?? 0,
            commentCount: dataTableResponse2?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable2,
            data: dataTableResponse2?.data ?? [],
            columns: dataTableResponse2?.columns ?? [],
            rows: dataTableResponse2?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 2,
        }),
      );

      dataTableResponse2?.rows.forEach((rowName, index) => {
        table1Categories.current[index].rowName = rowName;
      });

      dataTableResponse2?.columns?.forEach((columnName, index) => {
        table1Columns.current[index].columnName = columnName;
      });
    }

    dataTableResponse2?.rows.forEach((rowName, index) => {
      table2Categories.current[index].rowName = rowName;
    });

    dataTableResponse2?.columns?.forEach((columnName, index) => {
      table2Columns.current[index].columnName = columnName;
    });
  }, [
    dispatch,
    dataTableResponse1,
    isLoadingDataTable1,
    dataTableResponse2,
    isLoadingDataTable2,
    tabKey,
    tableSubmoduleId,
  ]);

  useEffect(() => {
    const refetchAll = async () => {
      try {
        await Promise.all([refetchDataTable1(), refetchDataTable2()]);
      } catch (error) {
        console.log('Error in fetching data');
      }
    };

    refetchAll().catch((err) => {
      console.log(err);
    });
  }, [fetchFlag, refetchDataTable1, refetchDataTable2]);

  useEffect(() => {
    if (dataTable1 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable1.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable1.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable1, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable2 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable2.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable2.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable2, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const isCellDisabled = (category: Category, column: Column) => {
    if (category.label === 'ATM' && column.label === 'of which also active in digital for 90 days (#)') {
      return true;
    }
    if (category.label === 'ATM' && column.label === 'of which also active in digital for 30 days (#)') {
      return true;
    }
    return false;
  };

  const handleClick = (cellId: string, column: string, row: string, dataTableId: number | undefined) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTableId, { cellId, column, row });
    }
  };

  const handleValueChangeTable1 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1,
          values: {
            ...dataTable1?.values,
            [cellId]: newValue,
          },
        },
        columns: ['A', 'B', 'C', 'D'],
        rowCount: 2,
      }),
    );
  };
  const handleValueChangeTable2 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable2,
          values: {
            ...dataTable2?.values,
            [cellId]: newValue,
          },
        },
        columns: ['A'],
        rowCount: 2,
      }),
    );
  };

  const date1 = new Date(workspace!.end_of_period);
  date1.setDate(date1.getDate() - 90);
  const date2 = new Date(workspace!.end_of_period);
  date2.setDate(date2.getDate() - 30);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box>
        <TableComponent
          categories={table1Categories.current}
          columns={table1Columns.current}
          values={dataTable1?.values ?? {}}
          onValueChange={handleValueChangeTable1}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${date1.getFullYear()}-${String(date1.getMonth() + 1).padStart(2, '0')}-${String(date1.getDate()).padStart(2, '0')} ${'\u00A0'.repeat(42)} ${date2.getFullYear()}-${String(date2.getMonth() + 1).padStart(2, '0')}-${String(date2.getDate()).padStart(2, '0')}`}
          getRowStyle={() => {
            return { backgroundColor: palette.green[200] };
          }}
          isCellDisabled={isCellDisabled}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable1?.issues ?? []}
        />
      </Box>
      <Box
        sx={{
          width: '446px',
          backgroundColor: palette.gray[300],
          p: 2,
        }}
      >
        <TableComponent
          categories={table2Categories.current}
          columns={table2Columns.current}
          values={dataTable2?.values ?? {}}
          onValueChange={handleValueChangeTable2}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getRowStyle={() => {
            return { backgroundColor: palette.green[200] };
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable2?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default CustomerActivityInPhysicalChannels;
