import { List, ListItem, Tooltip, type TooltipProps, Typography, Zoom, styled, tooltipClasses } from '@mui/material';
import React, { type ReactElement } from 'react';
import { type Issue } from '../../../utils/types/Issue';

interface ConditionalTooltipWrapperProps {
  children: ReactElement;
  issues: Issue[];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.red[200],
    color: 'black',
  },
}));

const ConditionalTooltipWrapper: React.FC<ConditionalTooltipWrapperProps> = ({ children, issues }) => {
  if (issues === null || issues === undefined || issues.length === 0) return children;
  else
    return (
      <StyledTooltip
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 300 }}
        leaveDelay={200}
        title={
          <List sx={{ padding: '0px' }}>
            {issues?.map((issue, index) => (
              <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ marginBottom: '5px' }}>{issue.error_message}</Typography>
              </ListItem>
            ))}
          </List>
        }
        placement="top-start"
      >
        {children}
      </StyledTooltip>
    );
};

export default ConditionalTooltipWrapper;
