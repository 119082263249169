import { createContext, useCallback, useMemo, useState } from 'react';
import { Alert, type AlertColor, Snackbar } from '@mui/material';

interface ToastContextProviderProps {
  children: React.ReactNode;
}

interface ToastContextState {
  open: boolean;
  handleOpenToast: ({ message, severity }: { message: string; severity: AlertColor }) => void;
}

export const ToastContext = createContext<ToastContextState>({ open: true, handleOpenToast: () => {} });

const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('Success');

  const handleOpen = useCallback(({ message, severity }: { message: string; severity: AlertColor }) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const value = useMemo(() => ({ open, handleOpenToast: handleOpen }), [open, handleOpen]);

  return (
    <ToastContext.Provider value={value}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert severity={severity} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
