import { type Dispatch } from '@reduxjs/toolkit';
import { updateDataTableCommentThreads, updateDataTableIssues } from '../../../utils/redux/dataTablesSlice';

interface UpdateCommentThreadsParams {
  dataTableId: number | undefined;
  fetchDataTableCommentThreads: any;
  dispatch: Dispatch;
}

interface UpdatedIssuesParams {
  dataTableId: number | undefined;
  submoduleId: number;
  fetchDataTableIssues: any;
  dispatch: Dispatch;
}

export const getCommentThreads = async ({
  dataTableId,
  fetchDataTableCommentThreads,
  dispatch,
}: UpdateCommentThreadsParams): Promise<void> => {
  if (dataTableId === undefined) return;
  try {
    const { data: commentThreadData } = await fetchDataTableCommentThreads(dataTableId);
    if (commentThreadData != null) {
      dispatch(updateDataTableCommentThreads({ commentThreads: commentThreadData, dataTableId }));
    }
  } catch (error) {
    console.error(`Failed to fetch comment threads for data table`);
  }
};

export const getIssues = async ({ dataTableId, submoduleId, dispatch, fetchDataTableIssues }: UpdatedIssuesParams) => {
  if (dataTableId === undefined) return;
  try {
    const { data: issuesData } = await fetchDataTableIssues({
      submoduleId,
      dataTableId,
    });
    if (issuesData != null) {
      dispatch(updateDataTableIssues({ issues: issuesData, dataTableId }));
    }
  } catch (error) {
    console.error(`Failed to fetch issues for data table`);
  }
};
