import { Comment, Error, Fullscreen, Info } from '@mui/icons-material';
import { Box, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import RedesignAppButton from '../../../../../../../components/materials/actions/RedesignAppButton';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { setOpenComments } from '../../../../../../../utils/redux/dataTablesSlice';
import { SubmodulesEnum } from '../../../../../../../utils/types/SubmodulesEnum';
import DataTableTabs from './DataTableTabs';

interface StyledBoxProps {
  backgroundColor?: string;
  color?: string;
}

export const StyledChipContainer = styled(Box)<StyledBoxProps>(({ theme, backgroundColor, color }) => ({
  backgroundColor: backgroundColor ?? theme.palette.gray[200],
  color: color ?? theme.palette.gray.black,
  borderRadius: 8,
  display: 'flex',
  height: '20px',
  alignItems: 'center',
  padding: '4px 12px',
  gap: 6,
}));

interface Props {
  handleFullscreen: () => void;
}

const submodulesToGlossaryTabPath = {
  [SubmodulesEnum.ProductAndSegment]: 'revenues',
  [SubmodulesEnum.TypeAndFunction]: 'costs',
  [SubmodulesEnum.Customers]: 'customers-product',
  [SubmodulesEnum.ProductSales]: 'customers-product',
  [SubmodulesEnum.OnlineAndMobileCustomers]: 'digital-channels',
  [SubmodulesEnum.BranchAndATMCustomers]: 'physical-channels',
  [SubmodulesEnum.PersonalBankingFTE]: 'employee',
  [SubmodulesEnum.ContactCenter]: 'virtual-channels',
};

const DataTableHeader: React.FC<Props> = ({ handleFullscreen }) => {
  const { activeSubmodule } = useWorkspaceDataContext();
  const { palette } = useTheme();
  const dispatch = useDispatch();

  return (
    <Box display="flex" gap={1}>
      <DataTableTabs />
      <Box flexShrink={0} display="flex" alignItems="center" gap={2}>
        <Box display="flex" flexDirection="column" gap={1}>
          <StyledChipContainer backgroundColor={palette.red[200]} color={palette.red[700]}>
            <Error fontSize="small" sx={{ color: ({ palette }) => palette.red[500] }} />
            {(activeSubmodule?.issues ?? '0') + ' '}
            Issues
          </StyledChipContainer>
          <StyledChipContainer backgroundColor={palette.yellow[300]} color={palette.yellow[700]}>
            <Comment fontSize="small" sx={{ color: ({ palette }) => palette.yellow[500] }} />
            {(activeSubmodule?.comments ?? '0') + ' '}
            Comments
          </StyledChipContainer>
        </Box>
        <RedesignAppButton
          onClick={() => {
            window.open(
              `/app/resources/glossary/${submodulesToGlossaryTabPath[activeSubmodule?.name ?? SubmodulesEnum.ProductAndSegment]}`,
              '_blank',
            );
          }}
          appVariant="outlined"
          sx={{ gap: 1, py: 0.5, textWrap: 'nowrap' }}
        >
          <Info fontSize="small" sx={{ color: ({ palette }) => palette.green[500] }} />
          <Typography variant="buttonMedium" color={({ palette }) => palette.green[500]}>
            View Definitions
          </Typography>
        </RedesignAppButton>
        <RedesignAppButton
          onClick={() => {
            dispatch(setOpenComments(true));
          }}
          appVariant="outlined"
          sx={{ gap: 1, py: 0.5, textWrap: 'nowrap' }}
        >
          <Comment fontSize="small" sx={{ color: ({ palette }) => palette.green[500] }} />
          <Typography variant="buttonMedium" color={({ palette }) => palette.green[500]}>
            View Comments
          </Typography>
        </RedesignAppButton>
        <RedesignAppButton
          onClick={handleFullscreen}
          appVariant="outlined"
          sx={{ gap: 1, py: 0.5, textWrap: 'nowrap' }}
        >
          <Fullscreen fontSize="small" sx={{ color: ({ palette }) => palette.green[500] }} />
          <Typography variant="buttonMedium" color={({ palette }) => palette.green[500]}>
            Fullscreen View
          </Typography>
        </RedesignAppButton>
      </Box>
    </Box>
  );
};

export default DataTableHeader;
