import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Chip, Divider, IconButton, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppButton from '../../../components/materials/actions/AppButton';
import ConfirmationModal from '../../../components/materials/containers/ConfirmationModal';
import { ToastContext } from '../../../context/toastContext';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { useDeleteThemeMutation, usePublishThemeMutation } from '../../../utils/redux/api';
import { selectIsUserAdmin } from '../../../utils/redux/authSlice';
import {
  clearSelectedKeyThemeId,
  deleteKeyThemeById,
  selectCurrentKeyTheme,
  setEditingKeyTheme,
  updateKeyThemeById,
} from '../../../utils/redux/keyThemeSlice';
import { KeyThemeItemType, KeyThemeStatus } from '../../../utils/types/KeyTheme';
import KeyThemeItemCardContainer from './KeyThemeItemCardContainer';
import SupportingDataCarousel from './SupportingDataCarousel';

interface Props {
  handleDeleteTheme: (themeId: number) => Promise<void>;
}

const KeyThemeView: React.FC<Props> = ({ handleDeleteTheme }) => {
  const selectedKeyTheme = useAppSelector((state) => selectCurrentKeyTheme(state));
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [deleteThemeTrigger] = useDeleteThemeMutation();
  const [publishThemeTrigger] = usePublishThemeMutation();
  const [modal, setModal] = useState({ open: false, title: '', text: '', confirmText: '', onConfirm: () => {} });
  const { handleOpenToast } = useContext(ToastContext);

  const userIsAdmin = useAppSelector((state) => selectIsUserAdmin(state));

  if (selectedKeyTheme === null || (selectedKeyTheme.status === KeyThemeStatus.Draft && !userIsAdmin)) return;
  const deleteTheme = async (themeId: number) => {
    try {
      await deleteThemeTrigger(themeId).unwrap();
      dispatch(deleteKeyThemeById({ id: themeId }));
      dispatch(clearSelectedKeyThemeId());
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not delete the theme',
      });
    }
  };

  const publishTheme = async (themeId: number) => {
    try {
      await publishThemeTrigger(themeId).unwrap();
      dispatch(
        updateKeyThemeById({
          ...selectedKeyTheme,
          status: KeyThemeStatus.Published,
        }),
      );
      handleOpenToast({
        severity: 'success',
        message: 'Theme published successfully',
      });
    } catch (error) {
      handleOpenToast({
        severity: 'error',
        message: 'Could not publish the theme',
      });
    }
  };

  const resetModal = () => {
    setModal({ open: false, title: '', text: '', confirmText: '', onConfirm: () => {} });
  };
  if (selectedKeyTheme === null) return;
  return (
    <>
      <Box display="flex" justifyContent="space-between" m={3}>
        <Box display="flex" alignItems="center" columnGap={2}>
          <Typography variant="headlineLarge" color={palette.green[700]}>
            {selectedKeyTheme.title}
          </Typography>
          <Chip size="small" label={selectedKeyTheme.status} variant="outlined" />
        </Box>
        {userIsAdmin && (
          <Box>
            <IconButton
              onClick={() => {
                setModal({
                  open: true,
                  title: 'Do you really want to delete this Key Theme?',
                  text: 'This action cannot be undone.',
                  confirmText: 'Delete',
                  onConfirm: () => {
                    void deleteTheme(selectedKeyTheme.id);
                  },
                });
              }}
              size="small"
              sx={{ mt: '4px', color: palette.green[500] }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                dispatch(setEditingKeyTheme(selectedKeyTheme));
              }}
              size="small"
              sx={{ mt: '4px', color: palette.green[500] }}
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Divider />
      <Box m={3}>
        <KeyThemeItemCardContainer items={selectedKeyTheme.insights} itemType={KeyThemeItemType.Insight} />

        <SupportingDataCarousel />

        <KeyThemeItemCardContainer items={selectedKeyTheme.opportunities} itemType={KeyThemeItemType.Opportunity} />
        <Box display="flex" mt={2} columnGap={2} justifyContent="flex-end">
          {selectedKeyTheme.status === KeyThemeStatus.Draft && userIsAdmin && (
            <AppButton
              sx={{
                backgroundColor: palette.green[500],
                borderRadius: '100px',
                '&:hover, &:active, &:focus': { backgroundColor: palette.green.accent50 },
              }}
              variant="contained"
              onClick={() => {
                setModal({
                  open: true,
                  title: 'Do you really want to publish this Key Theme?',
                  text: 'The Key Theme will become visible to the client',
                  confirmText: 'Publish',
                  onConfirm: () => {
                    void publishTheme(selectedKeyTheme.id);
                  },
                });
              }}
            >
              Publish
            </AppButton>
          )}
        </Box>
        <ConfirmationModal
          isOpen={modal.open}
          title={modal.title}
          text={modal.text}
          confirmText={modal.confirmText}
          onClose={() => {
            resetModal();
          }}
          onSave={() => {
            setModal({ ...modal, open: false });
            modal.onConfirm();
          }}
        />
      </Box>
    </>
  );
};

export default KeyThemeView;
