import { type FunctionComponent } from 'react';
import { type SxProps, type Theme } from '@mui/system';
import { styled } from '@mui/material/styles';
import Container, { type ContainerProps } from '@mui/material/Container';

interface AppLayoutProps {
  backgroundColor?: string;
  disableGutters?: boolean;
  sx?: SxProps<Theme>;
  children: JSX.Element | JSX.Element[];
}

const CustomContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  '&.MuiContainer-root': {
    width: '100%',
    maxWidth: 'none',
    marginLeft: '0',
    marginRight: '0',
    paddingLeft: '0',
    paddingRight: '0',
    boxSizing: 'border-box',
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: '100%', // Ensures that it takes full width on extra small devices
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '540px', // Optimal width for small devices
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '720px', // Optimal width for medium devices
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '960px', // Optimal width for large devices
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1200px', // Optimal width for extra large devices
  },
}));

const AppLayout: FunctionComponent<AppLayoutProps> = ({
  backgroundColor = '#F1EEEA',
  disableGutters,
  sx,
  children,
}) => {
  const customSx: SxProps<Theme> = {
    overflowX: 'hidden',
    backgroundColor,
    minHeight: '100vh',
    ...sx,
  };

  return (
    <CustomContainer disableGutters={disableGutters} sx={customSx}>
      {children}
    </CustomContainer>
  );
};

export default AppLayout;
