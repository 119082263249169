import React from 'react';
import { Box, type BoxProps, useTheme } from '@mui/material';

interface FormBoxProps extends BoxProps {
  children: React.ReactNode;
}

const FormBox: React.FC<FormBoxProps> = (props) => {
  const { palette } = useTheme();
  const { children, ...rest } = props;

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: palette.gray.white,
        padding: (theme) => `${theme.spacing(5)}`,
        gap: (theme) => theme.spacing(4),
        border: `1px solid ${palette.gray[300]}`,
        borderRadius: '8px',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FormBox;
