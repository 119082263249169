/**
 * Sets the redeem timestamp for a ticket in the session storage.
 * @param timestamp - The redeem timestamp to set.
 * @param workspaceId - The ID of the workspace.
 */
const setTicketRedeemTimestamp = (timestamp: number, workspaceId: number): void => {
  const sessionData = { [workspaceId]: timestamp };
  sessionStorage.setItem('tableauTimestamps', JSON.stringify(sessionData));
};
export default setTicketRedeemTimestamp;
