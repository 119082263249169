import { Add } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Chip, Divider, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppButton from '../../../components/materials/actions/AppButton';
import { ToastContext } from '../../../context/toastContext';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { useCreateThemeMutation } from '../../../utils/redux/api';
import { selectIsUserAdmin } from '../../../utils/redux/authSlice';
import {
  addKeyThemes,
  clearIsEditingKeyTheme,
  setEditingKeyTheme,
  setSelectedKeyThemeId,
} from '../../../utils/redux/keyThemeSlice';
import { KeyThemeStatus } from '../../../utils/types/KeyTheme';

const KeyThemeList: React.FC = () => {
  const { selectedKeyThemeId, keyThemes } = useAppSelector((state) => state.keyThemes);
  const { workspaceId } = useParams();
  const [createTheme] = useCreateThemeMutation();
  const userIsAdmin = useAppSelector((state) => selectIsUserAdmin(state));

  const { handleOpenToast } = useContext(ToastContext);

  const { palette } = useTheme();
  const dispatch = useDispatch();

  const filteredKeyThemes = userIsAdmin
    ? keyThemes
    : keyThemes.filter((keyTheme) => keyTheme.status === KeyThemeStatus.Published);

  const getEmptyListMessage = () =>
    userIsAdmin
      ? 'You have not added any themes yet. Would you like to add a theme?'
      : 'No themes published for this workspace.';

  return (
    <Box sx={{ width: '20%' }}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '8px',
        }}
      >
        <CardHeader title="Key Themes" />
        <Divider />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {keyThemes.length === 0 ? (
            <Typography variant="bodyLarge">{getEmptyListMessage()}</Typography>
          ) : (
            filteredKeyThemes.map((keyTheme) => {
              const isSelected = selectedKeyThemeId === keyTheme.id;
              return (
                <Card
                  variant="outlined"
                  key={keyTheme.id}
                  sx={{
                    width: '100%',
                    backgroundColor: isSelected ? palette.green[400] : palette.green[500],
                    border: '1px solid',
                    borderColor: isSelected ? palette.green[300] : palette.green[400],
                    color: 'white',
                    mb: '15px',
                  }}
                  onClick={() => {
                    dispatch(setSelectedKeyThemeId(keyTheme.id));
                    dispatch(clearIsEditingKeyTheme());
                  }}
                >
                  <CardContent sx={{ p: '7px 7px 16px 16px' }}>
                    <Box display="flex">
                      <Chip
                        size="small"
                        sx={{ color: ({ palette }) => palette.gray.white, ml: 'auto' }}
                        label={keyTheme.status}
                        variant="outlined"
                      />
                    </Box>
                    <Typography variant="titleLarge">{keyTheme.title}</Typography>
                  </CardContent>
                </Card>
              );
            })
          )}
          {userIsAdmin && (
            <AppButton
              variant="contained"
              sx={{
                mt: 4,
                backgroundColor: palette.green[500],
                borderRadius: '100px',
                '&:hover, &:active, &:focus': { backgroundColor: palette.green.accent50 },
              }}
              onClick={async () => {
                try {
                  const response = await createTheme({
                    theme_name: 'Theme Name',
                    workspace_id: Number(workspaceId),
                  }).unwrap();
                  dispatch(addKeyThemes(response));
                  dispatch(setEditingKeyTheme(response));
                  dispatch(setSelectedKeyThemeId(response.id));
                } catch {
                  handleOpenToast({
                    message: 'Error creating new Key Theme, try again later or contact support',
                    severity: 'error',
                  });
                }
              }}
              startIcon={<Add />}
            >
              Add a theme
            </AppButton>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default KeyThemeList;
