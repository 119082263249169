import React, { useCallback, useContext, useEffect, useState } from 'react';
import AppLayout from '../../components/materials/containers/AppLayout';
import AccountHeader from '../../components/materials/navigation/AccountHeader';
import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import FormBox from './components/FormBox';
import AppTextField from '../../components/materials/forms/AppTextField';
import AppButton from '../../components/materials/actions/AppButton';
import { useRequestResetPasswordMutation } from '../../utils/redux/api';
import { ToastContext } from '../../context/toastContext';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const debounce = (func: () => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function executedFunction() {
    const later = () => {
      clearTimeout(timeout);
      func();
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const secondsToMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

const RequestResetPassword: React.FC = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState('');
  const { palette } = useTheme();

  const [requestPsRequest] = useRequestResetPasswordMutation();

  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    setIsFormValid(emailRegex.test(email));
  }, [email]);

  const [countdown, setCountdown] = useState<number>(0);
  const [countdownMessage, setCountdownMessage] = useState<string>('');
  const { handleOpenToast } = useContext(ToastContext);

  // countdown timer
  useEffect(() => {
    if (submitDisabled) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            setSubmitDisabled(false);
            return 300;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [submitDisabled]);

  const handleEmailChange = useCallback(() => {
    setEmail(inputRef.current?.value ?? '');
  }, [inputRef]);

  const debouncedHandleEmailChange = useCallback(() => debounce(handleEmailChange, 500), [handleEmailChange]);

  const handleRequestSubmitted = () => {
    setRequestLoading(false);
    setSubmitDisabled(true);
    setCountdown(60);
    setCountdownMessage(
      'Request submitted. Please check your email for the reset password link. You can request again in:',
    );
  };

  const handleRequestError = (error: any) => {
    setRequestLoading(false);
    const toastMessage = error?.data?.message ?? 'An error occurred';
    const expiresAt = error?.data?.details?.expires_at;
    if (expiresAt !== null && expiresAt !== undefined) {
      const secondsToExpire =
        Math.floor(new Date(expiresAt as Date).getTime() / 1000) - Math.floor(new Date().getTime() / 1000);
      setCountdown(secondsToExpire);
    } else {
      setCountdown(10);
    }
    setSubmitDisabled(true);
    setCountdownMessage('An error occurred. Please try again in:');
    handleOpenToast({
      severity: 'error',
      message: toastMessage,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    setRequestLoading(true);
    try {
      await requestPsRequest(formData).unwrap();
      handleRequestSubmitted();
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  return (
    <AppLayout>
      <AccountHeader variant="page" />
      <Container component="main" maxWidth="sm">
        <FormBox>
          <Typography variant="displaySmall" sx={{ color: palette.green[700] }}>
            Recover Password
          </Typography>
          <Divider sx={{ width: '100%' }} />
          <Typography variant="bodyLarge" sx={{ color: palette.gray.black }}>
            Enter your username (email) to request a reset password link to your account.
          </Typography>
          <Divider sx={{ width: '100%' }} />
          <Box component={'form'} noValidate autoComplete="off" sx={{ width: '100%' }}>
            <AppTextField
              InputProps={{ inputRef }}
              id="email"
              label="Email"
              placeholder="Enter your email"
              variant="outlined"
              required
              onChange={debouncedHandleEmailChange()}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px' }}
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 0 }}>
              <AppButton
                variant="contained"
                loading={requestLoading}
                disabled={submitDisabled || !isFormValid}
                sx={{
                  mt: 4,
                  backgroundColor: palette.green[500],
                  borderRadius: '100px',
                  '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
                }}
                onClick={(event) => {
                  void handleSubmit(event);
                }}
              >
                <Typography variant="buttonMedium">Request Link</Typography>
              </AppButton>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 0, mt: 2 }}>
              <Typography variant="bodySmall" sx={{ color: palette.gray.black }}>
                {submitDisabled ? `${countdownMessage} ${secondsToMinutes(countdown)}` : ''}
              </Typography>
            </Box>
          </Box>
        </FormBox>
      </Container>
    </AppLayout>
  );
};

export default RequestResetPassword;
