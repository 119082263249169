import React from 'react';

const tableauHost = process.env.REACT_APP_TABLEAU_SERVER_HOST;
const site = process.env.REACT_APP_TABLEAU_SERVER_SITE;
const workbook = process.env.REACT_APP_TABLEAU_SERVER_WORKBOOK;

export interface ITableauAuthenticatorProps {
  onLoadTableau?: () => void;
  isTicketSuccess?: boolean;
  trustedTicket?: string;
}

const TableauAuthenticator: React.FC<ITableauAuthenticatorProps> = ({
  onLoadTableau,
  isTicketSuccess,
  trustedTicket,
}) => {
  const tableauUrl = `${tableauHost}/trusted/${trustedTicket}/t/${site}/views/${workbook}/Totalrevenuepercustomer?:embed=y&:showVizHome=no&:tabs=no`;

  return isTicketSuccess ?? false ? (
    <iframe
      title="tableau-dashboard"
      src={tableauUrl}
      width="100%"
      height="100%"
      style={{ display: 'none' }}
      onLoad={onLoadTableau ?? (() => {})}
    />
  ) : null;
};

export default TableauAuthenticator;
