import { rebexApi } from './baseRebexEndpoints';
import type { IChatResponse } from '../../types/ChatBot'; // Import the interface for type safety

export const chatbotApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    // Send a message to the chatbot and expect an IChatResponse
    sendMessage: builder.mutation<IChatResponse, { message: any }>({
      query: (payload) => ({
        url: '/chatbot/newrequest',
        method: 'POST',
        body: payload, // Send the message in the request body
      }),
    }),
  }),
});

// Export hooks for usage in components
export const { useSendMessageMutation } = chatbotApi;
