const kpiMapping: Record<string, string | null> = {
  // Revenues
  //  Revenue per customer
  'total-revenue-per-customer': 'Totalrevenuepercustomer',
  'proportion-of-mass-market-vs-affluent-revenue': 'Proportionofmasvsaffluentcustomersandrevenue',
  'net-interest-income-vs-fee-income': '1_Netinterestincomevsfeeincome',
  //  Product revenue
  'deposit-products': 'Tab1_RevGenbyDeposit',
  'credit-products': 'Tab1_RevGenbyCredit',
  'investment-and-insurance': 'RevgenbyInvestment_Insurance',

  // Costs
  //  Cost per customer (cost type)
  'efficiency-ratio-and-total-cost': 'Cost_FinancialPerformance',
  'cost-per-customer': 'Cost_TotalCost',
  //  Marketing costs
  'marketing-spend-by-channel': 'Cost_MktspendbyChannel',
  'marketing-spend-on-sales-promotion': 'Cost_MktspendonSalespromotion',
  //  Distribution of FTEs
  'fte-organizational-model': 'Cost_FTEOrganizationalmodel',
  //  Personnel costs
  'personnel-cost-overview': 'Cost_Personnelcostoverview',
  'branch-fte-compensation': 'Cost_BranchFTEsCompensation',
  'call-center-fte-compensation': 'Cost_CallCenterFTEs',
  'operations-fte-compensation': 'Cost_OperationsFTE',
  //  Operations
  'operations-productivity': 'Cost_OperationsProductivity',
  //  Cost model (cost function)
  'cost-per-1m-customers': 'Cost_Costmodel',

  // Customers
  //  Customer base distribution
  'customer-distribution-overview': 'Customer_Custbasecomposition',
  'customer-distribution-by-age': 'Customer_AgeCustDistribution',
  'customer-distribution-by-product-holding': 'Customer_ProdHoldingCustDistribution',
  'customer-distribution-by-tenure': 'Customer_TenureCustDistribution',
  //  Customer net growth
  'retail-customer': 'Customer_CustomerNetGrowth',
  //  Customer acquisition
  'overall-acquisition': 'Customer_Retailcustomeracquisition',
  'customer-acquisition-by-age': 'Customer_Custacquisitionbyage',
  'customer-acquisition-by-product-holding': 'Customer_Custacquisitionbyprodholding',
  'digitally-acquired-new-to-bank-customers': 'Customer_Digitalacquisition',
  //  Attrition
  'overall-attrition': 'Customer_CustomerAttrition',
  'customer-attrition-by-age': 'Customer_Custattritionbyage',
  'customer-attrition-by-product-holding': 'Customer_Custattritionbyprodholding',
  'customer-attrition-by-tenure': 'Customer_Custattritionbytenure',

  // Products
  //  Product penetration
  'penetration-by-product-type': 'Product_ProdPenetrationbyCustType',
  'penetration-by-age': 'Product_DepProdPenetrationbyage',
  'accounts-per-customer': 'Product_AcctRetailCust',
  //  Overall sales
  'new-account-per-customer-and-fte': 'Product_NewacctpercustandFTE',
  'sales-per-channel-by-product': 'Product_SalesperChannelTotal',
  'account-growth': 'Product_AccountGrowth',

  // Digital Channels
  //  Digital enrollment
  'share-of-customers-enrolled': 'Digital_ShareofCustomersenrolled',
  //  Customer activity 90 days
  '90-day-activity-total-customers': 'Digital_90daytotcustomers',
  '90-day-activity-primary-customers': 'Digital_90dayPrimarycustomers',
  '90-day-activity-in-mobile-by-age-segment': 'Digitial_90_Mobile_Active',
  '90-day-activity-in-online-by-age-segment': 'Digitial_90_Online_Active',
  //  Customer activity 30 days
  '30-day-activity-total-customers': 'Digital_30daytotcustomers',
  '30-day-activity-primary-customers': 'Digital_30dayprimarycustomers',
  '30-day-activity-newly-enrolled-customers': 'Digital_30dayNewenrolcust',
  //  Customer log ins
  'logins-per-enrolled-customer': 'Digital_Loginsperenrolcust',
  'use-of-digital-features': 'Digital_Customersusingdigital',
  //  Transactions
  'percentage-of-digital-transactions': 'Digital_MobandOnlinetransactions',
  'transactions-mix': 'Digital_Transactionmixacrosschannels',
  'transactions-per-active-customer': 'Digital_Trans90dayactivecust',
  //  Sales
  'percentage-of-sales-in-digital': 'Digital_ofsalesindigital',
  'checking account sales funnel': 'Digital_SalesfunnelforCA',
  'quality-of-sales': 'Digital_QualityofSales',
  //  Digital servicing
  'chatbot-usage': 'Digital_ChatbotUsage',

  // Physical Channels
  //  Customers per branch and ATM
  'customers-per-branch-and-atm': 'Physical_CustperBranchandATm',
  //  Customer activity
  '90-day-customer-activity-branch': 'Physical_Branch_90-daycustactivity',
  '90-day-customer-activity-atm': 'Physical_atm_90-daycustactivity',
  '30-day-customer-activity-branch': 'Physical_Branch_30-daycustactivity',
  '30-day-customer-activity-atm': 'Physical_atm_30-daycustactivity',
  'branch-and-atm-visits': 'Physical_BranchandATMvisits',
  //  Transactions
  'percentage-of-branch-and-atm-transactions': 'Physical_BranchandATMTransaction',
  'transaction-mix-by-channel': 'Digital_Transactionmixacrosschannels',
  'transactions-per-branch-and-atm-per-week': 'Physical_BranchandATMTransactionperweek',
  //  Sales
  'branch-sales-per-product': 'Physical_Branchsalesperproduct',
  'branch-sales-staff-productivity': 'Physical_BranchSalesStaffproductivity',
  'quality-of-sales-in-branch-vs-digital': 'Digital_QualityofSales',
  //  Branch efficiency
  'branch-fte-per-branch': 'Physical_BranchFTEperBranch',

  // Call Center Channels
  //  Number of call centers
  'number-of-call-centers': 'CallCenter_NumberofCallCenters',
  //  Customer activity
  '90-day-customer-activity': 'CallCenter_Customeractivity90days',
  '30-day-customer-activity': 'CallCenter_Customeractivity30days',
  //  Transactions
  'customer-initiated-transactions': 'CallCenter_Callcentertransactions',
  'calls-per-customer': 'CallCenter_Customertouchpoints',
  'transactions-per-active-customer-per-week': 'CallCenter_Transperactiveccperweek',
  //  Sales
  'percentage-of-sales-in-call-center': 'CallCenter_Salesproductivity',
  //  Call center servicing
  'service-efficiency': 'CallCenter_Servicing',
};

const kpiNameToTableauViewMapper = (kpiName: string) => {
  return kpiName in kpiMapping ? kpiMapping[kpiName] : null;
};

export default kpiNameToTableauViewMapper;
