import React, { Fragment, useEffect, useState } from 'react';
import classes from './ChatBox.module.css';
import { CircularProgress } from '@mui/material';
import ReactECharts from 'echarts-for-react';

interface IBarChartProps {
  categories: string[] | undefined;
  data: number[] | undefined;
}

const ChatBarChart: React.FC<IBarChartProps> = ({ categories, data }) => {
  const [chartoptions, setChartOptions] = useState<any>({});

  useEffect(() => {
    // Explicit zero-length check to avoid lint issues
    if (categories !== undefined && categories.length > 0 && data !== undefined && data.length > 0) {
      setChartOptions({
        legend: {
          show: true,
        },
        tooltip: {
          trigger: 'axis', // Enable default tooltip
        },
        color: ['#00796b'],
        xAxis: {
          type: 'category',
          data: categories, // No need for `&&` since this is always an array
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: data, // No need for `&&` since this is always an array
            type: 'bar',
          },
        ],
      });
    }
  }, [categories, data]);

  return (
    <Fragment>
      <ReactECharts
        option={chartoptions}
        style={{ height: '400px', width: '100%' }}
        className={classes.chart_wrapper}
      />
    </Fragment>
  );
};

export default ChatBarChart;
