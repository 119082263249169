import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { kpiResourceTypeToLabel, type KeyThemeResourceType } from '../../../utils/types/KeyTheme';
import ResourcesUpload from './ResourcesUpload';

interface Props {
  resourceType: KeyThemeResourceType;
  onNewResource: (id: string, resource: FormData) => void;
}

const FileUploadContainer: React.FC<Props> = ({ resourceType, onNewResource }) => {
  const { palette } = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={palette.gray.white}
      borderRadius="8px"
      border={`1px solid ${palette.gray[250]}`}
      p={4}
      gap={2}
    >
      <Typography variant="titleLarge">{kpiResourceTypeToLabel[resourceType]} Pages</Typography>
      <Typography variant="bodyLarge">
        You can upload {kpiResourceTypeToLabel[resourceType]} pages to display. Supported file types include{' '}
        <b> JPG, PNG,</b> and <b>PDF</b>.
      </Typography>
      <ResourcesUpload resourceType={resourceType} onNewResource={onNewResource} />
    </Box>
  );
};

export default FileUploadContainer;
