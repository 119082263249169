import React from 'react';
import {
  TextField,
  FormControl,
  type SxProps,
  type Theme,
  Typography,
  type TypographyProps,
  type InputProps,
  useTheme,
} from '@mui/material';

interface AppTextFieldProps {
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  variant: 'standard' | 'outlined' | 'filled';
  error?: boolean;
  helperText?: string;
  required?: boolean;
  select?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  labelVariant?: TypographyProps['variant'];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  InputProps?: InputProps & { inputProps?: React.InputHTMLAttributes<HTMLInputElement> };
}

const AppTextField: React.FC<AppTextFieldProps> = ({
  id,
  label,
  type = 'text',
  placeholder,
  value,
  variant,
  error = false,
  helperText = '',
  required = false,
  select = false,
  sx,
  labelVariant,
  disabled = false,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  InputProps,
  children,
}) => {
  const { palette } = useTheme();
  return (
    <FormControl fullWidth error={error} sx={sx}>
      <Typography variant={labelVariant} sx={{ marginBottom: 1, color: error ? 'error.main' : palette.gray.black }}>
        {label}
      </Typography>
      <TextField
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        error={error}
        variant={variant}
        required={required}
        select={select}
        fullWidth
        helperText={helperText}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        InputProps={InputProps}
        SelectProps={{
          displayEmpty: true,
          renderValue:
            value !== '' ? undefined : () => <span style={{ color: palette.gray[400] }}>{placeholder}</span>,
        }}
      >
        {children}
      </TextField>
    </FormControl>
  );
};

export default AppTextField;
