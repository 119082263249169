import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';

import ResourcesWrapper from '../components/ResourcesWrapper';
import { faqSections } from './faqContent';

const RedesignedFAQ: React.FC = () => {
  const faqData = faqSections;

  return (
    <ResourcesWrapper title="FAQ" subtitle="Frequently Asked Questions can be found here.">
      {faqData.map((faqSection, idx) => (
        <Accordion
          key={idx}
          disableGutters={true}
          sx={{
            boxShadow: 'none',
            '&:not(:first-of-type)': { paddingTop: '24px' },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="titleLarge">{faqSection.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {faqSection.questions.map((question, idx) => (
              <Box
                key={idx}
                sx={{
                  '&:first-of-type': {
                    paddingTop: '24px',
                  },
                  '&:not(:last-of-type)': {
                    paddingBottom: '24px',
                  },
                }}
              >
                <Typography variant="titleSmall">
                  {question.number}. {question.prompt}
                </Typography>
                <p>{question.answer}</p>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </ResourcesWrapper>
  );
};

export default RedesignedFAQ;
