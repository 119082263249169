import { Search } from '@mui/icons-material';
import { Autocomplete, Box, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { UserRoles } from '../../../../../utils/types/User';
import { type UserFiltered } from './UsersTableRow';

const searchUserInputDetails = {
  label: 'Search Name or E-mail Address',
};

const filterByRolesInputDetails = {
  label: 'Filter by Role',
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<User extends keyof any>(order: 'asc' | 'desc', orderBy: User): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface TableFiltersInterface {
  users: UserFiltered[] | undefined;
  order: 'asc' | 'desc';
  orderBy: string;
  setDisplayUsers: React.Dispatch<React.SetStateAction<UserFiltered[] | undefined>>;
}

export const TableFilters: React.FC<TableFiltersInterface> = ({ users, order, orderBy, setDisplayUsers }) => {
  const [userSearch, setUserSearch] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<UserRoles | null>(null);

  const filteredData = React.useMemo(() => {
    if (users !== undefined) {
      return [...users]
        .filter((row) =>
          row.email !== undefined && row.firstName !== undefined && row.lastName !== undefined
            ? row.email?.toLowerCase().includes(userSearch.toLowerCase()) ||
              row.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
              row.lastName?.toLowerCase().includes(userSearch.toLowerCase())
            : false,
        )
        .filter((row) => (selectedRole !== null ? row.role !== undefined && row.role === selectedRole : true))
        .sort(getComparator(order, orderBy));
    } else {
      return [];
    }
  }, [users, userSearch, selectedRole, order, orderBy]);

  useEffect(() => {
    setDisplayUsers(filteredData);
  }, [filteredData, setDisplayUsers]);

  return (
    <Box display="flex" flexDirection="row" sx={{ gap: 3 }} alignItems={'center'}>
      <FormControl fullWidth sx={{ width: '280px' }}>
        <InputLabel htmlFor="outlined-adornment-amount">{searchUserInputDetails.label}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          label={searchUserInputDetails.label}
          value={userSearch}
          onChange={(e) => {
            setUserSearch(e.target.value);
          }}
        />
      </FormControl>

      <Autocomplete
        disablePortal
        value={selectedRole}
        options={Object.values(UserRoles) as UserRoles[]}
        onChange={(e, selected) => {
          setSelectedRole(selected);
        }}
        getOptionLabel={(option) => {
          switch (option) {
            case UserRoles.ADMIN:
              return 'Admin';
            case UserRoles.DATA_LEAD:
              return 'Data Lead';
            case UserRoles.DATA_COLLECTOR:
              return 'Data Collector';
            default:
              return '';
          }
        }}
        renderInput={(params) => <TextField {...params} label={filterByRolesInputDetails.label} />}
        sx={{ width: '210px' }}
      />
    </Box>
  );
};
