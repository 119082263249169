import { rebexApi } from './baseRebexEndpoints';

interface UploadExcelTemplateRequest {
  workspaceId: string;
  excelFile: File;
}

const excelBaseRoute = 'excel';

export const extendedExcelApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadExcelTemplate: builder.mutation<string, UploadExcelTemplateRequest>({
      query: ({ workspaceId, excelFile }) => {
        const formData = new FormData();
        formData.append('excel_file', excelFile);

        return {
          url: `${excelBaseRoute}/upload-excel/${workspaceId}`,
          method: 'POST',
          body: formData,
          contentType: 'multipart/form-data',
        };
      },
    }),
  }),
});
