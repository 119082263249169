import React, { createContext, useContext, useEffect } from 'react';
import { ToastContext } from '../../../context/toastContext';
import getTicketRedeemTimestamp from '../helpers/getTicketRedeemTimestamp';
import { useRetrieveTableauTrustedTicketQuery } from '../../../utils/redux/api';
import { useParams } from 'react-router-dom';
import setTicketRedeemTimestamp from '../helpers/setTicketRedeemTimestamp';

interface TableauContextType {
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  trustedTicket: string | undefined;
}

interface TableauProviderProps {
  children: React.ReactNode;
}

export const TableauAuthContext = createContext<TableauContextType | undefined>(undefined);

export const TableauAuthProvider: React.FC<TableauProviderProps> = ({ children }) => {
  /* tableau */
  const { handleOpenToast } = useContext(ToastContext);
  const { workspaceId } = useParams<{ workspaceId: string }>();

  // we should only fetch the ticket if we have redeemed it 10 hours ago or more
  const timestamp = getTicketRedeemTimestamp(Number(workspaceId));
  const shouldSkip = timestamp !== null && Date.now() - timestamp.getTime() < 1000 * 60 * 60 * 3;

  const {
    data,
    isSuccess: isTicketSuccess,
    isError: isTicketError,
    isLoading: isTicketLoading,
    error: ticketError,
  } = useRetrieveTableauTrustedTicketQuery(Number(workspaceId), {
    skip: shouldSkip,
  });
  const { trusted_ticket: trustedTicket } = data ?? {};

  useEffect(() => {
    if (isTicketError) handleOpenToast({ message: JSON.stringify(ticketError), severity: 'error' });
  }, [isTicketError, ticketError, handleOpenToast]);

  useEffect(() => {
    if (isTicketSuccess) {
      setTicketRedeemTimestamp(Date.now(), Number(workspaceId));
    }
  }, [isTicketSuccess, trustedTicket, workspaceId]);

  return (
    <TableauAuthContext.Provider
      value={{ isSuccess: isTicketSuccess, isError: isTicketError, isLoading: isTicketLoading, trustedTicket }}
    >
      {children}
    </TableauAuthContext.Provider>
  );
};
