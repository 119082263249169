import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import SideMenu from './components/Menu/SideMenu';
import Topbar from './components/Menu/Topbar';

const Redesign: React.FC = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <Box width="100%" height="100%">
      <Topbar
        handleOpenSideMenu={() => {
          setSideMenuOpen(true);
        }}
      />
      <SideMenu
        isOpen={sideMenuOpen}
        handleCloseSideMenu={() => {
          setSideMenuOpen(false);
        }}
      />
      <Box px={4} py={2}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Redesign;
