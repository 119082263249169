import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import React from 'react';
import AppButton from '../actions/AppButton';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
  onSave: () => void;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  text,
  confirmText,
  cancelText,
  onSave,
  onClose,
}) => {
  const { palette } = useTheme();
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth sx={{ height: 'auto' }}>
      <DialogTitle sx={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px', alignContent: 'left' }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '22px', alignContent: 'left' }}>
        {text}
      </DialogContent>
      <DialogActions>
        <AppButton
          variant="outlined"
          sx={{
            borderColor: palette.green[500],
            color: palette.gray.white,
            borderRadius: '100px',
            '&:hover, &:active, &:focus': { borderColor: palette.green[500] },
            m: 2,
          }}
          onClick={onClose}
        >
          <Typography variant="bodyMedium" sx={{ color: palette.green[500] }}>
            {cancelText ?? 'Cancel'}
          </Typography>
        </AppButton>
        <AppButton
          variant="contained"
          sx={{
            backgroundColor: palette.green[500],
            color: palette.gray.white,
            borderRadius: '100px',
            m: 2,
          }}
          onClick={onSave}
        >
          <Typography variant="bodyMedium">{confirmText ?? 'Save'}</Typography>
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
