import { Button, useTheme, type ButtonProps } from '@mui/material';
import { forwardRef } from 'react';

interface Props extends ButtonProps {
  appVariant: 'filled' | 'outlined';
  widePadding?: boolean;
}

const RedesignAppButton = forwardRef<HTMLButtonElement, Props>(
  ({ appVariant, widePadding = false, startIcon, children, sx, ...props }, ref) => {
    const { palette } = useTheme();
    return (
      <Button
        ref={ref}
        startIcon={startIcon}
        sx={{
          textTransform: 'capitalize',
          borderRadius: 2,
          padding: widePadding ? '8px 12px' : '8px',
          bgcolor: appVariant === 'filled' ? palette.green[500] : palette.gray.white,
          color: appVariant === 'filled' ? palette.gray.white : palette.green[500],
          border: appVariant === 'filled' ? 'none' : `1px solid ${palette.green[500]}`,
          '&:hover': {
            backgroundColor: appVariant === 'filled' ? palette.green.hover1 : 'inherit',
            borderColor: appVariant === 'filled' ? 'inherit' : palette.green.hover1,
            color: appVariant === 'filled' ? palette.gray.white : palette.green.hover1,
          },
          '&.Mui-disabled': {
            backgroundColor: appVariant === 'filled' ? palette.gray[250] : 'inherit',
            color: palette.gray[400],
            borderColor: palette.gray[400],
          },
          ...sx,
        }}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

RedesignAppButton.displayName = 'RedesignAppButton';

export default RedesignAppButton;
