import React, { useState } from 'react';
import AppTextField from '../../../components/materials/forms/AppTextField';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export enum IconType {
  SHOW_PASSWORD,
}

export interface InputFieldProps {
  inputRef: React.RefObject<HTMLInputElement>;
  iconType: IconType;
  id: string;
  label: string;
  type?: string;
  onChange?: () => void;
  error?: boolean;
  helperText?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
  inputRef,
  iconType,
  id,
  label,
  type,
  onChange,
  error,
  helperText,
}) => {
  const [showField, setShowField] = useState<boolean>(false);
  const [fieldType, setFieldType] = useState<string | undefined>(type);

  let icon = null;

  switch (iconType) {
    case IconType.SHOW_PASSWORD:
      icon = (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              setShowField((prev) => {
                const newValue = !prev;

                if (newValue) setFieldType('text');
                else setFieldType('password');

                return newValue;
              });
            }}
            edge="end"
          >
            {showField ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
      break;
    default:
      break;
  }

  return (
    <AppTextField
      InputProps={{
        inputRef,
        endAdornment: icon,
      }}
      id={id}
      label={label}
      type={fieldType}
      placeholder="Enter your new password"
      variant="outlined"
      required
      onChange={onChange}
      error={error}
      helperText={helperText}
      labelVariant="labelLarge"
      sx={{ width: '100%', borderRadius: '4px', mb: '20px' }}
    />
  );
};
