export interface KeyTheme {
  id: number;
  title: string;
  status: KeyThemeStatus;
  insights: Insight[];
  opportunities: Opportunity[];
  kpis: KeyThemeResource[];
  consumerSurveys: KeyThemeResource[];
}

export enum KeyThemeStatus {
  Draft = 'draft',
  Published = 'published',
}

export interface BaseKeyThemeItem {
  title: string;
  consumerSurveyLabel: boolean;
  description: string;
}

export interface Insight extends BaseKeyThemeItem {}
export interface Opportunity extends BaseKeyThemeItem {}

export enum KeyThemeItemType {
  Insight = 'Insight',
  Opportunity = 'Opportunities/Next steps',
}

export enum KeyThemeResourceType {
  Kpi = 'kpi_page',
  ConsumerSurvey = 'survey_page',
}

export const kpiResourceTypeToLabel = {
  [KeyThemeResourceType.Kpi]: 'Kpi',
  [KeyThemeResourceType.ConsumerSurvey]: 'Consumer Survey',
};

interface KeyThemeResourceBase {
  file_storage_path: string;
  file_type: string;
  file_name: string;
  order_number?: number;
  theme_id?: number;
  resource_type: string;
  created_at?: string;
  updated_at?: string;
}

export interface KeyThemeResourceStored extends KeyThemeResourceBase {
  id: number;
  stored: true;
}

interface KeyThemeResourceNotStored extends KeyThemeResourceBase {
  id: string;
  stored?: false;
}

export type KeyThemeResource = KeyThemeResourceStored | KeyThemeResourceNotStored;

export interface KeyThemeResponse {
  id: number;
  name: string;
  status: string;
  workspace_id: number;
  insights_opportunities: InsightsOpportunities;
  created_at: string;
  updated_at: string;
  theme_resources: KeyThemeResourceStored[];
}

export interface CreateKeyThemeRequest {
  theme_name: string;
  workspace_id: number;
}

export interface UpdateKeyThemeRequest {
  themeId: number;
  keyTheme: KeyTheme;
}

interface InsightsOpportunities {
  insights: Insight[];
  opportunity_areas: Opportunity[];
}

export interface UpdateKeyThemeResponse {
  id: number;
  name: string;
  status: string;
  workspace_id: number;
  insights_opportunities: InsightsOpportunities;
  created_at: string;
  updated_at: string;
  theme_resources: number[];
}

export interface UploadKeyThemeResourcesRequest {
  themeId: number;
  formData: FormData;
}
