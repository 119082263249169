import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import {
  useLazyGetModulesByWorkspaceQuery,
  useLazyGetSubmoduleBySubmoduleIdQuery,
  useLazyGetWorkspaceByIdQuery,
} from '../utils/redux/api';
import { type Module } from '../utils/types/Module';
import { type SubmoduleWithDescription } from '../utils/types/SubModule';
import { type Workspace } from '../utils/types/Workspace';
import { ToastContext } from './toastContext';

interface LoadingRequests {
  workspace: boolean;
  module: boolean;
  submodule: boolean;
}

interface WorkspaceDataContextType {
  activeWorkspace?: Workspace;
  setActiveWorkspace: (workspace: Workspace) => void;
  activeModule?: Module;
  setActiveModule: React.Dispatch<React.SetStateAction<Module | undefined>>;
  activeSubmodule?: SubmoduleWithDescription;
  setActiveSubmodule: React.Dispatch<React.SetStateAction<SubmoduleWithDescription | undefined>>;
  refetchSubmodule: () => Promise<void>;
  flatfileData?: any;
  setFlatFileData: Dispatch<SetStateAction<any>>;
  isLoading: LoadingRequests;
}

const WorkspaceDataContext = createContext<WorkspaceDataContextType | undefined>(undefined);

const WorkspaceDataContextProvider: React.FC = (): ReactElement => {
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace | undefined>();
  const [activeModule, setActiveModule] = useState<Module | undefined>();
  const [activeSubmodule, setActiveSubmodule] = useState<SubmoduleWithDescription | undefined>();
  const [flatfileData, setFlatFileData] = useState<any>({});
  const { handleOpenToast } = useContext(ToastContext);
  const { workspaceId, moduleId, submoduleId } = useParams();
  const [fetchWorkspace, { isFetching: isLoadingWorkspace }] = useLazyGetWorkspaceByIdQuery();
  const [fetchSubmodule, { isFetching: isLoadingSubmodule }] = useLazyGetSubmoduleBySubmoduleIdQuery();
  const [fetchModulesByWorkspace, { isFetching: isLoadingModule }] = useLazyGetModulesByWorkspaceQuery();

  const setFormattedActiveWorkspace = (workspace: Workspace) => {
    setActiveWorkspace({
      workspace_id: workspace.workspace_id!,
      workspace_name: workspace.workspace_name,
      country: workspace.country,
      client_point_of_contact_name: workspace.client_point_of_contact_name,
      client_point_of_contact_email: workspace.client_point_of_contact_email,
      kickoff_meeting_date: workspace.kickoff_meeting_date.split('T')[0],
      final_due_date: workspace?.final_due_date.split('T')[0],
      beginning_of_period: workspace?.beginning_of_period?.split('T')[0],
      end_of_period: workspace?.end_of_period?.split('T')[0],
      bcg_mdp_email: workspace?.bcg_mdp_email,
      flatfile_space_id: workspace?.flatfile_space_id,
    });
  };

  const getSubmodule = useCallback(async () => {
    if (submoduleId !== undefined) {
      try {
        const submoduleResponse = await fetchSubmodule(Number(submoduleId)).unwrap();
        if (submoduleResponse != null) {
          setActiveSubmodule(submoduleResponse);
        }
      } catch (error) {
        handleOpenToast({
          severity: 'error',
          message: "Error loading submodule's data",
        });
      }
    }
  }, [fetchSubmodule, handleOpenToast, submoduleId]);

  useEffect(() => {
    const fetchData = async () => {
      if (workspaceId !== undefined && Number(workspaceId) !== activeWorkspace?.workspace_id) {
        try {
          const workspaceReponse = await fetchWorkspace(Number(workspaceId)).unwrap();
          if (workspaceReponse != null) {
            setFormattedActiveWorkspace(workspaceReponse);
          }
        } catch (error) {
          handleOpenToast({
            severity: 'error',
            message: "Error loading workspace's data",
          });
        }
      }

      await getSubmodule();

      if (moduleId !== undefined && Number(moduleId) !== activeModule?.module_id) {
        try {
          const modulesResponse = await fetchModulesByWorkspace(Number(workspaceId)).unwrap();
          if (modulesResponse != null) {
            const updatedModule = modulesResponse.modules.find(
              (module: Module) => module.module_id === Number(moduleId),
            );
            setActiveModule(updatedModule);
          }
        } catch (error) {
          handleOpenToast({
            severity: 'error',
            message: "Error loading module's data",
          });
        }
      }
    };

    void fetchData();
  }, [
    workspaceId,
    submoduleId,
    moduleId,
    activeWorkspace?.workspace_id,
    activeSubmodule?.submodule_id,
    activeModule?.module_id,
    fetchWorkspace,
    handleOpenToast,
    fetchSubmodule,
    fetchModulesByWorkspace,
    getSubmodule,
  ]);

  const value = useMemo(
    () => ({
      activeWorkspace,
      setActiveWorkspace: setFormattedActiveWorkspace,
      activeModule,
      setActiveModule,
      activeSubmodule,
      setActiveSubmodule,
      refetchSubmodule: getSubmodule,
      flatfileData,
      setFlatFileData,
      isLoading: { workspace: isLoadingWorkspace, module: isLoadingModule, submodule: isLoadingSubmodule },
    }),
    [
      activeWorkspace,
      activeModule,
      activeSubmodule,
      flatfileData,
      isLoadingWorkspace,
      isLoadingModule,
      isLoadingSubmodule,
      getSubmodule,
    ],
  );
  return (
    <WorkspaceDataContext.Provider value={value}>
      <Outlet />
    </WorkspaceDataContext.Provider>
  );
};

const useWorkspaceDataContext = (): WorkspaceDataContextType => {
  const context = useContext(WorkspaceDataContext);
  if (context === undefined) {
    throw new Error('useWorkspaceDataContext must be used within a WorkspaceDataContextProvider');
  }
  return context;
};

export { useWorkspaceDataContext };
export default WorkspaceDataContextProvider;
