import { Search as SearchIcon } from '@mui/icons-material';
import { Box, InputAdornment, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppAutoComplete from '../../../components/materials/forms/AppAutoComplete';
import { categories } from '../../../utils/kpiCategories';

const SearchBox: React.FC = () => {
  const { palette } = useTheme();
  const { workspaceId, workspaceName } = useParams<{
    submoduleName: string;
    moduleName: string;
    workspaceId: string;
    workspaceName: string;
  }>();
  const navigate = useNavigate();

  const kpis = useMemo(
    () =>
      categories.flatMap((category) =>
        category.options.map((option) => ({
          ...option,
          category_label: category.category_label,
          module_name: category.module_name,
        })),
      ),
    [],
  );

  const onChangeSearch = (event: any, value: any) => {
    if (value === null) return;

    navigate(`/rebex-workspaces/${workspaceName}/${workspaceId}/insights/${value.module_name}/${value.option_param}`);
  };

  return (
    <AppAutoComplete
      options={kpis}
      getOptionLabel={(option) => option.option_label}
      groupBy={(option) => option.category_label}
      onChange={onChangeSearch}
      label="Search"
      placeholder="KPI Categories"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      renderGroup={(params) => {
        return (
          <li key={params.key}>
            <Box sx={{ position: 'sticky', top: '-8px', background: palette.green[200], p: 1 }}>
              <Typography variant="titleMedium">{params.group}</Typography>
            </Box>
            <Box component="ul">{params.children}</Box>
          </li>
        );
      }}
      sx={{ minWidth: '200px', maxWidth: '500px' }}
    />
  );
};

export default SearchBox;
