import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as Icon } from '../svg/blank-kpi.svg';

export default function BlankKPI(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      component={Icon}
      inheritViewBox
      fontSize="inherit"
      style={{ width: '240px', height: '240px' }}
    />
  );
}
