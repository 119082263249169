export const categories = [
  {
    category_id: 32,
    category_label: 'Financial performance',
    module_name: 'revenues',
    options: [
      {
        option_id: 1,
        option_label: 'Efficiency ratio',
        option_param: 'efficiency-ratio-and-total-cost',
      },
    ],
  },
  {
    category_id: 1,
    category_label: 'Revenue per customer',
    module_name: 'revenues',
    options: [
      {
        option_id: 1,
        option_label: 'Total revenue per customer',
        option_param: 'total-revenue-per-customer',
      },
      {
        option_id: 2,
        option_label: 'Proportion of mass market vs affluent revenue',
        option_param: 'proportion-of-mass-market-vs-affluent-revenue',
      },
      {
        option_id: 3,
        option_label: 'Net interest income vs. fee income',
        option_param: 'net-interest-income-vs-fee-income',
      },
    ],
  },
  {
    category_id: 2,
    category_label: 'Product revenue',
    module_name: 'revenues',
    options: [
      {
        option_id: 1,
        option_label: 'Deposit products',
        option_param: 'deposit-products',
      },
      {
        option_id: 2,
        option_label: 'Credit products',
        option_param: 'credit-products',
      },
      {
        option_id: 3,
        option_label: 'Investment and insurance',
        option_param: 'investment-and-insurance',
      },
    ],
  },
  {
    category_id: 3,
    category_label: 'Cost per customer (cost type)',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'Efficiency ratio',
        option_param: 'efficiency-ratio-and-total-cost',
      },
      {
        option_id: 2,
        option_label: 'Cost per customer (P&L view)',
        option_param: 'cost-per-customer',
      },
    ],
  },
  {
    category_id: 4,
    category_label: 'Marketing costs',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'Marketing spend by channel',
        option_param: 'marketing-spend-by-channel',
      },
      {
        option_id: 2,
        option_label: 'Marketing spend on sales promotion',
        option_param: 'marketing-spend-on-sales-promotion',
      },
    ],
  },
  {
    category_id: 5,
    category_label: 'Distribution of FTEs',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'FTE organizational model (FTE per 100k customers)',
        option_param: 'fte-organizational-model',
      },
    ],
  },
  {
    category_id: 6,
    category_label: 'Personnel cost',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'Personnel cost overview',
        option_param: 'personnel-cost-overview',
      },
      {
        option_id: 2,
        option_label: 'Branch FTE compensation',
        option_param: 'branch-fte-compensation',
      },
      {
        option_id: 3,
        option_label: 'Call Center FTE compensation',
        option_param: 'call-center-fte-compensation',
      },
      {
        option_id: 4,
        option_label: 'Operations FTE compensation',
        option_param: 'operations-fte-compensation',
      },
    ],
  },
  {
    category_id: 7,
    category_label: 'Operations',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'Operations productivity',
        option_param: 'operations-productivity',
      },
    ],
  },
  {
    category_id: 8,
    category_label: 'Cost model (cost function)',
    module_name: 'costs',
    options: [
      {
        option_id: 1,
        option_label: 'Cost per 1m customers',
        option_param: 'cost-per-1m-customers',
      },
    ],
  },
  {
    category_id: 9,
    category_label: 'Customer base distribution',
    module_name: 'customers',
    options: [
      {
        option_id: 1,
        option_label: 'Overview',
        option_param: 'customer-distribution-overview',
      },
      {
        option_id: 2,
        option_label: 'Age',
        option_param: 'customer-distribution-by-age',
      },
      {
        option_id: 3,
        option_label: 'Product holding',
        option_param: 'customer-distribution-by-product-holding',
      },
      {
        option_id: 4,
        option_label: 'Tenure',
        option_param: 'customer-distribution-by-tenure',
      },
    ],
  },
  {
    category_id: 10,
    category_label: 'Customer net growth',
    module_name: 'customers',
    options: [
      {
        option_id: 1,
        option_label: 'Retail customer',
        option_param: 'retail-customer',
      },
    ],
  },
  {
    category_id: 11,
    category_label: 'Customer acquisition',
    module_name: 'customers',
    options: [
      {
        option_id: 1,
        option_label: 'Overall acquisition',
        option_param: 'overall-acquisition',
      },
      {
        option_id: 2,
        option_label: 'Age view',
        option_param: 'customer-acquisition-by-age',
      },
      {
        option_id: 3,
        option_label: 'Level of product holding view',
        option_param: 'customer-acquisition-by-product-holding',
      },
      {
        option_id: 4,
        option_label: 'Digitally acquired new-to-bank customers',
        option_param: 'digitally-acquired-new-to-bank-customers',
      },
    ],
  },
  {
    category_id: 12,
    category_label: 'Attrition',
    module_name: 'customers',
    options: [
      {
        option_id: 1,
        option_label: 'Overall attrition',
        option_param: 'overall-attrition',
      },
      {
        option_id: 2,
        option_label: 'Customer attrition by age',
        option_param: 'customer-attrition-by-age',
      },
      {
        option_id: 3,
        option_label: 'Level of product holding view',
        option_param: 'customer-attrition-by-product-holding',
      },
      {
        option_id: 4,
        option_label: 'Tenure view',
        option_param: 'customer-attrition-by-tenure',
      },
    ],
  },
  {
    category_id: 13,
    category_label: 'Product penetration',
    module_name: 'products',
    options: [
      {
        option_id: 1,
        option_label: 'Penetration by product type',
        option_param: 'penetration-by-product-type',
      },
      {
        option_id: 2,
        option_label: 'Penetration by age',
        option_param: 'penetration-by-age',
      },
      {
        option_id: 3,
        option_label: 'Accounts per customer',
        option_param: 'accounts-per-customer',
      },
    ],
  },
  {
    category_id: 14,
    category_label: 'Overall sales',
    module_name: 'products',
    options: [
      {
        option_id: 1,
        option_label: 'New accounts per customer and FTE',
        option_param: 'new-account-per-customer-and-fte',
      },
      {
        option_id: 2,
        option_label: 'Sales per channel by product',
        option_param: 'sales-per-channel-by-product',
      },
      {
        option_id: 3,
        option_label: 'Account growth',
        option_param: 'account-growth',
      },
    ],
  },
  {
    category_id: 15,
    category_label: 'Digital enrollment',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Share of customers enrolled',
        option_param: 'share-of-customers-enrolled',
      },
    ],
  },
  {
    category_id: 16,
    category_label: 'Customer activity 90 days',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Retail customers',
        option_param: '90-day-activity-total-customers',
      },
      {
        option_id: 2,
        option_label: 'Primary customers',
        option_param: '90-day-activity-primary-customers',
      },
      {
        option_id: 3,
        option_label: 'Mobile by age',
        option_param: '90-day-activity-in-mobile-by-age-segment',
      },
      {
        option_id: 4,
        option_label: 'Online by age',
        option_param: '90-day-activity-in-online-by-age-segment',
      },
    ],
  },
  {
    category_id: 17,
    category_label: 'Customer activity 30 days',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Retail customers',
        option_param: '30-day-activity-total-customers',
      },
      {
        option_id: 2,
        option_label: 'Primary customers',
        option_param: '30-day-activity-primary-customers',
      },
      {
        option_id: 3,
        option_label: 'New-to-bank enrolled',
        option_param: '30-day-activity-newly-enrolled-customers',
      },
    ],
  },
  {
    category_id: 18,
    category_label: 'Customer log ins',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Logins per enrolled customer',
        option_param: 'logins-per-enrolled-customer',
      },
      {
        option_id: 2,
        option_label: 'Use of digital features',
        option_param: 'use-of-digital-features',
      },
    ],
  },
  {
    category_id: 19,
    category_label: 'Transactions',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: '% of digital transactions',
        option_param: 'percentage-of-digital-transactions',
      },
      {
        option_id: 2,
        option_label: 'Transaction mix',
        option_param: 'transactions-mix',
      },
      {
        option_id: 3,
        option_label: 'Transactions per active customer',
        option_param: 'transactions-per-active-customer',
      },
    ],
  },
  {
    category_id: 20,
    category_label: 'Sales',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Percentage of sales in digital',
        option_param: 'percentage-of-sales-in-digital',
      },
      {
        option_id: 2,
        option_label: 'Checking account sales funnel',
        option_param: 'checking account sales funnel',
      },
      {
        option_id: 3,
        option_label: 'Quality of sales in branch vs digital',
        option_param: 'quality-of-sales',
      },
    ],
  },
  {
    category_id: 21,
    category_label: 'Digital servicing',
    module_name: 'digital-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Chatbot usage',
        option_param: 'chatbot-usage',
      },
    ],
  },
  {
    category_id: 22,
    category_label: 'Customers per branch and ATM',
    module_name: 'physical-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Customers per branch and ATM',
        option_param: 'customers-per-branch-and-atm',
      },
    ],
  },
  {
    category_id: 23,
    category_label: 'Customer activity',
    module_name: 'physical-channels',
    options: [
      {
        option_id: 1,
        option_label: '90-day customer activity - BRANCH',
        option_param: '90-day-customer-activity-branch',
      },
      {
        option_id: 2,
        option_label: '90-day customer activity - ATM',
        option_param: '90-day-customer-activity-atm',
      },
      {
        option_id: 3,
        option_label: '30-day customer activity - BRANCH',
        option_param: '30-day-customer-activity-branch',
      },
      {
        option_id: 4,
        option_label: '30-day customer activity - ATM',
        option_param: '30-day-customer-activity-atm',
      },
      {
        option_id: 5,
        option_label: 'Branch and ATM visits',
        option_param: 'branch-and-atm-visits',
      },
    ],
  },
  {
    category_id: 24,
    category_label: 'Transactions',
    module_name: 'physical-channels',
    options: [
      {
        option_id: 1,
        option_label: '% of branch and ATM transaction',
        option_param: 'percentage-of-branch-and-atm-transactions',
      },
      {
        option_id: 2,
        option_label: 'Transaction mix by channel',
        option_param: 'transaction-mix-by-channel',
      },
      {
        option_id: 3,
        option_label: 'Transactions per branch and ATM',
        option_param: 'transactions-per-branch-and-atm-per-week',
      },
    ],
  },
  {
    category_id: 25,
    category_label: 'Sales',
    module_name: 'physical-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Branch sales per product',
        option_param: 'branch-sales-per-product',
      },
      {
        option_id: 2,
        option_label: 'Branch sales staff productivity',
        option_param: 'branch-sales-staff-productivity',
      },
      {
        option_id: 3,
        option_label: 'Quality of sales in branch vs digital',
        option_param: 'quality-of-sales-in-branch-vs-digital',
      },
    ],
  },
  {
    category_id: 26,
    category_label: 'Branch efficiency',
    module_name: 'physical-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Branch FTE per branch',
        option_param: 'branch-fte-per-branch',
      },
    ],
  },
  {
    category_id: 27,
    category_label: 'Number of call centers',
    module_name: 'call-center-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Number of call centers',
        option_param: 'number-of-call-centers',
      },
    ],
  },
  {
    category_id: 28,
    category_label: 'Customer activity',
    module_name: 'call-center-channels',
    options: [
      {
        option_id: 1,
        option_label: '90-day customer activity',
        option_param: '90-day-customer-activity',
      },
      {
        option_id: 2,
        option_label: '30-day customer activity',
        option_param: '30-day-customer-activity',
      },
    ],
  },
  {
    category_id: 29,
    category_label: 'Transactions',
    module_name: 'call-center-channels',
    options: [
      {
        option_id: 1,
        option_label: '% call center transactions',
        option_param: 'customer-initiated-transactions',
      },
      {
        option_id: 2,
        option_label: 'Transactions per active customer',
        option_param: 'transactions-per-active-customer-per-week',
      },
    ],
  },
  {
    category_id: 30,
    category_label: 'Sales',
    module_name: 'call-center-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Percentage of sales in call center',
        option_param: 'percentage-of-sales-in-call-center',
      },
    ],
  },
  {
    category_id: 31,
    category_label: 'Call center servicing',
    module_name: 'call-center-channels',
    options: [
      {
        option_id: 1,
        option_label: 'Service efficiency',
        option_param: 'service-efficiency',
      },
    ],
  },
];
